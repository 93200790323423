import { useEffect, useState } from "react"

import { API_EQUIPMENT, API_EQUIPMENT_FAMILY } from "../constants/routes"
import { IResponse, IResponseEntity, ValueOf } from "../model/common"
import {
  EnumCurrency,
  EnumEquipmentRental,
  EnumEquipmentState,
  IEquipment,
  IFamily
} from "../model/equipment"
import { IFueling } from "../model/fueling"
import { useFetch } from "./common/petitions"

export const DEFAULT_EQUIPMENT_FAMILY: Partial<IFamily> = { id: "0", family_name: "Selecciona" }
export const useEquipmentFamilyList = () => {
  const { loadData, response } = useFetch<IResponse<IFamily>>({
    method: "get"
  })

  useEffect(() => {
    loadData(API_EQUIPMENT_FAMILY)
  }, [])

  const list = response.payload?.data ?? []
  return {
    equipmentFamilyList: [DEFAULT_EQUIPMENT_FAMILY, ...list],
    loadingList: response.loading,
    errorList: response.errorPayload
  }
}
export const DEFAULT_EQUIPMENT = {
  state: EnumEquipmentState.STAND_BY,
  rental_currency: EnumCurrency.USD,
  rental: EnumEquipmentRental.OPERATED,
  family: DEFAULT_EQUIPMENT_FAMILY,
  family_id: "0",
  own: true,
  expire_gps: "01-01-0001",
  expire_inspection: "01-01-0001",
  expire_soat: "01-01-0001",
  expire_trec: "01-01-0001",
  code: "Selecciona activo",
  plate: "Selecciona activo",
  id: ""
}
export const useCrudEquipment = () => {
  const [equipment, setEquipment] = useState<Partial<IEquipment>>({
    ...DEFAULT_EQUIPMENT,
    code: "",
    plate: ""
  })
  const { loadData: loadGetEquipment, response: responseGet } = useFetch<
    IResponse<Partial<IEquipment>>
  >({
    method: "get"
  })
  const { loadData: loadCreateEquipment, response: responseCreate } = useFetch<
    IResponseEntity<IEquipment>
  >({
    method: "post"
  })
  const { loadData: loadUpdateEquipment, response: responseUpdate } = useFetch<
    IResponseEntity<IEquipment>
  >({
    method: "put"
  })
  const { loadData: loadDeleteEquipment, response: responseDelete } = useFetch<
    IResponseEntity<IEquipment>
  >({
    method: "delete"
  })

  const updateOrCreateEquipment = () => {
    console.log("equip save", equipment)
    let msm = ""

    if (
      !equipment ||
      !equipment.family ||
      Number(equipment.family?.id) === 0 ||
      !equipment.tariff_price ||
      !equipment.code ||
      !equipment.plate
    ) {
      msm += Number(equipment.family?.id) > 0 ? "" : " familia,"
      msm += equipment.code ? "" : " código,"
      msm += equipment.plate ? "" : " placa,"
      msm += equipment.tariff_price ? "" : " tarifa,"
      alert("Error en " + msm)
      return
    }
    if (equipment.own) {
      if (!equipment.purchase_price) {
        msm += equipment.purchase_price ? "" : " precio compra,"
        alert("Error en " + msm)
        return
      }
    } else {
      if (
        !equipment.fee_price ||
        !equipment.rental ||
        !equipment.rental_currency ||
        !equipment.supplier?.id
      ) {
        msm += equipment.fee_price ? "" : " precio de alquiler,"
        msm += equipment.rental ? "" : " tipo de alquiler, "
        msm += equipment.rental ? "" : " moneda de alquiler, "
        msm += equipment.supplier?.id ? "" : " proveedor, "
        alert("Error en " + msm)
        return
      }
    }

    equipment.name = equipment.family.family_name ?? ""
    if (equipment.expire_gps?.includes("0001")) {
      equipment.has_gps = false
      equipment.expire_gps = undefined
    } else if (equipment.expire_gps) {
      equipment.has_gps = true
    }
    if (equipment.expire_inspection?.includes("0001")) {
      equipment.has_inspection_technical = false
      equipment.expire_inspection = undefined
    } else if (equipment.expire_inspection) {
      equipment.has_inspection_technical = true
    }
    if (equipment.expire_trec?.includes("0001")) {
      equipment.has_trec = false
      equipment.expire_trec = undefined
    } else if (equipment.expire_trec) {
      equipment.has_trec = true
    }
    if (Number(equipment.id) > 0) {
      loadUpdateEquipment(`${API_EQUIPMENT}/${equipment.id}`, JSON.stringify(equipment))
    } else {
      loadCreateEquipment(API_EQUIPMENT, JSON.stringify(equipment))
    }
  }
  const handleDeleteEquipment = (id: number) => {
    loadDeleteEquipment(`${API_EQUIPMENT}/${id}`)
  }
  const changeValueEquipment = (key: keyof IEquipment, value: ValueOf<IEquipment>) => {
    setEquipment((prev) => ({
      ...prev,
      [key]: value
    }))
  }

  return {
    data: [DEFAULT_EQUIPMENT, ...(responseGet.payload?.data ?? [])],
    loadingCUD: responseCreate.loading || responseUpdate.loading || responseDelete.loading,
    equipment,
    errCreateUpdate: responseCreate.errorPayload?.message || responseUpdate.errorPayload?.message,
    loadingList: responseGet,
    refreshEquipmentList: () => loadGetEquipment(API_EQUIPMENT),
    changeValueEquipment,
    handleDeleteEquipment,
    updateOrCreateEquipment,
    setEquipment
  }
}

export const useCrudFamily = () => {
  const [family, setFamily] = useState<Partial<IFamily>>({})
  const { loadData: loadGetFamily, response: responseGet } = useFetch<IResponse<IFamily>>({
    method: "get"
  })
  const { loadData: loadCreateFamily, response: responseCreate } = useFetch<
    IResponseEntity<IFamily>
  >({
    method: "post"
  })
  const { loadData: loadUpdateFamily, response: responseUpdate } = useFetch<
    IResponseEntity<IFamily>
  >({
    method: "put"
  })
  const { loadData: loadDeleteFamily, response: responseDelete } = useFetch<
    IResponseEntity<IFamily>
  >({
    method: "delete"
  })

  const updateOrCreateFamily = () => {
    if (!family || !family.family_name) return

    if (family.id) {
      loadUpdateFamily(`${API_EQUIPMENT_FAMILY}/${family.id}`, JSON.stringify(family))
      if (!family.deleted_at) {
        restore()
        loadUpdateFamily(`${API_EQUIPMENT_FAMILY}/${family.id}`, JSON.stringify(family))
      } else if (family.deleted_at) {
        handleDeleteFamily(Number(family.id))
      }
    } else {
      loadCreateFamily(API_EQUIPMENT_FAMILY, JSON.stringify(family))
    }
  }
  const restore = () => {
    loadUpdateFamily(`${API_EQUIPMENT_FAMILY}/${family.id}/restore`, JSON.stringify(family))
  }
  const handleDeleteFamily = (id: number) => {
    loadDeleteFamily(`${API_EQUIPMENT_FAMILY}/${id}`)
  }
  const changeValueFamily = (key: keyof IFamily, value: ValueOf<IFamily>) => {
    setFamily((prev) => ({
      ...prev,
      [key]: value
    }))
  }

  return {
    data: responseGet.payload?.data ?? [],
    loadingCUD: responseCreate.loading || responseUpdate.loading || responseDelete.loading,
    family,
    loadingList: responseGet,
    restore,
    refreshFamilyList: () => loadGetFamily(API_EQUIPMENT_FAMILY),
    changeValueFamily,
    handleDeleteFamily,
    updateOrCreateFamily,
    setFamily
  }
}
export const useEquipmentList = () => {
  const { loadData: loadGetEquipment, response: responseGet } = useFetch<
    IResponse<Partial<IEquipment>>
  >({
    method: "get"
  })

  return {
    equipmentList: responseGet.payload?.data ?? [],
    loadingEquipmentList: !!responseGet.loading,
    errorEquipmentList: responseGet.errorPayload,
    refreshEquipmentList: () => loadGetEquipment(API_EQUIPMENT)
  }
}
