import { Dispatch, SetStateAction, useEffect, useState } from "react"

import { Delete, Refresh } from "@mui/icons-material"
import {
  Autocomplete,
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  TextField,
  Typography
} from "@mui/material"
import { State } from "hooks/common/petitions"
import { DEFAULT_DAILY_PART, useDailyByDate } from "hooks/daily-part"
import { useCrudEmployee } from "hooks/employee"
import { useCrudEquipment } from "hooks/equipment"
import { IResponseEntity, ValueOf } from "model/common"
import { EnumShift, IDailyPart, ITimeline } from "model/daily-part"
import { IEmployee } from "model/employee"
import { IEquipment } from "model/equipment"

import { FormTimeline } from "./FormTimeline"
import { DEFAULT_TIMELINE } from "./const"

const TimelineListTable = ({
  timelineList,
  dailyPart,
  removeItemIndex,
  total_hours
}: {
  timelineList: ITimeline[]
  dailyPart: Partial<IDailyPart>
  removeItemIndex: (index: number) => void
  total_hours: number
}) => {
  const styleBorder: any = { border: "1px solid", borderCollapse: "collapse" }
  return (
    <Grid container mt={1}>
      <table style={{ border: "1px solid", borderCollapse: "collapse", width: "100%" }}>
        <thead>
          <tr style={{ background: "#efefef" }}>
            <th style={styleBorder}>Proyecto</th>
            <th style={styleBorder}>H. Inicial</th>
            <th style={styleBorder}>H. Final</th>
            <th style={styleBorder}>H. Trabajo</th>
            <th style={styleBorder}>Toneladas</th>
            <th style={styleBorder}>Trabajo resumen</th>
            <th style={styleBorder}>Opt</th>
          </tr>
        </thead>
        <tbody>
          {timelineList.map((time, k) => (
            <tr key={k} style={styleBorder}>
              <th style={styleBorder}>{time.movement?.project?.name ?? ""}</th>
              <th style={{ ...styleBorder, ...{ color: k === 0 ? "blue" : "black" } }}>
                {time.start_hourmeter}
              </th>
              <th
                style={{
                  ...styleBorder,
                  ...{ color: k === timelineList.length - 1 ? "blue" : "black" }
                }}
              >
                {time.end_hourmeter}
              </th>
              <th style={styleBorder}>
                {Number((time?.end_hourmeter ?? 0) - (time?.start_hourmeter ?? 0)).toFixed(2)}
              </th>
              <th style={styleBorder}>{Number(time?.tonnage ?? 0).toFixed(2)}</th>
              <th style={styleBorder}>{time.work}</th>
              <th style={{ width: 50 }}>
                <IconButton size="small" onClick={() => removeItemIndex(k)}>
                  <Delete fontSize="small" />
                </IconButton>
              </th>
            </tr>
          ))}
          <tr style={{ ...styleBorder, ...{ background: "#efefef" } }}>
            <th />
            <th style={{ color: "blue" }}>HI {dailyPart.start_hourmeter ?? 0}</th>
            <th style={{ color: "blue" }}>HF {dailyPart.end_hourmeter ?? 0}</th>
            <th>
              HT
              {total_hours.toFixed(2)}
            </th>
            <th colSpan={2} />
          </tr>
        </tbody>
      </table>
    </Grid>
  )
}
export const FormDailyPart = ({
  isModeEdit,
  dailyPart,
  lastTimeline,
  firstTimeline,
  responseCreate,
  responseUpdate,
  timelineList,
  isEditWorks,
  setIsEditWorks,
  setDailyPart,
  changeValueDailyPart,
  setTimelineList
}: {
  isModeEdit: boolean
  isEditWorks: boolean
  timelineList: ITimeline[]
  lastTimeline?: ITimeline
  firstTimeline?: ITimeline
  responseCreate: State<IResponseEntity<IDailyPart>>
  responseUpdate: State<IResponseEntity<IDailyPart>>
  dailyPart: Partial<IDailyPart>
  setIsEditWorks: Dispatch<SetStateAction<boolean>>
  setTimelineList: Dispatch<SetStateAction<ITimeline[]>>
  setDailyPart: Dispatch<SetStateAction<Partial<IDailyPart>>>
  changeValueDailyPart: (key: keyof IDailyPart, value: ValueOf<IDailyPart>) => void
}) => {
  const [currentTimeline, setCurrentTimeline] = useState<ITimeline>(DEFAULT_TIMELINE)
  const { data: equipmentList, refreshEquipmentList } = useCrudEquipment()
  const { data: employeeList, setRefreshEmployee } = useCrudEmployee()
  const { dailyList, loadDailyByDate } = useDailyByDate()
  const total_hours = timelineList.reduce(
    (a, row) => a + (Number(row.end_hourmeter ?? 0) - Number(row.start_hourmeter ?? 0)),
    0
  )
  const changeEquipmentPart = (value: Partial<IEquipment> | null) => {
    changeValueDailyPart("equipment", value ?? undefined)
    changeValueDailyPart("equipment_id", value?.id)
    changeValueDailyPart("project", value?.project)
    changeValueDailyPart("schedule", value?.project?.schedule)
    changeValueDailyPart("schedule_id", value?.project?.schedule?.id)
  }
  const changeEmployeePart = (value: Partial<IEmployee> | null) => {
    changeValueDailyPart("employee", value ?? undefined)
    changeValueDailyPart("employee_id", value?.id)
  }
  useEffect(() => {
    setCurrentTimeline((prev) => ({
      ...prev,
      start_hourmeter: dailyPart.equipment?.hourmeter ?? 0
    }))
    setTimelineList([])
  }, [dailyPart.equipment_id])
  useEffect(() => {
    refreshEquipmentList()
  }, [])
  const resetDataAfterFetch = () => {
    setTimelineList([])
    setCurrentTimeline(DEFAULT_TIMELINE)
    setDailyPart((prev) => ({
      ...DEFAULT_DAILY_PART,
      date_part: prev.date_part
    }))
  }
  useEffect(() => {
    const id_part = responseCreate.payload?.data.id || responseUpdate.payload?.data.id
    if (id_part) {
      resetDataAfterFetch()
    }
  }, [responseCreate.payload, responseUpdate.payload])
  useEffect(() => {
    const isAfterDeleteItemsTableTimeline =
      typeof dailyPart.timelines === "undefined" &&
      typeof lastTimeline === "undefined" &&
      typeof firstTimeline === "undefined" &&
      dailyPart.end_hourmeter !== 0 &&
      dailyPart.start_hourmeter !== 0
    if (isAfterDeleteItemsTableTimeline) {
      changeValueDailyPart("end_hourmeter", 0)
      changeValueDailyPart("start_hourmeter", 0)
    }
  }, [dailyPart, lastTimeline, firstTimeline])
  useEffect(() => {
    if (lastTimeline?.end_hourmeter) {
      changeValueDailyPart("end_hourmeter", lastTimeline.end_hourmeter ?? 0)
    } else if (isEditWorks) {
      changeValueDailyPart("end_hourmeter", 0)
    }
  }, [lastTimeline, isEditWorks])
  useEffect(() => {
    if (firstTimeline?.start_hourmeter) {
      changeValueDailyPart(
        "start_hourmeter",
        firstTimeline.start_hourmeter ?? dailyPart.equipment?.hourmeter ?? 0
      )
    } else if (isEditWorks) {
      changeValueDailyPart("start_hourmeter", 0)
    }
  }, [firstTimeline, isEditWorks])
  useEffect(() => {
    if (dailyPart.date_part) loadDailyByDate(dailyPart.date_part)
  }, [dailyPart.date_part])

  useEffect(() => {
    if (total_hours > 0 || (isEditWorks && isModeEdit)) {
      changeValueDailyPart("total_hours", total_hours)
    }
  }, [total_hours, isEditWorks, isModeEdit])
  const removeItemIndex = (index: number) => {
    timelineList.splice(index, 1)
    setTimelineList(timelineList.slice())
  }
  const onChangeWork = () => setIsEditWorks((prev) => !prev)
  const getOptionDisabledDailyList = (opt: Partial<IEquipment>) =>
    !!dailyList.find((d) => Number(d.daily?.equipment_id) === Number(opt.id))
  return (
    <Grid container>
      <Box height={8} width={"100%"} />
      <Grid container gap={1}>
        <Grid item flex={1}>
          <Grid container alignItems={"start"}>
            <Autocomplete
              value={dailyPart.equipment}
              style={{ flex: 1 }}
              onChange={(_, value) => changeEquipmentPart(value)}
              size="small"
              isOptionEqualToValue={(val, other) => Number(val?.id) === Number(other?.id)}
              options={equipmentList}
              getOptionDisabled={getOptionDisabledDailyList}
              getOptionLabel={(opt) => opt.code ?? opt.plate ?? ""}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Código equipo"
                  helperText={`Placa: ${dailyPart.equipment?.plate ?? ""}`}
                />
              )}
              disabled={isModeEdit}
            />
            <IconButton size="small" onClick={refreshEquipmentList}>
              <Refresh fontSize="small" />
            </IconButton>
          </Grid>
        </Grid>
        <Grid item flex={1}>
          <TextField
            size="small"
            fullWidth
            type="date"
            onChange={(val) => changeValueDailyPart("date_part", val.target.value)}
            value={dailyPart.date_part}
            label="Fecha de parte"
            variant="outlined"
            disabled={isModeEdit}
          />
        </Grid>
        <Grid item flex={2}>
          <Grid container alignItems={"self-start"}>
            <Autocomplete
              value={dailyPart.employee}
              onChange={(_, value) => changeEmployeePart(value)}
              style={{ flex: 1 }}
              size="small"
              isOptionEqualToValue={(val, other) => Number(val?.id) === Number(other?.id)}
              options={employeeList}
              getOptionLabel={(opt) => `${opt.dni} - ${opt.name} ${opt.surname}`}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="DNI"
                  helperText={`Licencia: ${dailyPart.employee?.license}, Ven: ${dailyPart.employee?.license_expire}`}
                />
              )}
            />
            <IconButton size="small" onClick={() => setRefreshEmployee((prev) => !prev)}>
              <Refresh fontSize="small" />
            </IconButton>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container flexDirection={"column"}>
            <ButtonGroup
              disableElevation
              variant="contained"
              aria-label="Disabled elevation buttons"
            >
              <Button
                color={dailyPart.shift === EnumShift.DAY ? "primary" : "inherit"}
                size="small"
                onClick={() => changeValueDailyPart("shift", EnumShift.DAY)}
              >
                Dia
              </Button>
              <Button
                color={dailyPart.shift === EnumShift.NIGHT ? "primary" : "inherit"}
                size="small"
                onClick={() => changeValueDailyPart("shift", EnumShift.NIGHT)}
              >
                Noche
              </Button>
            </ButtonGroup>
            <label style={{ color: "grey", fontSize: 12 }}>Turno</label>
          </Grid>
        </Grid>
      </Grid>

      <Box mt={2} width={"100%"} />
      {isModeEdit && (
        <FormControlLabel
          control={<Checkbox checked={isEditWorks} onChange={onChangeWork} />}
          label="Editar Trabajos"
        />
      )}
      {(isEditWorks || !isModeEdit) && (
        <Grid container border={"2px #ccc dashed"} component={Paper} p={1} elevation={8}>
          <Typography>Agregar trabajos</Typography>
          <FormTimeline
            currentTimeline={currentTimeline}
            dailyPart={dailyPart}
            lastTimeline={lastTimeline}
            setCurrentTimeline={setCurrentTimeline}
            setTimelineList={setTimelineList}
          />
          <Box width={"100%"} height={8} />
          <TimelineListTable
            dailyPart={dailyPart}
            removeItemIndex={removeItemIndex}
            timelineList={timelineList}
            total_hours={total_hours}
          />
        </Grid>
      )}

      <Box mt={2} width={"100%"} />
      <Grid container justifyItems={"center"} gap={1} mt={2}>
        <Grid item flex={3}>
          <TextField
            value={dailyPart.observations ?? ""}
            onChange={(e) => changeValueDailyPart("observations", e.target.value)}
            size="small"
            label="Observaciones"
            multiline
            fullWidth
            variant="outlined"
          />
        </Grid>
        <Grid item flex={0.5}>
          <TextField
            disabled
            size="small"
            fullWidth
            label="Total horas"
            variant="outlined"
            error={dailyPart.total_hours === 0}
            value={dailyPart.total_hours ?? 0}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}
