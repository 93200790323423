import React, { useEffect, useState } from "react"

import {
  addDays,
  addMonths,
  differenceInMonths,
  format,
  isSameDay,
  lastDayOfMonth,
  startOfMonth
} from "date-fns"
import { es } from "date-fns/locale"

import { useTimeLineStyles } from "./style"

interface IMarked {
  date: Date
  marked: boolean
  text: string
  style?: React.CSSProperties
}

export interface IDateView {
  startDate?: Date
  lastDate?: Date
  selectDate?: Date
  getSelectedDay?: (date: Date) => void
  primaryColor?: string
  labelFormat?: string
  marked?: IMarked[]
}

const DateView = ({
  startDate,
  lastDate,
  selectDate,
  getSelectedDay,
  primaryColor,
  labelFormat,
  marked
}: IDateView) => {
  const [selectedDate, setSelectedDate] = useState<Date>({} as Date)
  const styles = useTimeLineStyles
  const selectedStyle = {
    fontWeight: "bold",
    width: "45px",
    height: "45px",
    borderBottom: `2px solid ${primaryColor}`,
    color: primaryColor
  }

  const labelColor = { color: primaryColor }
  const markedStyle = { color: "#8c3737", padding: "2px", fontSize: 12 }

  const getStyles = (day: Date) => {
    return isSameDay(day, selectedDate) ? selectedStyle : {}
  }

  const getId = (day: Date) => {
    return isSameDay(day, selectedDate) ? "selected" : ""
  }

  const getMarked = (day: Date) => {
    const markedRes = marked?.find((i) => isSameDay(i.date, day))
    if (markedRes) {
      if (!markedRes?.marked) {
        return
      }

      return (
        <div style={{ ...(markedRes?.style ?? markedStyle), ...styles.markedLabel }}>
          {markedRes.text}
        </div>
      )
    }

    return ""
  }

  const renderDays = () => {
    const dayFormat = "E"
    const dateFormat = "d"

    const months = []
    let days = []
    const differenceInMonth = differenceInMonths(lastDate!, startDate!) || 1
    for (let i = 0; i <= differenceInMonth; i++) {
      let start = 0
      let end = 0
      const month = startOfMonth(addMonths(startDate!, i))
      start = i === 0 ? Number(format(startDate!, dateFormat, { locale: es })) - 1 : 0
      end =
        i === differenceInMonth
          ? Number(format(lastDate!, "d", { locale: es }))
          : Number(format(lastDayOfMonth(month), "d", { locale: es }))

      for (let j = start; j < end; j++) {
        const currentDay = addDays(month, j)
        days.push(
          <div
            id={`${getId(currentDay)}`}
            style={{
              ...getStyles(currentDay),
              ...(marked ? styles.dateDayItemMarked : styles.dateDayItem)
            }}
            key={currentDay.getTime()}
            onClick={() => onDateClick(currentDay)}
          >
            <div style={styles.dayLabel}>{format(currentDay, dayFormat, { locale: es })}</div>
            <div style={styles.dayLabel}>{format(currentDay, dateFormat, { locale: es })}</div>
            {getMarked(currentDay)}
          </div>
        )
      }
      months.push(
        <div style={styles.monthContainer} key={month.getTime()}>
          <span style={{ ...labelColor, ...styles.monthYearLabel }}>
            {format(month, labelFormat || "MMMM yyyy", { locale: es })}
          </span>
          <div
            style={styles.daysContainer}
            // style={i === 0 ?   : null}
          >
            {days}
          </div>
        </div>
      )
      days = []
    }

    return (
      <div id={"container"} style={styles.dateListScrollable}>
        {months}
      </div>
    )
  }

  const onDateClick = (day: Date) => {
    setSelectedDate(day)
    if (getSelectedDay) {
      getSelectedDay(day)
    }
  }

  useEffect(() => {
    if (getSelectedDay) {
      if (selectDate) {
        getSelectedDay(selectDate)
      } else {
        getSelectedDay(startDate!)
      }
    }
  }, [])

  useEffect(() => {
    if (selectDate) {
      if (!isSameDay(selectedDate, selectDate)) {
        setSelectedDate(selectDate)
        setTimeout(() => {
          const view = document.getElementById("selected")
          if (view) {
            view.scrollIntoView({
              behavior: "smooth",
              inline: "center",
              block: "nearest"
            })
          }
        }, 20)
      }
    }
  }, [selectDate])

  return <React.Fragment>{renderDays()}</React.Fragment>
}

export { DateView }
