import { useState } from "react"

import { API_EQUIPMENT, API_MOVEMENTS } from "../constants/routes"
import { IResponseEntity, ValueOf } from "../model/common"
import { IEquipment } from "../model/equipment"
import { IEquipmentProjectMovement } from "../model/equipment_project"
import { dateToString } from "../utils"
import { useFetch } from "./common/petitions"

export const useCrudMovement = () => {
  const [movement, setMovement] = useState<Partial<IEquipmentProjectMovement>>({
    input_at: dateToString(new Date()),
    output_at: dateToString(new Date()),
    input_notes: " ",
    output_notes: " "
  })
  const { loadData: loadGetEquipment, response: responseGet } = useFetch<
    IResponseEntity<IEquipment>
  >({
    method: "get"
  })
  const {
    loadData: loadCreateMovement,
    response: responseCreate,
    resetData
  } = useFetch<IResponseEntity<IEquipmentProjectMovement>>({
    method: "post"
  })
  const { loadData: loadUpdateMovement, response: responseUpdate } = useFetch<
    IResponseEntity<IEquipmentProjectMovement>
  >({
    method: "put"
  })
  const { loadData: loadDeleteMovement, response: responseDelete } = useFetch<
    IResponseEntity<IEquipmentProjectMovement>
  >({
    method: "delete"
  })

  const updateOrCreateMovement = (equipment: Partial<IEquipment>) => {
    // console.log("Movement to save", movement)
    // if (
    //   Number(equipment.hourmeter) > Number(movement.hourmeter) ||
    //   Number(equipment.mileage) > Number(movement.mileage)
    // ) {
    //   alert("Error en kilometraje u horometro, debe ser mayor")
    //   return
    // }
    console.log("movement", movement)
    movement.equipment_id = Number(equipment.id ?? 0)
    if (
      !movement ||
      !movement.equipment_id ||
      !movement.project_id ||
      !movement.tariff_price ||
      (Number(movement.input_hourmeter) === 0 && Number(movement.output_hourmeter) === 0)
    ) {
      let msm = ""
      msm += movement.project ? "" : " proyecto,"
      msm += movement.equipment_id ? "" : " equipo,"
      msm += movement.tariff_price ? "" : " tarifa,"
      msm += movement.id
        ? Number(movement.output_hourmeter) > 0
          ? ""
          : " horometro"
        : Number(movement.input_hourmeter) > 0
          ? ""
          : " horometro"
      //msm += movement.mileage ? "" : " kilometraje"
      //msm += movement.movement_type ? "" : " tipo de movimiento"
      //msm += movement.date_movement ? "" : " fecha de movimiento"
      alert("Error en " + msm)
      return
    }
    if (Number(movement.id) > 0) {
    } else {
      movement.output_at = undefined
      movement.output_hourmeter = undefined
      movement.output_notes = ""
    }
    loadCreateMovement(API_MOVEMENTS, JSON.stringify(movement))
  }

  const handleDeleteMovement = (id: number) => {
    loadDeleteMovement(`${API_MOVEMENTS}/${id}`)
  }

  const changeValueMovement = (
    key: keyof IEquipmentProjectMovement,
    value: ValueOf<IEquipmentProjectMovement>
  ) => {
    setMovement((prev) => ({
      ...prev,
      [key]: value
    }))
  }

  return {
    loadingCUD: responseCreate.loading || responseUpdate.loading || responseDelete.loading,
    responseCreate,
    movementForm: movement,
    loadingShow: responseGet.loading,
    equipmentDB: responseGet.payload?.data,
    refreshEquipment: (id_equipment: number) =>
      loadGetEquipment(`${API_EQUIPMENT}/${id_equipment}`),
    changeValueMovement,
    handleDeleteMovement,
    updateOrCreateMovement,
    setMovement,
    resetData
  }
}
