import { IEntity } from "./equipment"
import { ISchedule } from "./shift"

export enum EnumProjectState {
  ACTIVE = "ACTIVO",
  FINALIZE = "FINALIZADO",
  DELETED = "ELIMINADO"
}
export interface IProject {
  id: string
  schedule_id?: string
  customer_id?: number
  name: string
  code: string
  os: string
  description: string
  schedule?: ISchedule
  customer?: IEntity
  projected_cost: number
  start_at?: string
  end_at?: string
  delivery_at?: string
  created_at?: string
  updated_at?: string
  deleted_at?: string
  state: EnumProjectState
}
