import { useEffect } from "react"

import {
  Autocomplete,
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField
} from "@mui/material"
import { useEntityList } from "hooks/entity"
import { useEquipmentList } from "hooks/equipment"
import { EnumEntity } from "model/equipment"
import { EnumStatusFueling, EnumTypeFueling, IFueling } from "model/fueling"

export const FormFueling = ({
  changeValueFueling,
  fueling
}: {
  changeValueFueling: Function
  fueling: Partial<IFueling>
}) => {
  const { equipmentList, errorEquipmentList, loadingEquipmentList, refreshEquipmentList } =
    useEquipmentList()
  const {
    entityList,
    errorList: errorEntityList,
    loadingList: loadingEntityList,
    refreshEntityList
  } = useEntityList({
    type: EnumEntity.SUPPLIER
  })
  useEffect(() => {
    if (fueling.type === EnumTypeFueling.FUEL_TANKER) refreshEquipmentList()
    if (fueling.type === EnumTypeFueling.SERVICE_STATION) refreshEntityList()
  }, [fueling.type])

  return (
    <Grid container>
      <span>{errorEntityList?.message ?? ""}</span>
      <span>{errorEquipmentList?.message ?? ""}</span>
      <Grid container>
        <Grid item flex={1}>
          <FormControl>
            <FormLabel>Tipo</FormLabel>
            <RadioGroup
              onChange={(e, v) => changeValueFueling("type", v)}
              value={fueling.type}
              row
              name="row-radio-buttons-group-type"
            >
              <FormControlLabel
                value={EnumTypeFueling.FUEL_TANKER}
                control={<Radio />}
                label={EnumTypeFueling.FUEL_TANKER}
                checked={fueling.type === EnumTypeFueling.FUEL_TANKER}
                disabled={!!fueling.id}
              />
              <FormControlLabel
                value={EnumTypeFueling.SERVICE_STATION}
                control={<Radio />}
                label={EnumTypeFueling.SERVICE_STATION}
                checked={fueling.type === EnumTypeFueling.SERVICE_STATION}
                disabled={!!fueling.id}
              />
            </RadioGroup>
          </FormControl>
          <Box height={8} />
          <Grid container>
            <Grid item flex={1}>
              {fueling.type === EnumTypeFueling.FUEL_TANKER && (
                <Autocomplete
                  loading={loadingEquipmentList}
                  value={fueling.equipment}
                  onChange={(_, value) => changeValueFueling("equipment", value)}
                  fullWidth
                  size="small"
                  isOptionEqualToValue={(val, other) => Number(val?.id) === Number(other?.id)}
                  options={equipmentList}
                  getOptionLabel={(opt) =>
                    `${opt.plate ?? opt.code ?? "SIN COD."}-${
                      opt.family?.family_name ?? "SIN FAM."
                    }`
                  }
                  disabled={!!fueling.id}
                  renderInput={(params) => <TextField {...params} label="Relacionar cisterna *" />}
                />
              )}
              {fueling.type === EnumTypeFueling.SERVICE_STATION && (
                <Autocomplete
                  loading={loadingEntityList}
                  value={fueling.supplier}
                  onChange={(_, value) => changeValueFueling("supplier", value)}
                  fullWidth
                  size="small"
                  isOptionEqualToValue={(val, other) => Number(val?.id) === Number(other?.id)}
                  options={entityList}
                  getOptionLabel={(opt) => opt.business_name ?? ""}
                  disabled={!!fueling.id}
                  renderInput={(params) => <TextField {...params} label="Relacionar proveedor *" />}
                />
              )}
            </Grid>
          </Grid>
          <Box height={8} />

          <FormControl>
            <FormLabel>Estado</FormLabel>
            <RadioGroup
              onChange={(e, v) => changeValueFueling("status", v)}
              value={fueling.type}
              row
              name="row-radio-buttons-group-type"
            >
              <FormControlLabel
                value={EnumStatusFueling.ACTIVE}
                control={<Radio />}
                label={EnumStatusFueling.ACTIVE}
                checked={fueling.status === EnumStatusFueling.ACTIVE}
              />
              <FormControlLabel
                value={EnumStatusFueling.INACTIVE}
                control={<Radio />}
                label={EnumStatusFueling.INACTIVE}
                checked={fueling.status === EnumStatusFueling.INACTIVE}
              />
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>
    </Grid>
  )
}
