import { Fragment, useEffect, useState } from "react"
import { Link, useLocation } from "react-router-dom"

import {
  ChevronLeft,
  Garage,
  Grading,
  LocalGasStation,
  Logout,
  People,
  Settings
} from "@mui/icons-material"
import DashboardIcon from "@mui/icons-material/Dashboard"
import { Divider, IconButton, List, Toolbar, Tooltip, Typography } from "@mui/material"
import MuiDrawer from "@mui/material/Drawer"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import { styled } from "@mui/material/styles"
import { COLOR_PRIMARY, COLOR_SECONDARY, VERSION_APP } from "constants/index"
import {
  PATH_EMPLOYEE,
  PATH_EQUIPMENT,
  PATH_FUELING,
  PATH_HOME,
  PATH_RECORD_DP,
  PATH_SETTINGS
} from "constants/routes"
import { logout } from "hooks/useAuth"

import { drawerWidth } from "./Layout"

interface IMenu {
  toggleDrawer: () => void
  open: boolean
}

const MainListItems = () => {
  const [_, setOpenSettings] = useState(false)
  const { pathname } = useLocation()
  useEffect(() => {
    setOpenSettings(pathname.includes("setting"))
  }, [pathname])

  const isSelect = (path: string) => pathname === path
  //const includeURL = (path: string) => pathname.includes(path)
  const linkCss = { textDecoration: "none", color: "rgba(0, 0, 0, 0.87)" }
  return (
    <Fragment>
      <Link to={PATH_HOME} style={linkCss}>
        <Tooltip title="Inicio" placement="right">
          <ListItemButton selected={isSelect(PATH_HOME)}>
            <ListItemIcon>
              <DashboardIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary="Inicio" />
          </ListItemButton>
        </Tooltip>
      </Link>
      <Link to={PATH_RECORD_DP} style={linkCss}>
        <Tooltip title="Registros PD" placement="right">
          <ListItemButton selected={isSelect(PATH_RECORD_DP)}>
            <ListItemIcon>
              <Grading color="primary" />
            </ListItemIcon>
            <ListItemText primary="Registros PD" />
          </ListItemButton>
        </Tooltip>
      </Link>
      <Link to={PATH_EQUIPMENT} style={linkCss}>
        <Tooltip title="Equipos" placement="right">
          <ListItemButton selected={isSelect(PATH_EQUIPMENT)}>
            <ListItemIcon>
              <Garage color="primary" />
            </ListItemIcon>
            <ListItemText primary="Equipos" />
          </ListItemButton>
        </Tooltip>
      </Link>
      <Link to={PATH_FUELING} style={linkCss}>
        <Tooltip title="Abastecimiento" placement="right">
          <ListItemButton selected={isSelect(PATH_FUELING)}>
            <ListItemIcon>
              <LocalGasStation color="primary" />
            </ListItemIcon>
            <ListItemText primary="Abastecimiento" />
          </ListItemButton>
        </Tooltip>
      </Link>
      <Link to={PATH_EMPLOYEE} style={linkCss}>
        <Tooltip title="Colaboradores" placement="right">
          <ListItemButton selected={isSelect(PATH_EMPLOYEE)}>
            <ListItemIcon>
              <People color="primary" />
            </ListItemIcon>
            <ListItemText primary="Colaboradores" />
          </ListItemButton>
        </Tooltip>
      </Link>
      <Link to={PATH_SETTINGS} style={linkCss}>
        <Tooltip title="Configuraciones" placement="right">
          <ListItemButton selected={isSelect(PATH_SETTINGS)}>
            <ListItemIcon>
              <Settings color="primary" />
            </ListItemIcon>
            <ListItemText primary="Configuraciones" />
          </ListItemButton>
        </Tooltip>
      </Link>
    </Fragment>
  )
}

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open"
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9)
      }
    })
  }
}))
export const Menu = ({ open, toggleDrawer }: IMenu) => {
  return (
    <Drawer variant="permanent" open={open} style={{ display: "flex", alignItems: "center" }}>
      <Toolbar
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          px: [1]
        }}
      >
        {/* <img src={require("../../assets/icon.png")} alt="Logo" height={50} /> */}
        <IconButton onClick={toggleDrawer}>
          <ChevronLeft />
        </IconButton>
      </Toolbar>
      <Divider />
      <List component="nav" style={{ flex: 1 }}>
        <MainListItems />
      </List>
      <ListItemButton style={{ flex: 0 }} onClick={logout}>
        <ListItemIcon>
          <Logout color="primary" />
        </ListItemIcon>
        <ListItemText primary="Salir" />
      </ListItemButton>
      <Typography
        style={{ backgroundColor: COLOR_PRIMARY, color: COLOR_SECONDARY }}
        textAlign="center"
        fontSize={12}
      >
        {VERSION_APP}
      </Typography>
    </Drawer>
  )
}
