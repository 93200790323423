import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

import { LS_TOKEN, LS_USER } from "../constants/const"
import { API_CHECK_LOGIN, API_LOGIN, PATH_LOGIN } from "../constants/routes"
import { IAuthResponse, ICheckTokenResponse, IUser } from "../model/user"
import { useFetch } from "./common/petitions"

/**
 * Service Auth application
 * @returns values to form login and status authenticated
 */
export const useAuth = () => {
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [isAuthenticated, setIsAuthenticated] = useState(true)
  const [user, setUser] = useState<IUser>()
  const navigate = useNavigate()

  const { loadData, response } = useFetch<IAuthResponse>({
    method: "post",
    body: JSON.stringify({
      email,
      password
    })
  })
  const { loadData: loadCheckToken, response: responseCheck } = useFetch<ICheckTokenResponse>({
    method: "post",
    body: JSON.stringify({
      email,
      password
    })
  })
  useEffect(() => {
    if (response.payload && response.payload.token) {
      localStorage.setItem(LS_TOKEN, response.payload.token)
      setIsAuthenticated(true)
      window.location.href = "/"
    }
  }, [response.payload])
  useEffect(() => {
    if (responseCheck.payload && responseCheck.payload.valid && responseCheck.payload.user) {
      setIsAuthenticated(true)
      setUser(responseCheck.payload.user)
      localStorage.setItem(LS_USER, JSON.stringify(responseCheck.payload.user))
      navigate(window.location.pathname)
    } else if (responseCheck.errorPayload) {
      navigate(PATH_LOGIN)
    }
  }, [responseCheck.loading])
  useEffect(() => {
    if (!window.location.href.includes(PATH_LOGIN)) loadCheckToken(API_CHECK_LOGIN)
    if (!localStorage.getItem(LS_TOKEN)) navigate(PATH_LOGIN)
  }, [])

  const onChangeEmail = (email: string) => {
    setEmail(email)
  }
  const onChangePassword = (password: string) => {
    setPassword(password)
  }
  const submitAuth = () => {
    loadData(API_LOGIN)
  }

  return {
    email,
    password,
    loadingCheckLogin: response.loading,
    isAuthenticated,
    error: response.errorPayload?.message,
    user,
    onChangeEmail,
    onChangePassword,
    submitAuth
  }
}

export const logout = () => {
  localStorage.removeItem(LS_TOKEN)
  localStorage.removeItem(LS_USER)
  window.location.href = "/login"
}
