import { useEffect, useState } from "react"

import { API_EMPLOYEE } from "../constants/routes"
import { IResponse, IResponseEntity, ValueOf } from "../model/common"
import { EnumEmployeeState, IEmployee } from "../model/employee"
import { useFetch } from "./common/petitions"

export const DEFAULT_EMPLOYEE: Partial<IEmployee> = {
  state: EnumEmployeeState.ACTIVE,
  license_expire: "01-01-0001",
  date_birthday: "01-01-0001",
  name: "Empleado",
  dni: "Selecciona",
  id: "0",
  surname: ""
}
export const DEFAULT_EMPLOYEE_CREATE: Partial<IEmployee> = {
  state: EnumEmployeeState.ACTIVE,
  license_expire: "01-01-0001",
  date_birthday: "01-01-0001",
  name: "",
  dni: "",
  id: "0",
  surname: ""
}
export const useCrudEmployee = () => {
  const [employee, setEmployee] = useState<Partial<IEmployee>>(DEFAULT_EMPLOYEE_CREATE)
  const [refreshEmployee, setRefreshEmployee] = useState(false)
  const { loadData: loadGetEmployee, response: responseGet } = useFetch<IResponse<IEmployee>>({
    method: "get"
  })
  const { loadData: loadCreateEmployee, response: responseCreate } = useFetch<
    IResponseEntity<IEmployee>
  >({
    method: "post"
  })
  const { loadData: loadUpdateEmployee, response: responseUpdate } = useFetch<
    IResponseEntity<IEmployee>
  >({
    method: "put"
  })
  const { loadData: loadDeleteEmployee, response: responseDelete } = useFetch<
    IResponseEntity<IEmployee>
  >({
    method: "delete"
  })

  useEffect(() => {
    loadGetEmployee(API_EMPLOYEE)
  }, [refreshEmployee])

  const updateOrCreateEmployee = () => {
    console.log("emplo to save", employee)
    if (!employee || !employee.dni || !employee.name || !employee.surname) return
    if (employee.date_birthday?.includes("0001")) {
      employee.date_birthday = undefined
    }
    if (employee.license_expire?.includes("0001")) {
      employee.license_expire = undefined
    }
    if (Number(employee.id) > 0) {
      loadUpdateEmployee(`${API_EMPLOYEE}/${employee.id}`, JSON.stringify(employee))
    } else {
      loadCreateEmployee(API_EMPLOYEE, JSON.stringify(employee))
    }
  }
  const handleDeleteEmployee = (id: number) => {
    loadDeleteEmployee(`${API_EMPLOYEE}/${id}`)
  }
  const changeValueEmployee = (key: keyof IEmployee, value: ValueOf<IEmployee>) => {
    setEmployee((prev) => ({
      ...prev,
      [key]: value
    }))
  }

  return {
    data: [DEFAULT_EMPLOYEE, ...(responseGet.payload?.data ?? [])],
    loadingCUD: responseCreate.loading || responseUpdate.loading || responseDelete.loading,
    employee,
    loadingList: responseGet,
    setRefreshEmployee,
    changeValueEmployee,
    handleDeleteEmployee,
    updateOrCreateEmployee,
    setEmployee
  }
}
