import { IEntity, IEquipment } from "./equipment"

export enum EnumTypeFueling {
  SERVICE_STATION = "GRIFO",
  FUEL_TANKER = "CISTERNA"
}
export enum EnumStatusFueling {
  ACTIVE = "ACTIVO",
  INACTIVE = "DESACTIVADO"
}
export interface IFueling {
  id: number
  type: EnumTypeFueling
  status: EnumStatusFueling
  name: string
  resource: IEquipment | IEntity
  input?: number
  output?: number
  stock?: number
  equipment?: IEquipment
  supplier?: IEntity
  resource_id: number
  created_at?: string
  edited_at?: string
  deleted_at?: string
}
