const ResumeDailyPart = ({
  itemsFamily,
  total
}: {
  itemsFamily: { family_name: string; total: number }[]
  total: number
}) => {
  return (
    <table>
      <thead>
        {itemsFamily.map((f, k) => (
          <tr key={k}>
            <td
              style={{
                border: "2px #ccc solid",
                fontWeight: "bold",
                paddingRight: 16,
                textAlign: "end"
              }}
            >
              {f.family_name}: {f.total}h
            </td>
          </tr>
        ))}
        <tr>
          <td
            colSpan={6}
            style={{ border: "2px #ccc solid", fontWeight: "bold", textAlign: "end" }}
          >
            TOTAL: {total}h
          </td>
        </tr>
      </thead>
    </table>
  )
}
export { ResumeDailyPart }
