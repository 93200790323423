import { Dispatch, SetStateAction } from "react"

import { CompareArrows, Delete, Edit } from "@mui/icons-material"
import { IconButton, Tooltip } from "@mui/material"
import { GridColDef } from "@mui/x-data-grid"
import { IEquipment } from "model/equipment"

interface IColumnEquipment {
  setEditEquipment: Dispatch<SetStateAction<Partial<IEquipment> | undefined>>
  setDeleteEquipment: Dispatch<SetStateAction<Partial<IEquipment> | undefined>>
  setMovement: Dispatch<SetStateAction<Partial<IEquipment> | undefined>>
}

export const columnEquipmentList: (
  params: IColumnEquipment
) => GridColDef<Partial<IEquipment>>[] = ({
  setDeleteEquipment,
  setEditEquipment,
  setMovement
}) => {
  return [
    {
      field: "code",
      headerName: "Código",
      width: 100,
      valueGetter: ({ row }) => row.code
    },
    {
      field: "plate",
      headerName: "Placa",
      width: 100,
      valueGetter: ({ row }) => row.plate,
      renderCell: ({ row }) => (
        <Tooltip title={row.name} placement="left">
          <span>{row.plate}</span>
        </Tooltip>
      )
    },
    {
      field: "family",
      headerName: "Familia",
      width: 120,
      valueGetter: ({ row }) => row.family?.family_name ?? ""
    },
    {
      field: "project",
      headerName: "Proyectos",
      flex: 1,
      valueGetter: ({ row }) => row.movements_active?.map((p) => p.project?.os ?? "").join(", ")
    },
    {
      field: "brand",
      headerName: "Marca",
      width: 120,
      valueGetter: ({ row }) => row.brand
    },
    {
      field: "model",
      headerName: "Modelo",
      width: 120,
      valueGetter: ({ row }) => row.model
    },
    {
      field: "series",
      headerName: "Serie",
      valueGetter: ({ row }) => row.series,
      width: 150
    },
    {
      field: "n_motor",
      headerName: "N°Motor",
      valueGetter: ({ row }) => row.n_motor,
      width: 100
    },
    {
      field: "power",
      headerName: "Potencia ",
      width: 100,
      valueGetter: ({ row }) => row.power
    },
    {
      field: "color",
      headerName: "Color ",
      width: 100,
      valueGetter: ({ row }) => row.color
    },
    {
      field: "year_production",
      headerName: "Año F.",
      width: 100,
      valueGetter: ({ row }) => row.year_production
    },
    {
      field: "hourmeter",
      headerName: "Horómetro ",
      width: 100,
      valueGetter: ({ row }) => row.hourmeter
    },
    {
      field: "mileage",
      headerName: "Kilometraje ",
      width: 100,
      valueGetter: ({ row }) => row.mileage
    },
    {
      field: "has_gps",
      headerName: "GPS ",
      width: 100,
      valueGetter: ({ row }) => (row.has_gps ? "SI" : "NO")
    },
    {
      field: "expire_gps",
      headerName: "GPS-V",
      valueGetter: ({ row }) => row.expire_gps,
      width: 100
    },
    {
      field: "soat",
      valueGetter: ({ row }) => row.soat,
      headerName: "SOAT",
      width: 100
    },
    {
      field: "expire_soat",
      headerName: "SOAT-V",
      valueGetter: ({ row }) => row.expire_soat,
      width: 100
    },
    {
      field: "has_trec",
      headerName: "Trec",
      width: 100,
      valueGetter: ({ row }) => (row.has_trec ? "SI" : "NO")
    },
    {
      field: "expire_trec",
      headerName: "Trec-V",
      valueGetter: ({ row }) => row.expire_trec,
      width: 100
    },
    {
      field: "has_inspection_technical",
      headerName: "Inspección",
      width: 100,
      valueGetter: ({ row }) => (row.has_inspection_technical ? "SI" : "NO")
    },
    {
      field: "expire_inspection",
      headerName: "Inspección-V",
      width: 100,
      valueGetter: ({ row }) => row.expire_inspection
    },
    {
      field: "expire_card_merchandise",
      headerName: "F. Tarjeta merc.",
      width: 100,
      valueGetter: ({ row }) => row.expire_card_merchandise
    },

    {
      field: "own",
      headerName: "Tipo",
      width: 120,
      valueGetter: ({ row }) => (row.own ? "PROPIO" : `ALQUI. (${row.rental ?? "--"})`)
    },
    {
      field: "supplier",
      headerName: "Proveedor",
      width: 120,
      valueGetter: ({ row }) => row.supplier?.business_name
    },
    {
      field: "fee",
      headerName: "P. Alquiler",
      width: 100,
      valueGetter: ({ row }) => row.fee_price
    },
    {
      field: "purchase_price",
      headerName: "P. Compra",
      width: 100,
      valueGetter: ({ row }) => row.purchase_price
    },
    {
      field: "tariff",
      headerName: "Tarifa básica",
      width: 100,
      renderCell: ({ row }) => `$ ${Number(row.tariff_price ?? 0).toFixed(2)}`
    },

    {
      field: "_",
      headerName: "Opciones",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      width: 100,
      renderCell: ({ row }) => (
        <>
          <Tooltip title={`Editar o visualizar`}>
            <IconButton size="small" onClick={() => setEditEquipment(row)}>
              <Edit color="warning" fontSize="small" />
            </IconButton>
          </Tooltip>
          <Tooltip title={`Eliminar`}>
            <IconButton size="small" onClick={() => setDeleteEquipment(row)}>
              <Delete color="error" fontSize="small" />
            </IconButton>
          </Tooltip>
          <Tooltip title={`Ingreso/Salida`}>
            <IconButton size="small" onClick={() => setMovement(row)}>
              <CompareArrows color="info" fontSize="small" />
            </IconButton>
          </Tooltip>
        </>
      )
    }
  ]
}
