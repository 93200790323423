import { useEffect, useState } from "react"

import { Box, Button, FormControlLabel, Grid, Switch, TextField } from "@mui/material"
import { DataGrid } from "@mui/x-data-grid"
import FormDialog from "components/common/Dialog"
import { INITIAL_STATE_DATAGRID, PAGE_SIZE_OPTIONS, localizedTextsMap } from "constants/const"
import { DEFAULT_SHIFT, useCrudSchedule } from "hooks/shift"
import { ValueOf } from "model/common"
import { ISchedule } from "model/shift"
import { calculateDuration, dateToString } from "utils"

import { columnShift } from "./const"

export const FormSchedule = ({
  changeValueShift,
  schedule
}: {
  changeValueShift: (key: keyof ISchedule, value: ValueOf<ISchedule>) => void
  schedule: Partial<ISchedule>
}) => {
  useEffect(() => {
    changeValueShift("end_night", schedule.start_day)
  }, [schedule.start_day])
  useEffect(() => {
    changeValueShift("start_night", schedule.end_day)
  }, [schedule.end_day])
  return (
    <Grid container>
      <Box width="100%" height={8} />
      <Grid container>
        <Grid item flex={1}>
          <TextField
            size="small"
            value={schedule.name ?? ""}
            onChange={(e) => changeValueShift("name", e.target.value)}
            fullWidth
            autoFocus
            disabled={!!schedule.deleted_at}
            label="Nombre"
            variant="outlined"
          />
          <Box mt={1} mb={0.5}>
            Día
          </Box>
          <Grid container alignItems={"center"}>
            <TextField
              size="small"
              value={schedule.start_day ?? ""}
              disabled={!!schedule.deleted_at}
              onChange={(e) => changeValueShift("start_day", e.target.value)}
              label="Inicio"
              variant="outlined"
              type="time"
              style={{ flex: 1 }}
            />
            <TextField
              size="small"
              disabled={!!schedule.deleted_at}
              value={schedule.end_day ?? ""}
              onChange={(e) => changeValueShift("end_day", e.target.value)}
              label="Fin"
              variant="outlined"
              type="time"
              style={{ flex: 1 }}
            />
            <label>
              {calculateDuration(schedule.start_day ?? "", schedule.end_day ?? "").toFixed(1)}h
            </label>
          </Grid>

          <Box mt={1} mb={0.5}>
            Noche
          </Box>
          <Grid container alignItems={"center"}>
            <TextField
              size="small"
              value={schedule.start_night ?? ""}
              disabled
              onChange={(e) => changeValueShift("start_night", e.target.value)}
              style={{ flex: 1 }}
              label="Inicio"
              type="time"
              variant="outlined"
            />
            <TextField
              size="small"
              value={schedule.end_night ?? ""}
              disabled
              onChange={(e) => changeValueShift("end_night", e.target.value)}
              style={{ flex: 1 }}
              label="Fin"
              type="time"
              variant="outlined"
            />
            <label>
              {calculateDuration(schedule.start_night ?? "", schedule.end_night ?? "").toFixed(1)}h
            </label>
          </Grid>
          <FormControlLabel
            onChange={(e, checked) =>
              changeValueShift("deleted_at", checked ? undefined : dateToString(new Date()))
            }
            control={<Switch checked={!schedule.deleted_at} />}
            label="ACTIVO"
          />
        </Grid>
      </Grid>
    </Grid>
  )
}
export const ListContainerSchedule = () => {
  const [openForm, setOpenForm] = useState(false)
  const [scheduleEdit, setScheduleEdit] = useState<ISchedule | undefined>(undefined)
  const [scheduleDeleted, setScheduleDeleted] = useState<ISchedule>()
  const [scheduleDefault, setScheduleDefault] = useState<ISchedule>()

  const {
    loadingList,
    loadingCUD,
    data,
    schedule,
    changeValueSchedule,
    handleDefaultSchedule,
    handleDeleteSchedule,
    refreshScheduleList,
    setSchedule,
    updateOrCreateSchedule
  } = useCrudSchedule()

  useEffect(() => {
    if (scheduleEdit) {
      setSchedule(scheduleEdit)
    }
    setOpenForm(!!scheduleEdit)
  }, [scheduleEdit])

  useEffect(() => {
    if (!loadingCUD) {
      setOpenForm(false)
      setSchedule(DEFAULT_SHIFT)
      setScheduleDeleted(undefined)
      refreshScheduleList()
      setScheduleDefault(undefined)
    }
  }, [loadingCUD])
  useEffect(() => {
    if (!openForm) {
      setScheduleEdit(undefined)
      setSchedule(DEFAULT_SHIFT)
    }
  }, [openForm])
  return (
    <Grid container>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Button size="small" variant="contained" onClick={() => setOpenForm((prev) => !prev)}>
          Crear Horario
        </Button>
      </Box>
      <Grid item md={12}>
        <DataGrid
          density="compact"
          rows={data}
          columns={columnShift({
            setDeleteShift: setScheduleDeleted,
            setEditShift: setScheduleEdit,
            setActiveDefault: setScheduleDefault
          })}
          pagination
          autoHeight
          initialState={INITIAL_STATE_DATAGRID}
          disableRowSelectionOnClick
          pageSizeOptions={PAGE_SIZE_OPTIONS}
          localeText={localizedTextsMap}
          loading={loadingList}
        />
      </Grid>
      <FormDialog
        open={openForm}
        visibleButtons={!loadingCUD && schedule.start_day !== schedule.end_day}
        setOpen={setOpenForm}
        maxWidth="xs"
        handleConfirm={updateOrCreateSchedule}
        title={scheduleEdit ? "Editar turno: " + scheduleEdit.name : `Nuevo Turno`}
      >
        <FormSchedule changeValueShift={changeValueSchedule} schedule={schedule} />
      </FormDialog>

      <FormDialog
        open={!!scheduleDeleted}
        handleConfirm={() => handleDeleteSchedule(Number(scheduleDeleted?.id) ?? 0)}
        title="Eliminar Registro"
        setOpen={() => {
          setScheduleDeleted(undefined)
        }}
      >
        <label>Vamos a eliminar el registro: {scheduleDeleted?.name}</label>
      </FormDialog>
      <FormDialog
        open={!!scheduleDefault}
        handleConfirm={() => handleDefaultSchedule(Number(scheduleDefault?.id) ?? 0)}
        title="Activar por defecto"
        setOpen={() => {
          setScheduleDefault(undefined)
        }}
      >
        <label>
          Esto ayuda a automatizar el registro de parte diario, este turno saldrá por defecto
          seleccionado, obviamente podrá elegir otro, (esto no afecta a registros anteriores):{" "}
          {scheduleDeleted?.name}
        </label>
      </FormDialog>
    </Grid>
  )
}
