import { useState } from "react"

import { API_ENTITY } from "../constants/routes"
import { IResponse, IResponseEntity, ValueOf } from "../model/common"
import { EnumEntity, IEntity } from "../model/equipment"
import { useFetch } from "./common/petitions"

export const DEFAULT_ENTITY: Partial<IEntity> = {
  business_name: "",
  ruc: "",
  id: 0
}

export const useEntityList = ({ type }: { type: EnumEntity }) => {
  const { loadData, response } = useFetch<IResponse<IEntity>>({
    method: "get"
  })
  const list = response.payload?.data ?? []
  return {
    entityList: [...list],
    loadingList: response.loading,
    errorList: response.errorPayload,
    refreshEntityList: () => loadData(`${API_ENTITY}?type=${type}`)
  }
}

export const useCrudEntity = ({ type }: { type: EnumEntity }) => {
  const [entity, setEntity] = useState<Partial<IEntity>>(DEFAULT_ENTITY)
  const { loadData: loadGetEntity, response: responseGet } = useFetch<IResponse<IEntity>>({
    method: "get"
  })
  const { loadData: loadCreateEntity, response: responseCreate } = useFetch<
    IResponseEntity<IEntity>
  >({
    method: "post"
  })
  const { loadData: loadUpdateEntity, response: responseUpdate } = useFetch<
    IResponseEntity<IEntity>
  >({
    method: "put"
  })
  const { loadData: loadDeleteEntity, response: responseDelete } = useFetch<
    IResponseEntity<IEntity>
  >({
    method: "delete"
  })

  const updateOrCreateEntity = () => {
    console.log("Entity to save", entity)
    if (!entity || !entity.business_name || !entity.ruc) return
    entity.type = type
    if (Number(entity.id) > 0) {
      if (!!entity.deleted_at) {
        handleDeleteEntity(Number(entity.id))
      } else {
        restore()
      }
      loadUpdateEntity(`${API_ENTITY}/${entity.id}`, JSON.stringify(entity))
    } else {
      loadCreateEntity(API_ENTITY, JSON.stringify(entity))
    }
  }
  const restore = () => {
    loadUpdateEntity(`${API_ENTITY}/${entity.id}/restore`, JSON.stringify(entity))
  }
  const handleDeleteEntity = (id: number) => {
    loadDeleteEntity(`${API_ENTITY}/${id}`)
  }
  const changeValueEntity = (key: keyof IEntity, value: ValueOf<IEntity>) => {
    setEntity((prev) => ({
      ...prev,
      [key]: value
    }))
  }

  return {
    data: responseGet.payload?.data ?? [],
    loadingCUD: responseCreate.loading || responseUpdate.loading || responseDelete.loading,
    entity,
    loadingList: responseGet.loading,

    refreshEntityList: () => loadGetEntity(`${API_ENTITY}?type=${type}`),
    changeValueEntity,
    handleDeleteEntity,
    updateOrCreateEntity,
    setEntity
  }
}
