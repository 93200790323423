import { useLayoutEffect } from "react"

import * as am5 from "@amcharts/amcharts5"
import * as am5xy from "@amcharts/amcharts5/xy"
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated"

import { COLOR_PRIMARY, COLOR_SECONDARY } from "constants/index"

import { ChartData } from "./types"

type ChartProps = {
  id: string
  chartData: ChartData
  endScroll?: number
  labelText?: string
  disableScroll?: boolean
  flex?: number
  height?: number
}
function ChartLine({
  id,
  chartData,
  endScroll = 0.5,
  labelText = "[bold]{categoryX}[/]\n{valueY}",
  disableScroll = false,
  flex = 1,
  height = 500
}: ChartProps) {
  useLayoutEffect(() => {
    const root = am5.Root.new(id)

    root.setThemes([am5themes_Animated.new(root)])

    const chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panY: false,
        layout: root.verticalLayout,
        wheelX: "panX",
        wheelY: !disableScroll ? "zoomX" : "none",
        pinchZoomX: true
      })
    )
    chart.topAxesContainer.children.push(
      am5.Label.new(root, {
        text: chartData.title?.toUpperCase(),
        fontWeight: "bold",
        fontSize: 10,
        x: 0
      })
    )
    if (!disableScroll) {
      chart.set(
        "scrollbarX",
        am5.Scrollbar.new(root, {
          orientation: "horizontal",
          end: endScroll
        })
      )
    }

    // Create Y-axis
    const yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: am5xy.AxisRendererY.new(root, {})
      })
    )
    yAxis.get("renderer").labels.template.setAll({
      fontSize: 8,
      fontWeight: "bold"
    })
    const xRenderer = am5xy.AxisRendererX.new(root, {
      minGridDistance: 30,
      minorGridEnabled: true
    })

    xRenderer.labels.template.setAll({
      centerY: am5.p50,
      centerX: am5.p100,
      fontSize: 11
    })
    xRenderer.grid.template.setAll({
      location: 1
    })
    // Create X-Axis
    const xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        renderer: xRenderer,
        categoryField: "category"
      })
    )
    xAxis.data.setAll(chartData.data)

    chartData.series.forEach((s) => {
      const series = chart.series.push(
        am5xy.LineSeries.new(root, {
          name: s.name,
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: s.valueYField,
          categoryXField: s.categoryXField,
          sequencedInterpolation: true,

          tooltip: am5.Tooltip.new(root, {
            labelText: labelText,
            showTooltipOn: "always"
          })
        })
      )
      series.data.setAll(chartData.data)
      series.bullets.push(function () {
        var bulletCircle = am5.Circle.new(root, {
          radius: 5,
          fill: series.get("fill")
        })
        return am5.Bullet.new(root, {
          sprite: bulletCircle
        })
      })
      series.appear()
      series.set("fill", am5.color(s.color || COLOR_PRIMARY))
      series.set("stroke", am5.color(s.color || COLOR_SECONDARY))
    })

    // Add legend
    const legend = chart.children.push(am5.Legend.new(root, {}))
    legend.data.setAll(chart.series.values)

    // Add cursor
    chart.set("cursor", am5xy.XYCursor.new(root, {}))

    return () => {
      root.dispose()
    }
  }, [chartData])

  return <div id={id} style={{ flex, height }}></div>
}
export default ChartLine
