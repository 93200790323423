import { useEffect, useState } from "react"

import { Refresh } from "@mui/icons-material"
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  LinearProgress,
  Radio,
  RadioGroup,
  TextField
} from "@mui/material"
import { DataGrid, GridColumnVisibilityModel, GridToolbar } from "@mui/x-data-grid"
import FormDialog from "components/common/Dialog"
import { INITIAL_STATE_DATAGRID, PAGE_SIZE_OPTIONS, localizedTextsMap } from "constants/const"
import { useEntityList } from "hooks/entity"
import { DEFAULT_PROJECT_CREATE, useCrudProject } from "hooks/project"
import { useScheduleList } from "hooks/shift"
import { EnumEntity, IEntity } from "model/equipment"
import { EnumProjectState, IProject } from "model/project"

import { DEFAULT_VISIBLE_COLUMNS, columnProject } from "./const"

export const FormProject = ({
  changeValueProject,
  project
}: {
  changeValueProject: Function
  project: Partial<IProject>
}) => {
  const { scheduleList, loadingList, refreshSchedule } = useScheduleList()
  const {
    errorList: errorCustomerList,
    loadingList: loadingCustomerList,
    entityList,
    refreshEntityList
  } = useEntityList({ type: EnumEntity.CUSTOMER })
  useEffect(() => {
    refreshSchedule()
    refreshEntityList()
  }, [])
  const changeCustomer = (sup?: Partial<IEntity> | null) => {
    changeValueProject("customer", sup ?? undefined)
    changeValueProject("customer_id", sup?.id ?? 0)
  }
  return (
    <Grid container>
      {errorCustomerList?.message && <span>{errorCustomerList?.message}</span>}
      <Box width="100%" height={8} />
      <Grid container>
        <Grid item flex={1}>
          <Box height={8} />
          <Grid container flex={1}>
            <Autocomplete
              style={{ flex: 1 }}
              loading={loadingCustomerList}
              value={project.customer}
              onChange={(_, value) => changeCustomer(value)}
              size="small"
              isOptionEqualToValue={(val, other) => Number(val?.id) === Number(other?.id)}
              options={entityList ?? []}
              getOptionLabel={(opt) => `${opt.ruc}-${opt.business_name}`}
              renderInput={(params) => <TextField {...params} label="Cliente" />}
            />
            <IconButton onClick={refreshEntityList}>
              <Refresh fontSize="small" />
            </IconButton>
          </Grid>
          <Box height={8} />
          <Grid container>
            <TextField
              size="small"
              value={project.code ?? ""}
              disabled={!!project.deleted_at}
              onChange={(e) => changeValueProject("code", e.target.value)}
              label="Código de proyecto"
              variant="outlined"
            />
            <TextField
              size="small"
              disabled={!!project.deleted_at}
              value={project.os ?? ""}
              onChange={(e) => changeValueProject("os", e.target.value)}
              label="OS"
              variant="outlined"
            />
            <TextField
              InputProps={{ startAdornment: "$" }}
              size="small"
              value={project.projected_cost ?? ""}
              disabled={!!project.deleted_at}
              onChange={(e) => changeValueProject("projected_cost", e.target.value)}
              label="Costo de proyectado"
              variant="outlined"
              type="number"
            />
          </Grid>

          <Box height={8} />
          <TextField
            size="small"
            value={project.name ?? ""}
            disabled={!!project.deleted_at}
            onChange={(e) => changeValueProject("name", e.target.value)}
            label="Nombre de proyecto"
            fullWidth
            variant="outlined"
          />

          <Box height={8} />
          <TextField
            value={project.description ?? ""}
            disabled={!!project.deleted_at}
            onChange={(e) => changeValueProject("description", e.target.value)}
            fullWidth
            multiline
            label="Descripción de proyecto"
            variant="outlined"
          />

          <FormControl>
            <Grid container alignItems="center">
              <FormLabel>Horario</FormLabel>
              <IconButton onClick={refreshSchedule} size="small">
                <Refresh fontSize="small" />
              </IconButton>
            </Grid>

            {loadingList ? (
              <LinearProgress />
            ) : (
              <RadioGroup
                onChange={(e, v) => {
                  changeValueProject("schedule_id", v)
                  changeValueProject(
                    "schedule",
                    scheduleList.find((s) => Number(s.id) === Number(s))
                  )
                }}
                value={project.schedule_id ?? "0"}
                name="row-radio-buttons-group-project"
              >
                {scheduleList.map((sc, key) => (
                  <FormControlLabel
                    key={key}
                    value={sc.id}
                    disabled={!!project.deleted_at}
                    control={<Radio />}
                    label={`${sc.name} (Día: ${sc.start_day}, Noche: ${sc.start_night})`}
                    checked={Number(project.schedule_id) === Number(sc.id)}
                  />
                ))}
              </RadioGroup>
            )}
          </FormControl>
          <Box height={16} />
          <Grid container>
            <TextField
              size="small"
              disabled={!!project.deleted_at}
              value={project.start_at ?? "--"}
              onChange={(e) => changeValueProject("start_at", e.target.value)}
              label="Fecha de inicio"
              variant="outlined"
              type="date"
            />
            <TextField
              size="small"
              disabled={!!project.deleted_at}
              value={project.end_at ?? "--"}
              onChange={(e) => changeValueProject("end_at", e.target.value)}
              label="Fecha de fin estimado"
              variant="outlined"
              type="date"
            />
            <TextField
              size="small"
              disabled={!!project.deleted_at}
              value={project.delivery_at ?? "--"}
              onChange={(e) => changeValueProject("delivery_at", e.target.value)}
              label="Fecha de entrega"
              variant="outlined"
              type="date"
            />
          </Grid>
          <FormControl>
            <FormLabel>Estado</FormLabel>
            <RadioGroup
              onChange={(e, v) => changeValueProject("state", v)}
              value={project.state}
              row
              name="row-radio-buttons-group"
            >
              <FormControlLabel
                value={EnumProjectState.ACTIVE}
                control={<Radio />}
                label={EnumProjectState.ACTIVE}
                checked={project.state === EnumProjectState.ACTIVE}
              />
              <FormControlLabel
                value={EnumProjectState.FINALIZE}
                control={<Radio />}
                label={EnumProjectState.FINALIZE}
                checked={project.state === EnumProjectState.FINALIZE}
              />
              <FormControlLabel
                value={EnumProjectState.DELETED}
                control={<Radio />}
                label={EnumProjectState.DELETED}
                checked={project.state === EnumProjectState.DELETED}
              />
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>
    </Grid>
  )
}

export const ListContainerProject = () => {
  const [openForm, setOpenForm] = useState(false)
  const [projectEdit, setProjectEdit] = useState<IProject | undefined>(undefined)
  const [projectDeleted, setProjectDeleted] = useState<IProject>()
  const [visibleColumns, setVisibleColumns] = useState<GridColumnVisibilityModel | undefined>(
    JSON.parse(localStorage.getItem("VISIBLE_PROJECT_LIST") ?? "{}") ?? undefined
  )
  useEffect(() => {
    const ls = localStorage.getItem("VISIBLE_PROJECT_LIST")
    if (!ls) {
      localStorage.setItem("VISIBLE_PROJECT_LIST", JSON.stringify(DEFAULT_VISIBLE_COLUMNS))
      setVisibleColumns(DEFAULT_VISIBLE_COLUMNS)
    }
  }, [])
  const {
    loadingList,
    loadingCUD,
    project,
    data,
    changeValueProject,
    handleDeleteProject,
    setProject,
    refreshProjectList,
    updateOrCreateProject
  } = useCrudProject({ showDeleted: true })

  useEffect(() => {
    if (projectEdit) {
      setProject(projectEdit)
    }
    setOpenForm(!!projectEdit)
  }, [projectEdit])

  useEffect(() => {
    if (!loadingCUD) {
      setOpenForm(false)
      setProject({})
      setProjectDeleted(undefined)
      setProjectEdit(undefined)
      refreshProjectList()
    }
  }, [loadingCUD])
  useEffect(() => {
    if (!openForm) {
      setProjectEdit(undefined)
      setProject(DEFAULT_PROJECT_CREATE)
    }
  }, [openForm])
  return (
    <Grid container>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Button size="small" variant="contained" onClick={() => setOpenForm((prev) => !prev)}>
          Crear Proyecto
        </Button>
      </Box>
      <Grid container>
        <div style={{ width: "100%" }}>
          <DataGrid
            slots={{ toolbar: GridToolbar }}
            density="compact"
            rows={data}
            columns={columnProject({
              setDeleteProject: setProjectDeleted,
              setEditProject: setProjectEdit
            })}
            onColumnVisibilityModelChange={(item) => {
              setVisibleColumns(item)
              localStorage.setItem("VISIBLE_PROJECT_LIST", JSON.stringify(item))
            }}
            columnVisibilityModel={visibleColumns}
            pagination
            autoHeight
            initialState={INITIAL_STATE_DATAGRID}
            disableRowSelectionOnClick
            pageSizeOptions={PAGE_SIZE_OPTIONS}
            localeText={localizedTextsMap}
            loading={loadingList}
          />
        </div>
      </Grid>
      <FormDialog
        open={openForm}
        visibleButtons={!loadingCUD}
        setOpen={setOpenForm}
        maxWidth="md"
        handleConfirm={updateOrCreateProject}
        title={projectEdit ? "Editar Proyecto: " + projectEdit.name : `Nuevo Proyecto`}
      >
        <FormProject changeValueProject={changeValueProject} project={project} />
      </FormDialog>

      <FormDialog
        open={!!projectDeleted}
        handleConfirm={() => handleDeleteProject(Number(projectDeleted?.id) ?? 0)}
        title="Eliminar Registro"
        setOpen={() => {
          setProjectDeleted(undefined)
        }}
      >
        <label>Vamos a eliminar el registro: {projectDeleted?.name}</label>
      </FormDialog>
    </Grid>
  )
}
