import { useState } from "react"

import { Splitscreen } from "@mui/icons-material"
import { Box, Grid, Paper, Tab, Tabs } from "@mui/material"
import { CardTitle } from "components/common/CardTitle"
import { EnumEntity } from "model/equipment"

import { ListContainerEntity } from "./ListContainerEntity"
import { ListContainerFamily } from "./ListContainerFamily"
import { ListContainerProject } from "./ListContainerProject"
import { ListContainerSchedule } from "./ListContainerSchedule"

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={{ display: "flex", width: "100%" }}
      {...other}
    >
      {value === index && <Box sx={{ p: 3, width: "100%" }}>{children}</Box>}
    </div>
  )
}
function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  }
}
export const SettingsContainerTabs = () => {
  const [value, setValue] = useState(Number(localStorage.getItem("tab_settings")))

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    localStorage.setItem("tab_settings", `${newValue}`)
    setValue(newValue)
  }
  return (
    <Grid component={Paper} container paddingTop={1}>
      <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
        <Tab label="Familias" {...a11yProps(0)} />
        <Tab label="Proyectos" {...a11yProps(1)} />
        <Tab label="Clientes" {...a11yProps(2)} />
        <Tab label="Horarios" {...a11yProps(3)} />
        <Tab label="Proveedores" {...a11yProps(4)} />
      </Tabs>
      <CustomTabPanel value={value} index={0}>
        <ListContainerFamily />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <ListContainerProject />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <ListContainerEntity type={EnumEntity.CUSTOMER} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={3}>
        <ListContainerSchedule />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={4}>
        <ListContainerEntity type={EnumEntity.SUPPLIER} />
      </CustomTabPanel>
    </Grid>
  )
}

export const SettingsContainer = () => {
  return (
    <Grid container gap={1} flexDirection={"column"} alignItems={"end"}>
      <Grid container justifyContent={"space-around"}>
        <CardTitle
          title="Configuraciones de Tareo"
          subtitle="Lista de opciones dinámicas"
          icon={<Splitscreen fontSize="large" color="secondary" />}
          flex={1}
        />
      </Grid>
      <Grid container>
        <SettingsContainerTabs />
      </Grid>
    </Grid>
  )
}
