export const PATH_HOME = "/"
export const PATH_LOGIN = "/login"
export const PATH_EQUIPMENT = "/equipment"
export const PATH_EMPLOYEE = "/employee"
export const PATH_FUELING = "/fueling"
export const PATH_USER = "/user"
export const PATH_SETTINGS = "/settings"
export const PATH_RECORD_DP = "/record-daily-part"

export const API_HOST = process.env.REACT_APP_API_HOST
export const API_VERSION = "v1"
export const API_EMPLOYEE = `${API_HOST}/api/employee`
export const API_MOVEMENTS = `${API_HOST}/api/movement`
export const API_PROJECT = `${API_HOST}/api/project`
export const API_SCHEDULE = `${API_HOST}/api/schedule`
export const API_DAILY_PART = `${API_HOST}/api/daily-part`
export const API_EXPORT = `${API_HOST}/api/export`
export const API_DAILY_BY_DATE = `${API_HOST}/api/equipment/dailyByDate/`
export const API_TIMELINE = `${API_HOST}/api/timeline`
export const API_EQUIPMENT = `${API_HOST}/api/equipment`
export const API_ENTITY = `${API_HOST}/api/entity`
export const API_FUELING = `${API_HOST}/api/fueling`
export const API_FUELING_INPUT = `${API_HOST}/api/fueling-input`
export const API_FUELING_INPUT_DELETE = `${API_HOST}/api/fueling-input-delete`
export const API_FUELING_OUTPUT = `${API_HOST}/api/dispatches`
export const API_EQUIPMENT_FAMILY = `${API_HOST}/api/family`
export const API_LOGIN = `${API_HOST}/api/login`
export const API_CHECK_LOGIN = `${API_HOST}/api/check-token`
export const API_HOME = `${API_HOST}/api/home`
