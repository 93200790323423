import { Dispatch, SetStateAction } from "react"

import { Delete, Edit } from "@mui/icons-material"
import { IconButton, Tooltip } from "@mui/material"
import { GridColDef } from "@mui/x-data-grid"
import { IEmployee } from "model/employee"

interface IColumnEmployee {
  setEditEmployee: Dispatch<SetStateAction<Partial<IEmployee> | undefined>>
  setDeleteEmployee: Dispatch<SetStateAction<Partial<IEmployee> | undefined>>
}

export const columnEmployeeList: (params: IColumnEmployee) => GridColDef<Partial<IEmployee>>[] = ({
  setDeleteEmployee,
  setEditEmployee
}) => {
  return [
    {
      field: "dni",
      headerName: "DNI",
      width: 100
    },
    {
      field: "name",
      headerName: "Nombres",
      width: 100,
      valueGetter: (value) => value.row.name + " " + value.row.surname,
      flex: 1
    },

    {
      field: "license",
      headerName: "Licencia",
      width: 100
    },
    {
      field: "license_expire",
      headerName: "Expira",
      width: 100
    },
    {
      field: "position",
      headerName: "Cargo",
      width: 100
    },
    {
      field: "phone",
      headerName: "Teléfono",
      width: 100
    },
    {
      field: "email",
      headerName: "Correo",
      width: 300
    },
    {
      field: "date_birthday",
      headerName: "Fec Nac",
      width: 100
    },
    {
      field: "_",
      headerName: "Opciones",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      width: 160,
      renderCell: ({ row }) => (
        <>
          <Tooltip title={`Editar o visualizar el registro`}>
            <IconButton size="small" onClick={() => setEditEmployee(row)}>
              <Edit color="warning" fontSize="small" />
            </IconButton>
          </Tooltip>
          <Tooltip title={`Eliminar el registro`}>
            <IconButton size="small" onClick={() => setDeleteEmployee(row)}>
              <Delete color="error" fontSize="small" />
            </IconButton>
          </Tooltip>
        </>
      )
    }
  ]
}
