import { Dispatch, SetStateAction } from "react"

import { CompareArrows, Delete, Edit } from "@mui/icons-material"
import { IconButton, Tooltip } from "@mui/material"
import { GridColDef } from "@mui/x-data-grid"
import { IFueling } from "model/fueling"
import { IFuelingInput, IFuelingOutput } from "model/fueling_input"

interface IColumnFueling {
  setMovementsFueling: Dispatch<SetStateAction<IFueling | undefined>>
  setEditFueling: Dispatch<SetStateAction<IFueling | undefined>>
  setDeleteFueling: Dispatch<SetStateAction<IFueling | undefined>>
}

export const columnFueling: (params: IColumnFueling) => GridColDef<IFueling>[] = ({
  setEditFueling,
  setMovementsFueling,
  setDeleteFueling
}) => {
  return [
    {
      field: "id",
      headerName: "ID",
      valueGetter: ({ row }) => row.id
    },

    {
      field: "type",
      headerName: "Tipo",
      valueGetter: ({ row }) => row.type
    },
    {
      field: "name",
      headerName: "Nombre",
      valueGetter: ({ row }) => row.name,
      width: 250
    },
    {
      field: "stock",
      headerName: "Stock",
      valueGetter: ({ row }) => row.stock,
      width: 100
    },
    {
      field: "created_at",
      headerName: "Fecha de creación",
      width: 200
    },
    {
      field: "updated_at",
      headerName: "Fecha de edición",
      width: 200
    },
    {
      field: "deleted_at",
      headerName: "Fecha de eliminación",
      width: 200
    },
    {
      field: "state",
      headerName: "Estado",
      valueGetter: ({ row }) => row.status
    },
    {
      field: "_",
      headerName: "Opciones",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      renderCell: ({ row }) => (
        <>
          <Tooltip title={`Editar`}>
            <IconButton size="small" onClick={() => setEditFueling(row)}>
              <Edit color="error" fontSize="small" />
            </IconButton>
          </Tooltip>
          <Tooltip title={`Eliiminar registro`}>
            <IconButton size="small" onClick={() => setDeleteFueling(row)}>
              <Delete color="error" fontSize="small" />
            </IconButton>
          </Tooltip>
          <Tooltip title={`Ingreso/Salida`}>
            <IconButton size="small" onClick={() => setMovementsFueling(row)}>
              <CompareArrows color="info" fontSize="small" />
            </IconButton>
          </Tooltip>
        </>
      )
    }
  ]
}
interface IColumnFuelingInput {
  setDeleteInput: Dispatch<SetStateAction<Partial<IFuelingInput> | undefined>>
}

export const columnFuelingInput: (params: IColumnFuelingInput) => GridColDef<IFuelingInput>[] = (
  params
) => {
  return [
    {
      field: "quantity",
      headerName: "Cantidad",
      valueGetter: ({ row }) => row.quantity
    },
    {
      field: "type",
      headerName: "Tipo"
    },
    {
      field: "created_at",
      headerName: "F. Ingreso",
      renderCell: ({ row }) => row.created_at?.slice(0, 10),
      width: 250
    },
    {
      field: "_",
      headerName: "Opciones",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      width: 50,
      renderCell: ({ row }) => (
        <>
          {/* <Tooltip title={`Editar`}>
            <IconButton size="small" onClick={() => {}}>
              <Edit color="error" fontSize="small" />
            </IconButton>
          </Tooltip>*/}
          <Tooltip title={`Eliminar registro`}>
            <IconButton size="small" onClick={() => params.setDeleteInput(row)}>
              <Delete color="error" fontSize="small" />
            </IconButton>
          </Tooltip>
        </>
      )
    }
  ]
}
type IColumnFuelingOutput = {}
export const columnFuelingOutput: (
  params: IColumnFuelingOutput
) => GridColDef<IFuelingOutput>[] = () => {
  return [
    {
      field: "equipment",
      headerName: "Equipo",
      valueGetter: ({ row }) => row.equipment?.plate
    },
    {
      field: "quantity",
      headerName: "Cantidad",
      valueGetter: ({ row }) => row.cantidad
    },
    {
      field: "fecha",
      headerName: "F. Despacho",
      renderCell: ({ row }) => row.fecha?.slice(0, 10),
      width: 250
    }
  ]
}
