import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@mui/material"
import { GridExpandMoreIcon } from "@mui/x-data-grid"
import { useFuelingMovementReport } from "hooks/fueling"
import { formatNumber } from "utils"

export const FuelingReport = () => {
  const { resume, refreshListInput, refreshListOutput, loadingList } = useFuelingMovementReport()
  const months = resume.months
  let sumMonth = 0
  return (
    <Grid minWidth={700} padding={1}>
      <Grid container>
        <Button variant="contained" size="small" onClick={refreshListInput}>
          ACTUALIZAR INGRESOS
        </Button>
        <Button variant="contained" size="small" onClick={refreshListOutput}>
          ACTUALIZAR SALIDAS
        </Button>
      </Grid>

      {loadingList && <LinearProgress />}
      <Grid container padding={1}>
        <Grid item flex={1}>
          <label style={{ fontWeight: "bold" }}>MES</label>
        </Grid>
        <Grid item flex={1}>
          <label style={{ fontWeight: "bold" }}>INGRESO</label>
        </Grid>
        <Grid item flex={1}>
          <label style={{ fontWeight: "bold" }}>SALIDA</label>
        </Grid>
        {/* <Grid item flex={1}>
          <label style={{ fontWeight: "bold" }}>DIFERENCIA</label>
        </Grid> */}
        <Grid item flex={1}>
          <label style={{ fontWeight: "bold" }}>STOCK</label>
        </Grid>
        <label style={{ width: 32 }}></label>
      </Grid>
      {months.map((month) => {
        sumMonth += resume.data[month].totalMonthInput - resume.data[month].totalMonthOutput
        let sumDate = 0
        return (
          <Accordion key={month} disableGutters variant="outlined">
            <AccordionSummary
              expandIcon={<GridExpandMoreIcon fontSize="small" />}
              aria-controls="panel1-content"
              id="panel1-header"
              style={{ margin: 0, minHeight: 24 }}
            >
              <div style={{ display: "flex", width: "100%" }}>
                <label style={{ flex: 1 }}>{month}</label>
                <label style={{ flex: 1 }}>
                  {formatNumber(resume.data[month].totalMonthInput)}
                </label>
                <label style={{ flex: 1 }}>
                  {formatNumber(resume.data[month].totalMonthOutput)}
                </label>
                {/* <label style={{ flex: 1 }}>
                  {formatNumber(
                    resume.data[month].totalMonthInput - resume.data[month].totalMonthOutput
                  )}
                </label> */}
                <label style={{ flex: 1 }}>{formatNumber(sumMonth)}</label>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <div style={{ display: "flex", alignItems: "flex-start" }}>
                <Grid container component={Paper} elevation={4}>
                  <Grid container padding={2}>
                    <label style={{ flex: 1, fontWeight: "bold", paddingRight: 4 }}>Fecha</label>
                    <label style={{ flex: 1, fontWeight: "bold", paddingRight: 4 }}>Ingreso</label>
                    <label style={{ flex: 1, fontWeight: "bold", paddingRight: 4 }}>Salida</label>
                    <label style={{ flex: 1, fontWeight: "bold", paddingRight: 4 }}>
                      Diferencia
                    </label>
                    <label style={{ flex: 1, fontWeight: "bold", paddingRight: 4 }}>
                      Acumulado
                    </label>
                  </Grid>
                  <Grid container>
                    {resume.data[month].dates.map((date) => {
                      sumDate +=
                        resume.data[month].resume[date].totalDateInput -
                        resume.data[month].resume[date].totalDateOutput
                      const Row = (
                        <Grid item container>
                          <label style={{ flex: 1 }}>
                            {new Date(Number(date)).getUTCFullYear()}-
                            {new Date(Number(date)).getUTCMonth() + 1}-
                            {new Date(Number(date)).getUTCDate()}
                          </label>
                          <label style={{ flex: 1, display: "flex" }}>
                            <div style={{ flex: 1 }}>
                              {formatNumber(resume.data[month].resume[date].totalDateInput)}
                            </div>
                            <div style={{ flex: 1 }}>
                              ({resume.data[month].resume[date].input.length})
                            </div>
                          </label>
                          <label style={{ flex: 1, display: "flex" }}>
                            <div style={{ flex: 1 }}>
                              {formatNumber(resume.data[month].resume[date].totalDateOutput)}
                            </div>
                            <div style={{ flex: 1 }}>
                              ({resume.data[month].resume[date].output.length})
                            </div>
                          </label>
                          <label style={{ flex: 1 }}>
                            {formatNumber(
                              resume.data[month].resume[date].totalDateInput -
                                resume.data[month].resume[date].totalDateOutput
                            )}
                          </label>
                          <label style={{ flex: 1 }}>{formatNumber(sumDate)}</label>
                        </Grid>
                      )
                      let accDateInput = 0
                      let accDateOutput = 0
                      return (
                        <Accordion style={{ width: "100%" }} disableGutters key={date}>
                          <AccordionSummary
                            style={{ minHeight: 24 }}
                            expandIcon={<GridExpandMoreIcon fontSize="small" />}
                          >
                            {Row}
                          </AccordionSummary>
                          <AccordionDetails>
                            <Grid container>
                              <Grid item flex={1}>
                                <Table size="small">
                                  <TableHead>
                                    <TableRow>
                                      <TableCell>Abastecimiento</TableCell>
                                      <TableCell>Tipo</TableCell>
                                      <TableCell>Ingreso</TableCell>
                                      <TableCell>Acumulado</TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {resume.data[month].resume[date].input.map((input) => {
                                      accDateInput += Number(input.quantity)
                                      return (
                                        <TableRow key={input.id}>
                                          <TableCell size="small">{input.fueling_id}</TableCell>
                                          <TableCell size="small">{input.type}</TableCell>
                                          <TableCell size="small">
                                            {formatNumber(input.quantity)}
                                          </TableCell>
                                          <TableCell size="small">
                                            {formatNumber(accDateInput)}
                                          </TableCell>
                                        </TableRow>
                                      )
                                    })}
                                    <TableRow style={{ borderStyle: "double" }}>
                                      <TableCell size="small" colSpan={2}>
                                        Total de ingresos del día
                                      </TableCell>
                                      <TableCell size="small" colSpan={2}>
                                        {formatNumber(
                                          resume.data[month].resume[date].totalDateInput
                                        )}
                                      </TableCell>
                                    </TableRow>
                                  </TableBody>
                                </Table>
                              </Grid>
                              <Grid item flex={1}>
                                <Table size="small">
                                  <TableHead>
                                    <TableRow>
                                      <TableCell>Abastecimiento</TableCell>
                                      <TableCell>Equipo</TableCell>
                                      <TableCell>Salida</TableCell>
                                      <TableCell>Acumulado</TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {resume.data[month].resume[date].output.map((output) => {
                                      accDateOutput += Number(output.cantidad)
                                      return (
                                        <TableRow key={output.id}>
                                          <TableCell>{output.fueling_id}</TableCell>
                                          <TableCell>{output.equipment?.plate}</TableCell>
                                          <TableCell>{output.cantidad}</TableCell>
                                          <TableCell>{accDateOutput}</TableCell>
                                        </TableRow>
                                      )
                                    })}
                                    <TableRow style={{ borderStyle: "double" }}>
                                      <TableCell colSpan={2}>Total de Salidas del día</TableCell>
                                      <TableCell colSpan={2}>
                                        {formatNumber(
                                          resume.data[month].resume[date].totalDateOutput
                                        )}
                                      </TableCell>
                                    </TableRow>
                                  </TableBody>
                                </Table>
                              </Grid>
                            </Grid>
                          </AccordionDetails>
                        </Accordion>
                      )
                    })}
                    <Grid container padding={2}>
                      <label style={{ flex: 1, fontWeight: "bold" }}>Total</label>
                      <label style={{ flex: 1, fontWeight: "bold" }}>
                        {formatNumber(resume.data[month].totalMonthInput)}
                      </label>
                      <label style={{ flex: 1, fontWeight: "bold" }}>
                        {formatNumber(resume.data[month].totalMonthOutput)}
                      </label>
                      <label style={{ flex: 1, fontWeight: "bold" }}>
                        {formatNumber(
                          resume.data[month].totalMonthInput - resume.data[month].totalMonthOutput
                        )}
                      </label>
                      <label style={{ flex: 1 }}></label>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </AccordionDetails>
          </Accordion>
        )
      })}
    </Grid>
  )
}
