import { Dispatch, SetStateAction } from "react"

import { Add, Check, Delete, Edit } from "@mui/icons-material"
import { IconButton, Tooltip } from "@mui/material"
import { GridColDef } from "@mui/x-data-grid"
import { DEFAULT_PROJECT } from "hooks/project"
import { IDailyPartByDate, IEquipment, ITimeline } from "model"

export const DEFAULT_TIMELINE: ITimeline = {
  part_daily_id: 0,
  movement_id: 0,
  movement: {
    id: 0,
    project: DEFAULT_PROJECT
  },
  work: "",
  id: 0,
  part_daily: {}
}
export interface IValorizationRecord {
  date_part: string
  start_hourmeter: string
  end_hourmeter: string
  tonnage: string
  tariff_price: string
}
export interface IValorizationRow {
  equipment: IEquipment
  data: IValorizationRecord[]
}
export interface IDailyPartCustom {
  id: number
  equipment: IEquipment
  daily?: Partial<IDailyPartByDate> | undefined
}
interface IColumnDaily {
  onSelectDefaultEquipment: (e: IEquipment | undefined) => void
  setDeleteDaily: Dispatch<SetStateAction<Partial<IDailyPartCustom> | undefined>>
  setEditDaily: Dispatch<SetStateAction<Partial<IDailyPartCustom> | undefined>>
}

export const columnDailyList: (params: IColumnDaily) => GridColDef<Partial<IDailyPartCustom>>[] = ({
  setDeleteDaily,
  onSelectDefaultEquipment,
  setEditDaily
}) => {
  return [
    {
      field: "family",
      headerName: "Familia",
      valueGetter: (value) => `${value.row.equipment?.family?.family_name ?? ""}`
    },
    {
      field: "code",
      headerName: "Equipo",
      valueGetter: (value) => `${value.row.equipment?.plate ?? value.row.equipment?.code}`
    },

    {
      field: "driver",
      headerName: "Conductor",
      valueGetter: (value) =>
        `${value.row.daily?.employee?.name ?? "--"} ${value.row.daily?.employee?.surname ?? "--"}`,
      flex: 2
    },

    {
      field: "work",
      headerName: "Trabajos",
      valueGetter: (value) =>
        value.row.daily?.timelines?.map((t) => t.movement?.project?.os ?? "").join(", ") ?? "--",
      flex: 1
    },
    {
      field: "observations",
      headerName: "Observaciones",
      valueGetter: (value) => value.row.daily?.observations ?? "--",
      flex: 2
    },
    {
      field: "start_houmeter",
      headerName: "H. Inicial",
      valueGetter: (value) => `${value.row.daily?.start_hourmeter ?? "--"}`
    },
    {
      field: "end_hourmeter",
      headerName: "H. Final",
      valueGetter: (value) => `${value.row.daily?.end_hourmeter ?? "--"}`
    },
    {
      field: "total_hours",
      headerName: "H. Total",
      valueGetter: (value) => `${value.row.daily?.total_hours ?? "--"}`
    },
    {
      field: "tonnage",
      headerName: "Tonelaje",
      valueGetter: (value) =>
        value.row.daily?.timelines?.reduce((acc, current) => Number(current.tonnage ?? 0) + acc, 0)
    },
    {
      field: "check",
      headerName: "PD",
      renderCell: (value) =>
        value.row.daily ? (
          <>
            <IconButton size="small">
              <Check color="info" fontSize="small" />
            </IconButton>
            <IconButton size="small" onClick={() => setEditDaily(value.row)}>
              <Edit color="warning" fontSize="small" />
            </IconButton>
            <Tooltip title={`Eliminar el registro`}>
              <IconButton size="small" onClick={() => setDeleteDaily(value.row)}>
                <Delete color="error" fontSize="small" />
              </IconButton>
            </Tooltip>
          </>
        ) : (
          <IconButton size="small" onClick={() => onSelectDefaultEquipment(value.row.equipment)}>
            <Add color="warning" fontSize="small" />
          </IconButton>
        )
    }
  ]
}
