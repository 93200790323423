export enum EnumEmployeeState {
  ACTIVE = "ACTIVE",
  DISABLED = "DISABLED"
}
export interface IEmployee {
  id: string
  name: string
  surname: string
  date_birthday: string
  dni: string
  license: string
  license_expire: string
  position: string
  salary: number
  email: string
  phone: string
  state: EnumEmployeeState
  created_at: string
}
