import { RouterProvider } from "react-router-dom"

import { ThemeProvider, createTheme } from "@mui/material/styles"

import { COLOR_PRIMARY, COLOR_SECONDARY } from "./constants/colors"
import { router } from "./routes/AppRoute"

import "./assets/app.css"

const defaultTheme = createTheme({
  palette: {
    primary: {
      main: COLOR_PRIMARY
    },
    secondary: {
      main: COLOR_SECONDARY
    }
  }
})
function App() {
  return (
    <ThemeProvider theme={defaultTheme}>
      <RouterProvider router={router} />
    </ThemeProvider>
  )
}

export default App
