import { useEffect, useState } from "react"

import { Box, Button, FormControlLabel, Grid, Switch, TextField } from "@mui/material"
import { DataGrid, GridToolbar } from "@mui/x-data-grid"
import FormDialog from "components/common/Dialog"
import { INITIAL_STATE_DATAGRID, PAGE_SIZE_OPTIONS, localizedTextsMap } from "constants/const"
import { useCrudFamily } from "hooks/equipment"
import { IFamily } from "model/equipment"
import { dateToString } from "utils"

import { columnFamily } from "./const"

export const FormFamily = ({
  changeValueFamily,
  family
}: {
  changeValueFamily: Function
  family: Partial<IFamily>
}) => {
  return (
    <Grid container>
      <Box width="100%" height={8} />
      <Grid container>
        <Grid item flex={1}>
          <TextField
            size="small"
            value={family.family_name ?? ""}
            onChange={(e) => changeValueFamily("family_name", e.target.value)}
            fullWidth
            disabled={!!family.deleted_at}
            autoFocus
            label="Nombre de familia"
            variant="outlined"
          />
          <FormControlLabel
            onChange={(e, checked) =>
              changeValueFamily("deleted_at", checked ? undefined : dateToString(new Date()))
            }
            control={<Switch checked={!family.deleted_at} />}
            label="ACTIVO"
          />
        </Grid>
      </Grid>
    </Grid>
  )
}

export const ListContainerFamily = () => {
  const [openForm, setOpenForm] = useState(false)
  const [familyEdit, setFamilyEdit] = useState<IFamily | undefined>(undefined)
  const [familyDelete, setFamilyDeleted] = useState<IFamily>()
  const {
    data,
    loadingCUD,
    family,
    loadingList,
    setFamily,
    changeValueFamily,
    updateOrCreateFamily,
    refreshFamilyList,
    handleDeleteFamily
  } = useCrudFamily()
  useEffect(() => {
    if (familyEdit) {
      setFamily(familyEdit)
    }
    setOpenForm(!!familyEdit)
  }, [familyEdit])
  useEffect(() => {
    if (!loadingCUD) {
      setOpenForm(false)
      setFamily({})
      setFamilyDeleted(undefined)
      setFamilyEdit(undefined)
      refreshFamilyList()
    }
  }, [loadingCUD])
  useEffect(() => {
    if (!openForm) {
      setFamilyEdit(undefined)
      setFamily({})
    }
  }, [openForm])
  return (
    <Grid container>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Button size="small" variant="contained" onClick={() => setOpenForm((prev) => !prev)}>
          Crear Familia
        </Button>
      </Box>
      <Grid item md={12}>
        <DataGrid
          slots={{ toolbar: GridToolbar }}
          density="compact"
          rows={data}
          columns={columnFamily({
            setDeleteFamily: setFamilyDeleted,
            setEditFamily: setFamilyEdit
          })}
          pagination
          autoHeight
          initialState={INITIAL_STATE_DATAGRID}
          disableRowSelectionOnClick
          pageSizeOptions={PAGE_SIZE_OPTIONS}
          localeText={localizedTextsMap}
          loading={loadingList.loading}
        />
      </Grid>
      <FormDialog
        open={openForm}
        visibleButtons={!loadingCUD}
        setOpen={setOpenForm}
        maxWidth="xs"
        handleConfirm={updateOrCreateFamily}
        title={familyEdit ? "Editar Familia " + familyEdit.family_name : `Nueva Familia`}
      >
        <FormFamily changeValueFamily={changeValueFamily} family={family} />
      </FormDialog>
      <FormDialog
        open={!!familyDelete}
        handleConfirm={() => handleDeleteFamily(Number(familyDelete?.id) ?? 0)}
        title="Eliminar Registro"
        setOpen={() => {
          setFamilyDeleted(undefined)
        }}
      >
        <label>Vamos a eliminar el registro: {familyDelete?.family_name}</label>
      </FormDialog>
    </Grid>
  )
}
