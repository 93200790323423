import { IEmployee } from "./employee"
import { IEquipment } from "./equipment"
import { IEquipmentProjectMovement } from "./equipment_project"
import { IProject } from "./project"
import { ISchedule } from "./shift"

export enum EnumShift {
  DAY = "DAY",
  NIGHT = "NIGHT"
}
export interface IDailyPart {
  id: number
  equipment_id: number
  employee_id: number
  schedule_id: number
  date_part: string
  shift: EnumShift
  start_hourmeter: number
  start_mileage: number
  end_hourmeter: number
  end_mileage: number
  total_hours?: number
  observations: string
  equipment?: Partial<IEquipment>
  schedule?: Partial<ISchedule>
  employee?: Partial<IEmployee>
  project?: Partial<IProject>
  timelines?: ITimeline[]
}

export interface ITimeline {
  id?: number
  part_daily_id: number
  movement_id: number
  tonnage?: number
  work: string
  start_hourmeter?: number
  end_hourmeter?: number
  part_daily?: Partial<IDailyPart>
  movement?: Partial<IEquipmentProjectMovement>
}
export interface IDailyPartByDate extends IDailyPart {
  timelines: ITimeline[]
  employee: IEmployee
  equipment: IEquipment
}
