import { useState } from "react"

import { API_SCHEDULE } from "../constants/routes"
import { IResponse, IResponseEntity, ValueOf } from "../model/common"
import { ISchedule } from "../model/shift"
import { useFetch } from "./common/petitions"

export const DEFAULT_SHIFT: Partial<ISchedule> = {
  name: "",
  start_day: "07:00",
  end_day: "19:00",
  start_night: "19:00",
  end_night: "07:00"
}

export const useScheduleList = () => {
  const { loadData, response } = useFetch<IResponse<ISchedule>>({
    method: "get"
  })
  const list = response.payload?.data ?? []
  return {
    scheduleList: [...list],
    loadingList: response.loading,
    errorList: response.errorPayload,
    refreshSchedule: () => loadData(API_SCHEDULE)
  }
}

export const useCrudSchedule = () => {
  const [schedule, setSchedule] = useState<Partial<ISchedule>>(DEFAULT_SHIFT)
  const { loadData: loadGetSchedule, response: responseGet } = useFetch<IResponse<ISchedule>>({
    method: "get"
  })
  const { loadData: loadCreateSchedule, response: responseCreate } = useFetch<
    IResponseEntity<ISchedule>
  >({
    method: "post"
  })
  const { loadData: loadUpdateSchedule, response: responseUpdate } = useFetch<
    IResponseEntity<ISchedule>
  >({
    method: "put"
  })
  const { loadData: loadDeleteSchedule, response: responseDelete } = useFetch<
    IResponseEntity<ISchedule>
  >({
    method: "delete"
  })

  const updateOrCreateSchedule = () => {
    console.log("Schedule to save", schedule)
    if (
      !schedule ||
      !schedule.name ||
      !schedule.start_day ||
      !schedule.start_night ||
      !schedule.end_day ||
      !schedule.end_night
    )
      return
    if (Number(schedule.id) > 0) {
      if (!!schedule.deleted_at) {
        handleDeleteSchedule(Number(schedule.id))
      } else {
        restore()
      }
      loadUpdateSchedule(`${API_SCHEDULE}/${schedule.id}`, JSON.stringify(schedule))
    } else {
      loadCreateSchedule(API_SCHEDULE, JSON.stringify(schedule))
    }
  }
  const restore = () => {
    loadUpdateSchedule(`${API_SCHEDULE}/${schedule.id}/restore`, JSON.stringify(schedule))
  }
  const handleDeleteSchedule = (id: number) => {
    loadDeleteSchedule(`${API_SCHEDULE}/${id}`)
  }
  const handleDefaultSchedule = (id: number) => {
    loadUpdateSchedule(`${API_SCHEDULE}/${id}`, JSON.stringify({ id, default: true }))
  }
  const changeValueSchedule = (key: keyof ISchedule, value: ValueOf<ISchedule>) => {
    setSchedule((prev) => ({
      ...prev,
      [key]: value
    }))
  }

  return {
    data: responseGet.payload?.data ?? [],
    loadingCUD: responseCreate.loading || responseUpdate.loading || responseDelete.loading,
    schedule,
    loadingList: responseGet.loading,

    refreshScheduleList: () => loadGetSchedule(API_SCHEDULE),
    changeValueSchedule,
    handleDeleteSchedule,
    handleDefaultSchedule,
    updateOrCreateSchedule,
    setSchedule
  }
}
