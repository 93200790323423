import * as React from "react"

import { CircularProgress, DialogContentText } from "@mui/material"
import Button from "@mui/material/Button"
import Dialog, { DialogProps } from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"

interface IFormDialog extends DialogProps {
  open: boolean
  loading?: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  title: string
  description?: string
  children: React.ReactElement
  visibleButtons?: boolean
  disabledButtons?: boolean
  handleConfirm: () => void
}
export default function FormDialog({
  open,
  loading,
  title,
  description,
  children,
  visibleButtons = true,
  disabledButtons = false,
  maxWidth,
  setOpen,
  handleConfirm
}: IFormDialog) {
  const handleClose = () => {
    setOpen(false)
  }
  return (
    <Dialog fullWidth maxWidth={maxWidth} open={open} onClose={handleClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {description && <DialogContentText>{description}</DialogContentText>}
        {children}
      </DialogContent>
      {visibleButtons && (
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button variant="contained" disabled={disabledButtons || loading} onClick={handleConfirm}>
            {loading ? <CircularProgress /> : "Confirmar"}
          </Button>
        </DialogActions>
      )}
    </Dialog>
  )
}
