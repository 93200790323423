import { useEffect, useMemo } from "react"

import { API_HOME, COLOR_PRIMARY, COLOR_SECONDARY } from "constants/index"
import { useFetch } from "hooks/common/petitions"
import { IResponseEntity } from "model"

import { ChartData, HomeReport } from "../types"

export const useHome = () => {
  const request = useFetch<IResponseEntity<HomeReport>>({
    method: "get"
  })

  useEffect(() => {
    request.loadData(API_HOME)
  }, [])

  const response = request.response.payload?.data || {
    currentFamilies: [],
    accumulatedFamilies: [],
    currentPercentageUseFamilies: [],
    accumulatedPercentageUseFamilies: [],
    currentRateHourmeter: [],
    accumulatedRateHourmeter: [],
    currentRateMileage: [],
    accumulatedRateMileage: [],
    avgFuelingInput: [],
    stockFueling: {
      dateStock: "",
      sumInputs: 0,
      sumOutputs: 0,
      stock: 0
    }
  }

  const charts = useMemo(() => {
    const currentMonthData: ChartData = {
      series: [
        {
          name: "Mes actual",
          valueYField: "currentMonth",
          categoryXField: "category",
          labelText: "[bold]{categoryX}[/]:{valueY} hrs"
        }
      ],
      data: response.currentFamilies.map((c) => ({
        category: c.familyName,
        currentMonth: parseFloat(c.totalHours)
      })),
      title: "Horas trabajadas en el mes actual"
    }
    const accumulatedData: ChartData = {
      series: [
        {
          name: "Acumulado",
          valueYField: "accumulated",
          categoryXField: "category",
          labelText: "[bold]{categoryX}[/]:{valueY} hrs"
        }
      ],
      data: response.accumulatedFamilies.map((c) => ({
        category: c.familyName,
        accumulated: parseFloat(c.totalHours)
      })),

      title: "Horas acumuladas por familia"
    }

    const percentageUseCurrentData: ChartData = {
      series: [
        {
          name: "MA: Trabajó",
          valueYField: "percentageWorker",
          categoryXField: "category",
          color: COLOR_PRIMARY,
          fontColor: COLOR_SECONDARY
        },
        {
          name: "MA: No trabajó",
          valueYField: "percentageNoWorker",
          categoryXField: "category",
          color: COLOR_SECONDARY,
          fontColor: COLOR_PRIMARY
        }
      ],
      data: response.currentPercentageUseFamilies.map((c) => ({
        category: c.familyName,
        percentageWorker: c.percentageWorker,
        percentageNoWorker: 100 - c.percentageWorker
      })),
      title: "Porcentaje de uso de familias en el mes actual"
    }

    const percentageUseAccumulatedData: ChartData = {
      series: [
        {
          name: "Acc: Trabajó",
          valueYField: "percentageWorker",
          categoryXField: "category",
          color: COLOR_PRIMARY,
          fontColor: COLOR_SECONDARY
        },
        {
          name: "Acc: No trabajó",
          valueYField: "percentageNoWorker",
          categoryXField: "category",
          color: COLOR_SECONDARY,
          fontColor: COLOR_PRIMARY
        }
      ],
      data: response.accumulatedPercentageUseFamilies.map((c) => ({
        category: c.familyName,
        percentageWorker: c.percentageWorker,
        percentageNoWorker: 100 - c.percentageWorker
      })),
      title: "Porcentaje acumulado de uso de familias"
    }
    const rateHourmeterData: ChartData = {
      series: [
        {
          name: "Mes Actual Gls/hora",
          valueYField: "Acumulada",
          categoryXField: "category",
          labelText: "[bold]{valueYField}[/]:{valueY} gls/h",
          color: COLOR_PRIMARY
        },
        {
          name: "Acumulado Gls/hora",
          valueYField: "Mes Actual",
          labelText: "[bold]{valueYField}[/]:{valueY} gls/h",
          categoryXField: "category",
          color: COLOR_SECONDARY
        }
      ],
      data: response.currentRateHourmeter.map((c) => {
        const accumulated = response.accumulatedRateHourmeter.find((a) => a.familyId === c.familyId)
        return {
          category: c.familyName,
          "Mes Actual": Number(c.rateHourmeter).toFixed(2),
          Acumulada: Number(parseFloat(accumulated?.rateHourmeter || "0").toFixed(2))
        }
      }),
      title: "Tasa de consumo de combustible por familia"
    }
    const rateMileageData: ChartData = {
      series: [
        {
          name: "Mes Actual Km/Gln",
          valueYField: "Acumulada",
          categoryXField: "category",
          labelText: "[bold]{valueYField}[/]:{valueY}"
        },
        {
          name: "Accumulado Km/Gln",
          valueYField: "Mes Actual",
          categoryXField: "category",
          labelText: "[bold]{valueYField}[/]:{valueY}"
        }
      ],
      data: response.currentRateMileage.map((c) => {
        const accumulated = response.accumulatedRateMileage.find(
          (a) => a.equipmentId === c.equipmentId
        )
        return {
          category: c.plate,
          "Mes Actual": Number(c.rateMileage).toFixed(2),
          Acumulada: Number(parseFloat(accumulated?.rateMileage || "0").toFixed(2))
        }
      }),
      title: "Tasa de consumo de combustible por kilometraje"
    }
    const avgFuelingInputData: ChartData = {
      series: [
        {
          name: "Promedio días entre recargas",
          valueYField: "avgDaysBetweenRefills",
          categoryXField: "category"
        }
      ],
      data: response.avgFuelingInput.map((c) => ({
        category: c.monthYear,
        avgDaysBetweenRefills: Number(parseFloat(c.avgDaysBetweenRefills).toFixed(2))
      })),
      title: "Promedio de días entre recargas"
    }
    return {
      stockFueling: response.stockFueling,
      currentMonthData,
      accumulatedData,
      percentageUseCurrentData,
      percentageUseAccumulatedData,
      rateHourmeterData,
      rateMileageData,
      avgFuelingInputData
    }
  }, [response])
  return {
    loading: !!request.response.loading,
    ...charts
  }
}
