import { Navigate, createBrowserRouter } from "react-router-dom"

import {
  ContainerDailyPartList,
  ContainerEmployeeList,
  ContainerEquipmentList,
  ContainerFuelingList,
  HomeCharts,
  SettingsContainer
} from "features"

import ContainerSign from "features/auth/Login"

import { Layout } from "components/layout/Layout"

import { useAuth } from "hooks/useAuth"

import {
  PATH_EMPLOYEE,
  PATH_EQUIPMENT,
  PATH_FUELING,
  PATH_HOME,
  PATH_LOGIN,
  PATH_RECORD_DP,
  PATH_SETTINGS
} from "constants/routes"

export const PrivateRouter = ({ children }: { children: JSX.Element }) => {
  const { isAuthenticated } = useAuth()
  if (isAuthenticated) {
    return children
  }

  return <Navigate replace to="/login" />
}

export const PublicRoute = ({ children }: { children: JSX.Element }) => {
  return children
}

export const router = createBrowserRouter([
  {
    path: "/",
    children: [
      {
        path: PATH_LOGIN,
        element: (
          <PublicRoute>
            <ContainerSign />
          </PublicRoute>
        )
      },
      {
        path: PATH_HOME,
        element: (
          <PrivateRouter>
            <Layout title="Home - Urteaga">
              <HomeCharts />
            </Layout>
          </PrivateRouter>
        )
      },
      {
        path: PATH_RECORD_DP,
        element: (
          <PrivateRouter>
            <Layout title="Registros - Urteaga">
              <ContainerDailyPartList />
            </Layout>
          </PrivateRouter>
        )
      },
      {
        path: PATH_EQUIPMENT,
        element: (
          <PrivateRouter>
            <Layout title="Equipos - Urteaga">
              <ContainerEquipmentList />
            </Layout>
          </PrivateRouter>
        )
      },
      {
        path: PATH_EMPLOYEE,
        element: (
          <PrivateRouter>
            <Layout title="Colaboradores - Urteaga">
              <ContainerEmployeeList />
            </Layout>
          </PrivateRouter>
        )
      },
      {
        path: PATH_FUELING,
        element: (
          <PrivateRouter>
            <Layout title="Abastecimiento - Urteaga">
              <ContainerFuelingList />
            </Layout>
          </PrivateRouter>
        )
      },
      {
        path: PATH_SETTINGS,
        element: (
          <PrivateRouter>
            <Layout title="Configuraciones - Urteaga">
              <SettingsContainer />
            </Layout>
          </PrivateRouter>
        )
      }
    ]
  }
])
