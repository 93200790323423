export const useTimeLineStyles: any = {
  container: {
    display: "flex",
    background: "inherit",
    justifyContent: "center",
    flex: 1,
    flexWrap: "wrap",
    alignItems: "flex-end"
  },
  buttonWrapper: {
    display: "flex",
    alignItems: "flexEnd",
    background: "inherit"
  },
  button: {
    border: "none",
    textDecoration: "none",
    cursor: "pointer",
    borderRadius: "50%",
    width: "40px",
    height: "40px",
    color: "white",
    fontSize: "20px",
    fontWeight: "bold",
    flexShrink: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 0,
    marginBottom: "13px"
  },
  dateListScrollable: {
    display: "flex",
    overflowX: "auto",
    scrollbarWidth: "none",
    // margin: "2px 0 2px 0px",
    WebkitOverflowScrolling: "touch",
    justifyContent: "center"
    // flexWrap: "wrap"
  },
  monthContainer: {
    display: "flex",
    flexDirection: "column",
    cursor: "pointer",
    padding: " 2px",
    margin: "2px"
  },
  monthYearLabel: {
    alignSelf: "flex-start",
    zIndex: 3,
    fontSize: "15px",
    fontWeight: "bold",
    position: "sticky",
    top: " 10px",
    left: "0",
    margin: "0 10px"
  },
  dateDayItem: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    cursor: "pointer",
    margin: 0,
    width: "28px",
    // height: " 49px",
    flexShrink: 0
  },
  dateDayItemMarked: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    cursor: "pointer",
    margin: " 0 0 0 10px",
    width: " 32px",
    // height: "100%",
    flexShrink: 0
  },
  daysContainer: {
    display: "flex",
    zIndex: 3,
    marginTop: "10px"
    // flexWrap: "wrap"
  },
  dayLabel: {
    fontSize: "12px",
    margin: "4px 0 0 0"
  },

  dateLabel: {
    fontSize: "18px"
  },

  markedLabel: {
    marginTop: "10px"
  }
}
