import { useEffect, useState } from "react"

import { API_FUELING, API_FUELING_INPUT, API_FUELING_OUTPUT } from "../constants/routes"
import { IResponse, IResponseEntity, ValueOf } from "../model/common"
import { EnumTypeFueling, IFueling } from "../model/fueling"
import { IFuelingInput, IFuelingOutput } from "../model/fueling_input"
import { useFetch } from "./common/petitions"

export const DEFAULT_ENTITY: Partial<IFueling> = {
  type: EnumTypeFueling.FUEL_TANKER,
  id: 0
}

export const useFuelingList = () => {
  const { loadData, response } = useFetch<IResponse<IFueling>>({
    method: "get"
  })
  const list = response.payload?.data ?? []
  return {
    fuelingList: [...list],
    loadingList: response.loading,
    errorList: response.errorPayload,
    refreshFuelingList: () => loadData(API_FUELING)
  }
}

export const useFuelingCrud = () => {
  const [fueling, setFueling] = useState<Partial<IFueling>>(DEFAULT_ENTITY)
  const { loadData: loadGetFueling, response: responseGet } = useFetch<IResponse<IFueling>>({
    method: "get"
  })
  const { loadData: loadCreateFueling, response: responseCreate } = useFetch<
    IResponseEntity<IFueling>
  >({
    method: "post"
  })
  const { loadData: loadUpdateFueling, response: responseUpdate } = useFetch<
    IResponseEntity<IFueling>
  >({
    method: "put"
  })
  const { loadData: loadDeleteFueling, response: responseDelete } = useFetch<
    IResponseEntity<IFueling>
  >({
    method: "delete"
  })
  useEffect(() => {
    if (fueling.type === EnumTypeFueling.FUEL_TANKER)
      setFueling((prev) => ({ ...prev, supplier: undefined }))
    if (fueling.type === EnumTypeFueling.SERVICE_STATION)
      setFueling((prev) => ({ ...prev, equipment: undefined }))
  }, [fueling.type])

  const updateOrCreateFueling = () => {
    console.log("Fueling to save", fueling)
    const resource_id = fueling.supplier?.id ?? fueling.equipment?.id ?? undefined
    if (!resource_id) return
    fueling.resource_id = Number(resource_id)
    if (!fueling || !fueling.type || !fueling.resource_id) return
    if (Number(fueling.id) > 0) {
      loadUpdateFueling(`${API_FUELING}/${fueling.id}`, JSON.stringify(fueling))
    } else {
      loadCreateFueling(API_FUELING, JSON.stringify(fueling))
    }
  }
  const changeValueFueling = (key: keyof IFueling, value: ValueOf<IFueling>) => {
    setFueling((prev) => ({
      ...prev,
      [key]: value
    }))
  }
  const handleDeleteFueling = (id: number) => {
    loadDeleteFueling(`${API_FUELING}/${id}`)
  }
  return {
    data: responseGet.payload?.data ?? [],
    loadingCUD: responseCreate.loading || responseUpdate.loading || responseDelete.loading,
    fueling,
    loadingList: responseGet.loading,

    refreshFuelingList: () => loadGetFueling(API_FUELING),
    changeValueFueling,
    updateOrCreateFueling,
    setFueling,
    handleDeleteFueling
  }
}
export const useFuelingMovement = ({ fueling }: { fueling?: IFueling }) => {
  const [fuelingDB, setFuelingDB] = useState<Partial<IFueling>>({})
  const date = new Date()
  const month = `00${date.getMonth() + 1}`.split("").slice(-2).join("")
  const day = `00${date.getDate()}`.split("").slice(-2).join("")
  const [fuelingInput, setFuelingInput] = useState<Partial<IFuelingInput>>({
    ...fueling,
    created_at: `${date.getFullYear()}-${month}-${day}`
  })
  const { loadData: loadGetFueling, response: responseGetFueling } = useFetch<
    IResponseEntity<IFueling>
  >({
    method: "get"
  })
  const { loadData: loadGetFuelingInput, response: responseGetInput } = useFetch<
    IResponse<IFuelingInput>
  >({
    method: "get"
  })
  const { loadData: loadGetFuelingOutput, response: responseGetOutput } = useFetch<
    IResponse<IFuelingOutput>
  >({
    method: "get"
  })
  const { loadData: loadCreateFuelingInput, response: responseCreateFuelingInput } = useFetch<
    IResponseEntity<IFuelingInput>
  >({
    method: "post"
  })
  const { loadData: loadDeleteFuelingInput, response: responseFuelingInput } = useFetch<
    IResponseEntity<IFuelingInput>
  >({
    method: "delete"
  })
  const createFuelingInput = () => {
    fuelingInput.id = 0
    if (!fuelingInput || !fueling) return
    fuelingInput.fueling_id = fueling.id
    console.log("Fueling to save", fuelingInput)

    if (Number(fuelingInput?.quantity) <= 0 || !fuelingInput.fueling_id || !fuelingInput.created_at)
      return
    if (Number(fuelingInput.id) > 0) {
    } else {
      loadCreateFuelingInput(
        API_FUELING_INPUT,
        JSON.stringify({
          ...fuelingInput,
          created_at: `${fuelingInput.created_at}T05:00:00`
        })
      )
    }
  }
  const changeValueFuelingInput = (key: keyof IFuelingInput, value: ValueOf<IFuelingInput>) => {
    setFuelingInput((prev) => ({
      ...prev,
      [key]: value
    }))
  }
  useEffect(() => {
    if (responseGetFueling.payload) {
      setFuelingDB(responseGetFueling.payload.data)
    } else {
      setFuelingDB({})
    }
  }, [responseGetFueling])
  const refreshListInput = () =>
    loadGetFuelingInput(`${API_FUELING_INPUT}?fueling_id=${fueling?.id}`)
  const refreshListOutput = () =>
    loadGetFuelingOutput(`${API_FUELING_OUTPUT}?fueling_id=${fueling?.id}`)
  const refreshFuelingDB = () => loadGetFueling(`${API_FUELING}/${fueling?.id}`)

  const handleDeleteInput = (id: number) => {
    loadDeleteFuelingInput(`${API_FUELING_INPUT}/${id}`)
  }
  return {
    fueling: fuelingDB,
    inputList: responseGetInput.payload?.data ?? [],
    outputList: responseGetOutput.payload?.data ?? [],
    loadingCreate: !!responseCreateFuelingInput.loading,
    loadingList: !!responseGetInput.loading || !!responseGetOutput.loading,
    loadingFuelingDB: responseGetFueling.loading,
    loadingDelete: !!responseFuelingInput.loading,
    fuelingInput,
    createFuelingInput,
    refreshListInput,
    refreshListOutput,
    refreshFuelingDB,
    changeValueFuelingInput,
    handleDeleteInput
  }
}
type IResumeDate = Record<
  string,
  {
    input: IFuelingInput[]
    totalDateInput: number
    output: IFuelingOutput[]
    totalDateOutput: number
  }
>
type IResumeMonth = Record<
  string,
  {
    resume: IResumeDate
    totalMonthInput: number
    totalMonthOutput: number
    dates: string[]
  }
>
type IResume = {
  totalInput: number
  totalOutput: number
  diff: number
  data: IResumeMonth
  months: string[]
}
export const useFuelingMovementReport = () => {
  const { loadData: loadGetFuelingInput, response: responseGetInput } = useFetch<
    IResponse<IFuelingInput>
  >({
    method: "get"
  })
  const { loadData: loadGetFuelingOutput, response: responseGetOutput } = useFetch<
    IResponse<IFuelingOutput>
  >({
    method: "get"
  })
  const inputList = responseGetInput.payload?.data ?? []
  const outputList = responseGetOutput.payload?.data ?? []

  useEffect(() => {
    refreshListInput()
    refreshListOutput()
  }, [])
  const refreshListInput = () => loadGetFuelingInput(`${API_FUELING_INPUT}`)
  const refreshListOutput = () => loadGetFuelingOutput(`${API_FUELING_OUTPUT}`)

  const createObjectDateFromList = () => {
    const objectDate: IResume = {
      data: {},
      totalInput: 0,
      totalOutput: 0,
      diff: 0,
      months: []
    }
    inputList.forEach((input) => {
      const date = new Date(`${input.created_at}`)
      const monthYear = `${date.getFullYear()}-${date.getMonth() + 1}`
      const dateLocal = date.getTime().toString()
      if (objectDate.data[monthYear]) {
        if (objectDate.data[monthYear].resume[dateLocal]) {
          objectDate.data[monthYear].resume[dateLocal].input.push(input)
        } else {
          objectDate.data[monthYear].resume[dateLocal] = {
            input: [input],
            totalDateInput: 0,
            totalDateOutput: 0,
            output: []
          }
          objectDate.data[monthYear].dates.push(dateLocal)
        }
      } else {
        objectDate.data[monthYear] = {
          dates: [dateLocal],
          resume: {
            [dateLocal]: {
              input: [input],
              totalDateInput: 0,
              totalDateOutput: 0,
              output: []
            }
          },
          totalMonthInput: 0,
          totalMonthOutput: 0
        }
        objectDate.months.push(monthYear)
      }
      objectDate.data[monthYear].totalMonthInput += Number(input.quantity)
      objectDate.data[monthYear].resume[dateLocal].totalDateInput += Number(input.quantity)
      objectDate.totalInput += Number(input.quantity)
    })
    outputList.forEach((output) => {
      const date = new Date(`${output.fecha}T00:00:00.000000Z`)
      const monthYear = `${date.getFullYear()}-${date.getMonth() + 1}`
      const dateLocal = date.getTime().toString()
      if (objectDate.data[monthYear]) {
        if (objectDate.data[monthYear].resume[dateLocal]) {
          objectDate.data[monthYear].resume[dateLocal].output.push(output)
        } else {
          objectDate.data[monthYear].resume[dateLocal] = {
            input: [],
            output: [output],
            totalDateInput: 0,
            totalDateOutput: 0
          }
          if (!objectDate.data[monthYear].dates.some((m) => m === dateLocal)) {
            objectDate.data[monthYear].dates.push(dateLocal)
          }
        }
      } else {
        objectDate.data[monthYear] = {
          dates: [dateLocal],
          resume: {
            [dateLocal]: {
              input: [],
              totalDateInput: 0,
              totalDateOutput: 0,
              output: [output]
            }
          },
          totalMonthInput: 0,
          totalMonthOutput: 0
        }
        if (!objectDate.months.some((m) => m === monthYear)) {
          objectDate.months.push(monthYear)
        }
      }
      objectDate.data[monthYear].totalMonthOutput += Number(output.cantidad)
      objectDate.data[monthYear].resume[dateLocal].totalDateOutput += Number(output.cantidad)
      objectDate.totalOutput += Number(output.cantidad)
    })
    //short dates
    objectDate.months.forEach((month) => {
      objectDate.data[month].dates = objectDate.data[month].dates.sort((a, b) => {
        const dateA = new Date(Number(a))
        const dateB = new Date(Number(b))
        return dateA.getTime() - dateB.getTime()
      })
    })

    return objectDate
  }

  return {
    inputList,
    outputList,
    loadingList: !!responseGetInput.loading || !!responseGetOutput.loading,
    refreshListInput,
    refreshListOutput,
    resume: createObjectDateFromList()
  }
}
