import { useEffect, useState } from "react"

import { Splitscreen } from "@mui/icons-material"
import { Box, Button, Grid, Paper, TextField } from "@mui/material"
import { DataGrid, GridToolbar } from "@mui/x-data-grid"
import { CardTitle } from "components/common/CardTitle"
import FormDialog from "components/common/Dialog"
import { INITIAL_STATE_DATAGRID, PAGE_SIZE_OPTIONS, localizedTextsMap } from "constants/const"
import { DEFAULT_EMPLOYEE_CREATE, useCrudEmployee } from "hooks/employee"
import { ValueOf } from "model/common"
import { IEmployee } from "model/employee"

import { columnEmployeeList } from "./const"

export const EmployeeListTable = () => {
  const [editEmployee, setEditEmployee] = useState<Partial<IEmployee> | undefined>(undefined)
  const [deletedEmployee, setDeletedEmployee] = useState<Partial<IEmployee> | undefined>(undefined)
  const {
    data,
    changeValueEmployee,
    employee,
    updateOrCreateEmployee,
    loadingCUD,
    setRefreshEmployee,
    setEmployee,
    handleDeleteEmployee,
    loadingList
  } = useCrudEmployee()
  const [openForm, setOpenForm] = useState(false)
  useEffect(() => {
    if (editEmployee) {
      setEmployee(editEmployee)
    }
    setOpenForm(!!editEmployee)
  }, [editEmployee])
  useEffect(() => {
    if (!loadingCUD) {
      setEmployee(DEFAULT_EMPLOYEE_CREATE)
      setEditEmployee(undefined)
      setDeletedEmployee(undefined)
      setRefreshEmployee((prev) => !prev)
      setOpenForm(false)
    }
  }, [loadingCUD])
  useEffect(() => {
    if (!openForm) {
      setEmployee(DEFAULT_EMPLOYEE_CREATE)
      setEditEmployee(undefined)
    }
  }, [openForm])

  return (
    <Grid container md={12} lg={12} paddingTop={1} component={Paper}>
      <Box padding={2} display="flex" alignItems="center" justifyContent="space-between">
        <Button size="small" variant="contained" onClick={() => setOpenForm((prev) => !prev)}>
          Crear colaborador
        </Button>
      </Box>
      <Grid container md={12} padding={2}>
        <DataGrid
          slots={{ toolbar: GridToolbar }}
          density="compact"
          rows={data.filter((d) => d.id !== "0")}
          columns={columnEmployeeList({
            setDeleteEmployee: setDeletedEmployee,
            setEditEmployee: setEditEmployee
          })}
          loading={loadingList.loading}
          pagination
          autoHeight
          initialState={INITIAL_STATE_DATAGRID}
          disableRowSelectionOnClick
          pageSizeOptions={PAGE_SIZE_OPTIONS}
          localeText={localizedTextsMap}
        />
      </Grid>
      <FormDialog
        open={openForm}
        setOpen={setOpenForm}
        maxWidth="md"
        disabledButtons={loadingCUD}
        handleConfirm={updateOrCreateEmployee}
        title={`Nuevo colaborador`}
      >
        <FormEmployee changeValueEmployee={changeValueEmployee} employee={employee} />
      </FormDialog>
      <FormDialog
        open={!!deletedEmployee}
        handleConfirm={() => handleDeleteEmployee(Number(deletedEmployee?.id) ?? 0)}
        title="Eliminar Registro"
        setOpen={() => {
          setDeletedEmployee(undefined)
        }}
      >
        <label>
          Vamos a eliminar el registro: {deletedEmployee?.dni}{" "}
          {deletedEmployee?.name?.toUpperCase()} {deletedEmployee?.surname?.toUpperCase()}
        </label>
      </FormDialog>
    </Grid>
  )
}
export const FormEmployee = ({
  changeValueEmployee,
  employee
}: {
  changeValueEmployee: (key: keyof IEmployee, value: ValueOf<IEmployee>) => void
  employee: Partial<IEmployee>
}) => {
  return (
    <Grid container>
      <Box width="100%" height={8} />
      <Grid container>
        <Grid item flex={1}>
          <TextField
            value={employee.dni ?? ""}
            onChange={(e) => changeValueEmployee("dni", e.target.value)}
            size="small"
            autoFocus
            inputProps={{
              maxLength: 11
            }}
            fullWidth
            label="DNI"
            variant="outlined"
          />
        </Grid>
        <Grid item flex={1}>
          <TextField
            size="small"
            value={employee.name ?? ""}
            onChange={(e) => changeValueEmployee("name", e.target.value)}
            fullWidth
            label="Nombres"
            variant="outlined"
          />
        </Grid>
        <Grid item flex={1}>
          <TextField
            size="small"
            fullWidth
            label="Apellidos"
            variant="outlined"
            value={employee.surname ?? ""}
            onChange={(e) => changeValueEmployee("surname", e.target.value)}
          />
        </Grid>
      </Grid>
      <Box width="100%" height={8} />

      <Grid container>
        <Grid item flex={1}>
          <TextField
            size="small"
            value={employee.position ?? ""}
            onChange={(e) => changeValueEmployee("position", e.target.value)}
            fullWidth
            label="Cargo"
            variant="outlined"
          />
        </Grid>

        <Grid item flex={1}>
          <TextField
            size="small"
            fullWidth
            label="Licencia"
            variant="outlined"
            value={employee.license ?? ""}
            onChange={(e) => changeValueEmployee("license", e.target.value)}
          />
        </Grid>
        <Grid item flex={1}>
          <TextField
            size="small"
            value={employee.license_expire ?? ""}
            onChange={(e) => changeValueEmployee("license_expire", e.target.value)}
            fullWidth
            type="date"
            label="Fec. Venc. Lic."
            variant="outlined"
          />
        </Grid>
      </Grid>
      <Box width="100%" height={8} />

      <Grid container>
        <Grid item flex={0.5}>
          <TextField
            size="small"
            value={employee.salary ?? ""}
            onChange={(e) => changeValueEmployee("salary", e.target.value)}
            fullWidth
            label="Salario"
            variant="outlined"
          />
        </Grid>
        <Grid item flex={1}>
          <TextField
            size="small"
            value={employee.phone ?? ""}
            onChange={(e) => changeValueEmployee("phone", e.target.value)}
            fullWidth
            label="Teléfono"
            variant="outlined"
          />
        </Grid>
        <Grid item flex={1}>
          <TextField
            value={employee.email ?? ""}
            onChange={(e) => changeValueEmployee("email", e.target.value)}
            type="email"
            size="small"
            fullWidth
            label="Email"
            variant="outlined"
          />
        </Grid>
        <Grid item flex={1}>
          <TextField
            size="small"
            value={employee.date_birthday ?? ""}
            onChange={(e) => changeValueEmployee("date_birthday", e.target.value)}
            fullWidth
            type="date"
            label="Fec. Nac."
            variant="outlined"
          />
        </Grid>
      </Grid>
      <Box width="100%" height={8} />
    </Grid>
  )
}

export const ContainerEmployeeList = () => {
  return (
    <Grid container gap={1} flexDirection={"column"} alignItems={"end"}>
      <Grid container justifyContent={"space-around"}>
        <CardTitle
          title="Colaboradores"
          subtitle="Lista de usuarios"
          icon={<Splitscreen fontSize="large" color="secondary" />}
        />
      </Grid>
      <Grid container>
        <EmployeeListTable />
      </Grid>
    </Grid>
  )
}
