import { useEffect, useMemo, useState } from "react"

import { API_DAILY_BY_DATE, API_DAILY_PART, API_EXPORT } from "constants/routes"
import { IDailyPartCustom, IValorizationRow } from "features/daily-parts/const"
import { IProject } from "model"
import { IResponse, IResponseDailyByDate, IResponseEntity, ValueOf } from "model/common"
import { EnumShift, IDailyPart, IDailyPartByDate, ITimeline } from "model/daily-part"
import { IEquipment, IFamily } from "model/equipment"

import { downloadFile, useFetch } from "./common/petitions"
import { DEFAULT_EMPLOYEE } from "./employee"
import { DEFAULT_EQUIPMENT, DEFAULT_EQUIPMENT_FAMILY } from "./equipment"
import { DEFAULT_PROJECT } from "./project"

const date_current = new Date()
export const DEFAULT_DAILY_PART: Partial<IDailyPart> = {
  date_part: `${date_current.getFullYear()}-${`0${date_current.getMonth() + 1}`.slice(
    -2
  )}-${`0${date_current.getDate()}`.slice(-2)}`,
  shift: EnumShift.DAY,
  observations: " ",
  employee: DEFAULT_EMPLOYEE,
  equipment: DEFAULT_EQUIPMENT
}
export const useCrudDailyPart = () => {
  const [dailyPart, setDailyPart] = useState<Partial<IDailyPart>>(DEFAULT_DAILY_PART)
  const {
    loadData: loadCreateDailyPart,
    response: responseCreate,
    resetData
  } = useFetch<IResponseEntity<IDailyPart>>({
    method: "post"
  })
  const {
    loadData: loadUpdateDailyPart,
    response: responseUpdate,
    resetData: resetDataUpdate
  } = useFetch<IResponseEntity<IDailyPart>>({
    method: "put"
  })
  const { loadData: loadDeleteDailyPart, response: responseDelete } = useFetch<
    IResponseEntity<IDailyPart>
  >({
    method: "delete"
  })
  const date = new Date(dailyPart.date_part ?? "")

  const isNotValidDailyForm =
    !dailyPart ||
    !dailyPart.employee_id ||
    !dailyPart.equipment_id ||
    isNaN(date.getTime()) ||
    !dailyPart.end_hourmeter ||
    !dailyPart.start_hourmeter ||
    Number(dailyPart.end_hourmeter) <= Number(dailyPart.start_hourmeter)

  const updateOrCreateDailyPart = (timelines: ITimeline[], isEditWorks?: boolean) => {
    let msm = ""
    if (isNotValidDailyForm) {
      msm += Number(dailyPart.equipment_id) > 0 ? "" : " equipo,"
      msm += Number(dailyPart.employee_id) > 0 ? "" : " empleado,"
      msm += dailyPart.start_hourmeter ? "" : " horometro inicial,"
      msm += dailyPart.end_hourmeter ? "" : " horometro final,"
      msm +=
        Number(dailyPart.end_hourmeter) <= Number(dailyPart.start_hourmeter)
          ? "hometro final debe ser mayor que inicial"
          : ""

      alert("Error en " + msm)
      return
    }
    dailyPart.start_mileage = dailyPart.equipment?.mileage ?? 0
    dailyPart.observations = dailyPart.observations ?? " "
    dailyPart.equipment = undefined
    dailyPart.employee = undefined
    dailyPart.timelines = timelines.map((t) => ({
      ...t,
      movement: undefined,
      part_daily: undefined
    }))
    if (Number(dailyPart.id) > 0) {
      if (isEditWorks && timelines.length === 0) {
        alert("necesita al menos un registro en la subtabla de trabajos")
        return
      }
      loadUpdateDailyPart(
        `${API_DAILY_PART}/${dailyPart.id}?edit_works=${isEditWorks}`,
        JSON.stringify(dailyPart)
      )
    } else {
      if (timelines.length === 0) {
        alert("necesita al menos un registro en la subtabla de trabajos")
        return
      }
      loadCreateDailyPart(API_DAILY_PART, JSON.stringify(dailyPart))
    }
  }
  const handleDeleteDailyPart = (id: number) => {
    loadDeleteDailyPart(`${API_DAILY_PART}/${id}`)
  }
  const changeValueDailyPart = (key: keyof IDailyPart, value: ValueOf<IDailyPart>) => {
    setDailyPart((prev) => ({
      ...prev,
      [key]: value
    }))
  }
  useEffect(() => {
    if (!responseCreate.loading && responseCreate.payload?.data) {
      alert("Guardado correctamente")
      resetData()
    }
  }, [responseCreate])
  useEffect(() => {
    if (!responseUpdate.loading && responseUpdate.payload?.data) {
      alert("Actualizado correctamente")
      resetDataUpdate()
    }
  }, [responseUpdate])

  return {
    loadingCUD: responseCreate.loading || responseUpdate.loading || responseDelete.loading,
    dailyPart,
    responseCreate,
    responseUpdate,
    isNotValidDailyForm,
    changeValueDailyPart,
    handleDeleteDailyPart,
    updateOrCreateDailyPart,
    resetData,
    setDailyPart
  }
}
export const downloadFileProject = (projectId: number, nameFile: string) => {
  downloadFile(`${API_EXPORT}?project_id=${projectId}`, nameFile)
}

export const useDailyByDate = () => {
  const { loadData: loadGetList, response: responseGet } = useFetch<IResponseDailyByDate>({
    method: "get"
  })
  const [familyFilter, setFamilyFilter] = useState<Partial<IFamily> | undefined>(
    DEFAULT_EQUIPMENT_FAMILY
  )
  const [projectFilter, setProjectFilter] = useState<Partial<IProject> | undefined>(DEFAULT_PROJECT)
  const [filterDailyList, setFilterDailyList] = useState<IDailyPartCustom[]>([])

  const loadDailyByDate = (date: string) => {
    loadGetList(`${API_DAILY_BY_DATE}${date}`)
  }
  const dailyList: { id: number; equipment: IEquipment; daily?: Partial<IDailyPartByDate> }[] =
    responseGet.payload?.equipment_list.map((e) => ({
      equipment: e,
      id: Number(e.id),
      daily: responseGet.payload?.daily_list.find((d) => d.equipment.id === e.id)
    })) ?? []

  useEffect(() => {
    let filteredList = dailyList
    if (familyFilter && familyFilter.id !== "0") {
      filteredList = filteredList.filter((d) => d.equipment.family?.id === familyFilter.id)
    }

    if (projectFilter && projectFilter.id !== "0") {
      filteredList = filteredList.filter(
        (item) => !!item.equipment.movements_active?.find((m) => projectFilter.id === m.project?.id)
      )
    }
    setFilterDailyList(filteredList)
  }, [familyFilter, projectFilter, dailyList.length])
  return {
    dailyList,
    filterDailyList,
    loadingDailyList: responseGet.loading,
    errorDailyDate: responseGet.errorPayload?.message,
    familyFilter,
    projectFilter,
    loadDailyByDate,
    setFamilyFilter,
    setProjectFilter
  }
}
const containsText = (text: string, searchText: string) =>
  text.toLowerCase().indexOf(searchText.toLowerCase()) > -1

export const useValorizationReport = ({ equipmentList }: { equipmentList: IEquipment[] }) => {
  const {
    loadData: loadGetList,
    response: responseGet,
    resetData
  } = useFetch<IResponse<IValorizationRow>>({
    method: "get"
  })

  const [searchText, setSearchText] = useState("")
  const [openForm, setOpenForm] = useState(false)
  const [loading, setLoading] = useState(false)
  const [isFilterSupplier, setIsFilterSupplier] = useState(false)
  const [from, setFrom] = useState("--")
  const [to, setTo] = useState("--")
  const [equipmentSelect, setEquipmentSelect] = useState<string[]>([])
  const [supplierIDSelect, setSupplierIDSelect] = useState<number>(0)
  const displayedEquipmentOptions = useMemo(
    () => equipmentList.filter((option) => containsText(option.plate, searchText)),
    [searchText, equipmentList.length]
  )
  const supplierList = useMemo(() => {
    const uniqueIds = new Set()
    const supplierList = equipmentList.filter((e) => !!e.supplier).map((e) => e.supplier) ?? []
    return supplierList.filter((item) => {
      const id = item?.id ?? ""
      if (!uniqueIds.has(id)) {
        uniqueIds.add(id)
        return true
      }
      return false
    })
  }, [equipmentList])
  const notValidForm = equipmentSelect.length === 0 || from === "--" || to === "--"
  const idsString = equipmentList
    .filter((e) => equipmentSelect.some((plate) => plate === e.plate))
    .map((e) => e.id)
    .join(",")
  const url_download_base = `${API_EXPORT}/valorization?equipment_id=${idsString}&from_at=${from}&to_at=${to}`
  const downloadFileValorization = async () => {
    if (notValidForm) return
    setLoading(true)

    await downloadFile(`${url_download_base}&download=true`, `VAL-${from}-${to}`)
    setTimeout(() => {
      setLoading(false)
    }, 2000)
  }
  useEffect(() => {
    if (!openForm) {
      resetData()
    }
  }, [openForm])
  return {
    openForm,
    loading: loading || responseGet.loading,
    isFilterSupplier,
    from,
    to,
    supplierIDSelect,
    supplierList,
    notValidForm,
    equipmentSelect,
    displayedEquipmentOptions,
    valorizationList: responseGet.payload?.data.filter((row) => row.data.length > 1) ?? [],
    setFrom,
    setSearchText,
    setOpenForm,
    setIsFilterSupplier,
    setEquipmentSelect,
    setSupplierIDSelect,
    setTo,
    downloadFileValorization,
    onClickVisualization: () => loadGetList(`${url_download_base}&download=false`)
  }
}
