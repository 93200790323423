import { Grid } from "@mui/material"
import Skeleton from "@mui/material/Skeleton"

export default function HomeSkeleton() {
  return (
    <Grid container>
      <Grid container>
        <Grid flex={0.75} margin={1}>
          <Skeleton variant="rectangular" width={"100%"} height={400} />
        </Grid>
        <Grid flex={0.75} margin={1}>
          <Skeleton variant="rectangular" width={"100%"} height={400} />
        </Grid>
        <Grid flex={1} margin={1}>
          <Skeleton variant="rectangular" width={"100%"} height={400} />
        </Grid>
      </Grid>
      <Grid container>
        <Grid flex={0.75} margin={1}>
          <Skeleton variant="rectangular" width={"100%"} height={400} />
        </Grid>
        <Grid flex={0.75} margin={1}>
          <Skeleton variant="rectangular" width={"100%"} height={400} />
        </Grid>
        <Grid flex={1} margin={1}>
          <Skeleton variant="rectangular" width={"100%"} height={332} />
          <Grid container flex={1} marginX={0} marginY={2} gap={2} flexDirection="row">
            <Grid item flex={1}>
              <Skeleton variant="rectangular" width={"100%"} height={50} />
            </Grid>
            <Grid item flex={1}>
              <Skeleton variant="rectangular" width={"100%"} height={50} />
            </Grid>
            <Grid item flex={1}>
              <Skeleton variant="rectangular" width={"100%"} height={50} />
            </Grid>
            <Grid item flex={1}>
              <Skeleton variant="rectangular" width={"100%"} height={50} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container>
        <Skeleton variant="rectangular" width={"100%"} height={500} />
      </Grid>
    </Grid>
  )
}
