import { IDailyPartCustom } from "./const"

const getResumePerFamily = (listDaily: IDailyPartCustom[]) => {
  const familyMap = new Map<string, number>()
  let hoursAllFamily = 0
  listDaily.forEach((e) => {
    const familyName = e.equipment.family?.family_name?.toUpperCase() ?? "SIN FAMILIA"
    const totalHours = Number(e.daily?.total_hours) || 0
    if (familyMap.has(familyName)) {
      familyMap.set(familyName, Number(familyMap.get(familyName)) + totalHours)
    } else {
      familyMap.set(familyName, totalHours)
    }
    hoursAllFamily += totalHours
  })
  const itemsFamily = Array.from(familyMap).map(([family_name, total]) => ({
    family_name,
    total
  }))
  return { itemsFamily, hoursAllFamily }
}

export { getResumePerFamily }
