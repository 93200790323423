import { Dispatch, SetStateAction } from "react"

import { CheckCircle, CheckCircleOutline, Delete, Edit } from "@mui/icons-material"
import { IconButton, Tooltip } from "@mui/material"
import { GridColDef } from "@mui/x-data-grid"
import { IEntity, IFamily } from "model/equipment"
import { IProject } from "model/project"
import { ISchedule } from "model/shift"
import { dateToString } from "utils"

interface IColumnFamily {
  setEditFamily: Dispatch<SetStateAction<IFamily | undefined>>
  setDeleteFamily: Dispatch<SetStateAction<IFamily | undefined>>
}

export const columnFamily: (params: IColumnFamily) => GridColDef<IFamily>[] = ({
  setDeleteFamily,
  setEditFamily
}) => {
  return [
    {
      field: "id",
      headerName: "ID",
      valueGetter: ({ row }) => row.id
    },
    {
      field: "family_name",
      headerName: "Nombre",
      valueGetter: ({ row }) => row.family_name
    },
    {
      field: "created_at",
      headerName: "Fecha de creación"
    },
    {
      field: "updated_at",
      headerName: "Fecha de edición"
    },
    {
      field: "deleted_at",
      headerName: "Fecha de eliminación"
    },
    {
      field: "state",
      headerName: "Estado",
      valueGetter: ({ row }) => (row.deleted_at ? "ELIMINADO" : "ACTIVO")
    },
    {
      field: "_",
      headerName: "Opciones",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      renderCell: ({ row }) => (
        <>
          <Tooltip title={`Editar o visualizar`}>
            <IconButton size="small" onClick={() => setEditFamily(row)}>
              <Edit color="warning" fontSize="small" />
            </IconButton>
          </Tooltip>
          <Tooltip title={`Eliminar`}>
            <IconButton size="small" onClick={() => setDeleteFamily(row)}>
              <Delete color="error" fontSize="small" />
            </IconButton>
          </Tooltip>
        </>
      )
    }
  ]
}

interface IColumnProject {
  setEditProject: Dispatch<SetStateAction<IProject | undefined>>
  setDeleteProject: Dispatch<SetStateAction<IProject | undefined>>
}

export const columnProject: (params: IColumnProject) => GridColDef<IProject>[] = ({
  setDeleteProject,
  setEditProject
}) => {
  return [
    {
      field: "id",
      headerName: "ID",
      width: 50
    },
    {
      field: "code",
      headerName: "Código",
      width: 80
    },
    {
      field: "name",
      headerName: "Nombre",
      width: 200
    },
    {
      field: "os",
      headerName: "OS"
    },
    {
      field: "client",
      headerName: "Cliente",
      valueGetter: (val) => val.row.customer?.business_name ?? "SIN CLIENTE"
    },

    {
      field: "schedule_id",
      headerName: "Horario",
      valueGetter: (val) => val.row.schedule?.name ?? "SIN HORARIO"
    },
    {
      field: "start_at",
      headerName: "Inicio",
      valueGetter: (val) => dateToString(new Date(val.row.start_at ?? 0))
    },
    {
      field: "end_at",
      headerName: "Fin Estimado",
      valueGetter: (val) => dateToString(new Date(val.row.end_at ?? 0))
    },
    {
      field: "delivery_at",
      headerName: "Entrega",
      valueGetter: (val) => dateToString(new Date(val.row.delivery_at ?? 0))
    },
    {
      field: "created_at",
      headerName: "Fecha de creación",
      valueGetter: (val) => dateToString(new Date(val.row.created_at ?? 0))
    },
    {
      field: "deleted_at",
      headerName: "Fecha de eliminación",
      valueGetter: (val) => (val.row.deleted_at ? dateToString(new Date(val.row.deleted_at)) : "")
    },
    {
      field: "state",
      headerName: "Estado"
    },
    {
      field: "_",
      headerName: "Opciones",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      renderCell: ({ row }) => (
        <>
          <Tooltip title={`Editar o visualizar`}>
            <IconButton size="small" onClick={() => setEditProject(row)}>
              <Edit color="warning" fontSize="small" />
            </IconButton>
          </Tooltip>
          {!row.deleted_at && (
            <Tooltip title={`Eliminar`}>
              <IconButton size="small" onClick={() => setDeleteProject(row)}>
                <Delete color="error" fontSize="small" />
              </IconButton>
            </Tooltip>
          )}
        </>
      )
    }
  ]
}

interface IColumnShift {
  setEditShift: Dispatch<SetStateAction<ISchedule | undefined>>
  setDeleteShift: Dispatch<SetStateAction<ISchedule | undefined>>
  setActiveDefault: Dispatch<SetStateAction<ISchedule | undefined>>
}

export const columnShift: (params: IColumnShift) => GridColDef<ISchedule>[] = ({
  setDeleteShift,
  setEditShift,
  setActiveDefault
}) => {
  return [
    {
      field: "id",
      headerName: "ID"
    },
    {
      field: "name",
      headerName: "Nombre"
    },
    {
      field: "start_day",
      headerName: "Inicio Día"
    },

    {
      field: "end_day",
      headerName: "Fin Día"
    },
    {
      field: "start_night",
      headerName: "Inicio Noche",
      flex: 1
    },
    {
      field: "end_night",
      headerName: "Fin Noche",
      flex: 1
    },
    {
      field: "created_at",
      headerName: "Fecha de creación",
      valueGetter: (val) => dateToString(new Date(val.row.created_at ?? 0))
    },
    {
      field: "deleted_at",
      headerName: "Fecha de eliminación",
      valueGetter: (val) => (val.row.deleted_at ? dateToString(new Date(val.row.deleted_at)) : "")
    },
    {
      field: "_",
      headerName: "Opciones",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      renderCell: ({ row }) => (
        <>
          <Tooltip title={`Editar o visualizar`}>
            <IconButton size="small" onClick={() => setEditShift(row)}>
              <Edit color="warning" fontSize="small" />
            </IconButton>
          </Tooltip>
          {!row.deleted_at && (
            <Tooltip
              title={
                row.default
                  ? "No se puede eliminar porque esta por defecto, primero cambie a el turno por defecto"
                  : `Eliminar`
              }
            >
              <IconButton
                size="small"
                onClick={!row.default ? () => setDeleteShift(row) : () => {}}
              >
                <Delete color="error" fontSize="small" />
              </IconButton>
            </Tooltip>
          )}

          {row.default ? (
            <IconButton color="primary" size="small">
              <CheckCircle />
            </IconButton>
          ) : (
            !row.deleted_at && (
              <Tooltip title="Activar por defecto">
                <IconButton size="small" onClick={() => setActiveDefault(row)}>
                  <CheckCircleOutline />
                </IconButton>
              </Tooltip>
            )
          )}
        </>
      )
    }
  ]
}

interface IColumnEntity {
  setEditEntity: Dispatch<SetStateAction<IEntity | undefined>>
  setDeleteEntity: Dispatch<SetStateAction<IEntity | undefined>>
}

export const columnsEntity: (params: IColumnEntity) => GridColDef<IEntity>[] = ({
  setDeleteEntity,
  setEditEntity
}) => {
  return [
    {
      field: "id",
      headerName: "ID"
    },
    {
      field: "ruc",
      headerName: "RUC"
    },
    {
      field: "business_name",
      headerName: "Nombre",
      minWidth: 300
    },
    {
      field: "_",
      headerName: "Opciones",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      renderCell: ({ row }) => (
        <>
          <Tooltip title={`Editar o visualizar`}>
            <IconButton size="small" onClick={() => setEditEntity(row)}>
              <Edit color="warning" fontSize="small" />
            </IconButton>
          </Tooltip>
          {!row.deleted_at && (
            <Tooltip title={`Eliminar`}>
              <IconButton size="small" onClick={() => setDeleteEntity(row)}>
                <Delete color="error" fontSize="small" />
              </IconButton>
            </Tooltip>
          )}
        </>
      )
    }
  ]
}

export const DEFAULT_VISIBLE_COLUMNS = {
  start_at: false,
  end_at: false,
  created_at: false,
  deleted_at: false,
  delivery_at: false
}
