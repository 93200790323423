import { useEffect, useState } from "react"

import { LocalGasStation, Refresh } from "@mui/icons-material"
import { Box, Button, Dialog, Grid, IconButton, Paper } from "@mui/material"
import { DataGrid, GridColumnVisibilityModel, GridToolbar } from "@mui/x-data-grid"
import { CardTitle, FormDialog } from "components"
import { EnumTypeFueling, IEntity, IEquipment, IFueling } from "model"

import { useFuelingCrud } from "hooks/fueling"

import { INITIAL_STATE_DATAGRID, PAGE_SIZE_OPTIONS, localizedTextsMap } from "constants/index"

import { DEFAULT_VISIBLE_COLUMNS } from "../settings/const"
import { FormFueling } from "./FormFueling"
import { MovementsFueling } from "./FuelingMovements"
import { FuelingReport } from "./FuelingReport"
import { columnFueling } from "./const"

export const ContainerFuelingList = () => {
  const [openForm, setOpenForm] = useState(false)
  const [openReport, setOpenReport] = useState(false)
  const [movementsFueling, setMovementsFueling] = useState<IFueling>()
  const [editFueling, setEditFueling] = useState<IFueling>()
  const [deleteFueling, setDeleteFueling] = useState<IFueling>()
  const [visibleColumns, setVisibleColumns] = useState<GridColumnVisibilityModel | undefined>(
    JSON.parse(localStorage.getItem("VISIBLE_PROJECT_LIST") ?? "{}") ?? undefined
  )
  useEffect(() => {
    const ls = localStorage.getItem("VISIBLE_PROJECT_LIST")
    if (!ls) {
      localStorage.setItem("VISIBLE_PROJECT_LIST", JSON.stringify(DEFAULT_VISIBLE_COLUMNS))
      setVisibleColumns(DEFAULT_VISIBLE_COLUMNS)
    }
  }, [])
  const {
    loadingList,
    loadingCUD,
    fueling,
    data: fuelingList,
    changeValueFueling,
    setFueling,
    refreshFuelingList,
    updateOrCreateFueling,
    handleDeleteFueling
  } = useFuelingCrud()

  useEffect(() => {
    if (editFueling) {
      const fueling = editFueling
      if (editFueling.type === EnumTypeFueling.FUEL_TANKER) {
        fueling.equipment = editFueling.resource as IEquipment
      }
      if (editFueling.type === EnumTypeFueling.SERVICE_STATION) {
        fueling.supplier = editFueling.resource as IEntity
      }
      setFueling(fueling)
    }
    setOpenForm(!!editFueling)
  }, [editFueling])

  useEffect(() => {
    if (!loadingCUD) {
      refreshFuelingList()
      setOpenForm(false)
      setFueling({})
      setDeleteFueling(undefined)
    }
  }, [loadingCUD])
  useEffect(() => {
    if (!openForm) {
      setFueling({})
      setEditFueling(undefined)
    }
  }, [openForm])

  const disableConfirm = !fueling.type || (!fueling.supplier && !fueling.equipment)
  return (
    <Grid container>
      <Grid container>
        <CardTitle
          title="Abastecimiento"
          subtitle="Unidades de abastecimiento"
          icon={<LocalGasStation fontSize="large" color="secondary" />}
        />
      </Grid>
      <Grid container component={Paper} marginTop={1} padding={2}>
        <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
          <Button size="small" variant="contained" onClick={() => setOpenForm((prev) => !prev)}>
            Crear Abastecimiento
          </Button>
          <Button size="small" variant="contained" onClick={() => setOpenReport((prev) => !prev)}>
            RESUMEN
          </Button>
          <IconButton disabled={loadingList} size="small" onClick={refreshFuelingList}>
            <Refresh fontSize="small" />
          </IconButton>
        </Box>
        <div style={{ width: "100%", marginTop: 16 }}>
          <DataGrid
            slots={{ toolbar: GridToolbar }}
            density="compact"
            rows={fuelingList}
            columns={columnFueling({
              setEditFueling,
              setMovementsFueling,
              setDeleteFueling
            })}
            onColumnVisibilityModelChange={(item) => {
              setVisibleColumns(item)
              localStorage.setItem("VISIBLE_PROJECT_LIST", JSON.stringify(item))
            }}
            columnVisibilityModel={visibleColumns}
            pagination
            autoHeight
            initialState={INITIAL_STATE_DATAGRID}
            disableRowSelectionOnClick
            pageSizeOptions={PAGE_SIZE_OPTIONS}
            localeText={localizedTextsMap}
            loading={loadingList}
          />
        </div>
      </Grid>
      <FormDialog
        open={openForm}
        visibleButtons={!loadingCUD}
        disabledButtons={disableConfirm}
        setOpen={setOpenForm}
        maxWidth="md"
        handleConfirm={updateOrCreateFueling}
        title={editFueling?.id ? `Editar ${editFueling.name}` : `Nuevo Abastecimiento`}
      >
        <FormFueling changeValueFueling={changeValueFueling} fueling={fueling} />
      </FormDialog>
      <FormDialog
        open={!!movementsFueling}
        visibleButtons={!loadingCUD}
        disabledButtons={disableConfirm}
        setOpen={() => {
          setMovementsFueling(undefined)
          refreshFuelingList()
        }}
        maxWidth="lg"
        handleConfirm={updateOrCreateFueling}
        title={`Movimientos de ${movementsFueling?.name}`}
      >
        <MovementsFueling fueling={movementsFueling} />
      </FormDialog>
      <FormDialog
        open={!!deleteFueling}
        handleConfirm={() => handleDeleteFueling(Number(deleteFueling?.id) ?? 0)}
        title="Eliminar Registro"
        setOpen={() => {
          setDeleteFueling(undefined)
        }}
      >
        <label>Vamos a eliminar el registro: {deleteFueling?.name} permanentemente</label>
      </FormDialog>
      <Dialog maxWidth="lg" open={openReport} onClose={() => setOpenReport((prev) => !prev)}>
        <FuelingReport />
      </Dialog>
    </Grid>
  )
}
