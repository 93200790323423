import { ChangeEvent, useEffect, useState } from "react"

import { ArrowCircleLeft, ArrowCircleRight, CalendarToday } from "@mui/icons-material"
import { Grid, IconButton, Popover, TextField } from "@mui/material"
import { COLOR_PRIMARY } from "constants/colors"
import { dateToString } from "utils"

import { DateView, IDateView } from "./DateView"
import { getMonthDays, subtractDays } from "./helps"
import { useTimeLineStyles as styles } from "./style"

interface IDatePicker extends IDateView {
  color?: string
  startDay?: Date
}

const DatePicker = (props: IDatePicker) => {
  const systemDate = new Date()
  const [currentDate, setCurrentDate] = useState(new Date())
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const startDate = subtractDays(currentDate, getMonthDays(currentDate.getMonth()))
  const handleBackMonth = () => {
    const newCurrentDate = subtractDays(currentDate, getMonthDays(currentDate.getMonth()))
    setCurrentDate(newCurrentDate)
    props.getSelectedDay && props.getSelectedDay(newCurrentDate)
  }
  const handleNextMonth = () => {
    const newCurrentDate = subtractDays(currentDate, -getMonthDays(currentDate.getMonth()))
    if (systemDate.getTime() < newCurrentDate.getTime()) {
      setCurrentDate(systemDate)
      props.getSelectedDay && props.getSelectedDay(systemDate)
      return
    }
    setCurrentDate(newCurrentDate)
    props.getSelectedDay && props.getSelectedDay(newCurrentDate)
  }
  const handleChangeDatePicker = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const newDate = new Date(`${e.target.value} 00:00:00`)
    if (isNaN(newDate.getTime())) return
    if (systemDate.getTime() < newDate.getTime()) {
      setCurrentDate(systemDate)
      props.getSelectedDay && props.getSelectedDay(systemDate)
      return
    }
    setCurrentDate(newDate)
    props.getSelectedDay && props.getSelectedDay(newDate)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  useEffect(() => {
    if (currentDate.getMonth() - startDate.getMonth() > 1) {
      const newDate = new Date(startDate.setDate(startDate.getDate() - 3))
      setCurrentDate(newDate)
      props.getSelectedDay && props.getSelectedDay(newDate)
    }
  }, [currentDate, startDate])
  const handleClickCalendar = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const open = Boolean(anchorEl)
  const equalDay =
    systemDate.getFullYear() === currentDate.getFullYear() &&
    systemDate.getMonth() === currentDate.getMonth() &&
    systemDate.getDate() === currentDate.getDate()
  const currentDateFormat = new Date(currentDate.setDate(currentDate.getDate()))
  return (
    <div style={styles.container}>
      <Grid item>
        <Grid container flexDirection="column">
          <IconButton size="small" color="primary" onClick={handleBackMonth}>
            <ArrowCircleLeft />
          </IconButton>
          <IconButton
            aria-describedby="popover-calendar"
            size="small"
            color="primary"
            onClick={handleClickCalendar}
          >
            <CalendarToday />
          </IconButton>
          <Popover
            id={"popover-calendar"}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left"
            }}
          >
            <TextField
              value={dateToString(props.selectDate ?? systemDate)}
              autoFocus
              onChange={handleChangeDatePicker}
              type="date"
              size="small"
            />
          </Popover>
        </Grid>
      </Grid>

      <DateView
        {...props}
        primaryColor={COLOR_PRIMARY}
        startDate={startDate}
        lastDate={currentDateFormat}
      />
      {!equalDay && (
        <Grid item>
          <Grid container flexDirection="column">
            <IconButton size="small" color="primary" onClick={handleNextMonth}>
              <ArrowCircleRight />
            </IconButton>
          </Grid>
        </Grid>
      )}
    </div>
  )
}

export { DatePicker }
