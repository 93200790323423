import { useEffect, useState } from "react"

import { Add, AttachMoney, Redo, Refresh, Splitscreen } from "@mui/icons-material"
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip
} from "@mui/material"
import { DataGrid, GridColumnVisibilityModel, GridToolbar } from "@mui/x-data-grid"
import { CardTitle } from "components/common/CardTitle"
import FormDialog from "components/common/Dialog"
import { INITIAL_STATE_DATAGRID, PAGE_SIZE_OPTIONS, localizedTextsMap } from "constants/const"
import { State } from "hooks/common/petitions"
import { useEntityList } from "hooks/entity"
import { DEFAULT_EQUIPMENT, useCrudEquipment, useEquipmentFamilyList } from "hooks/equipment"
import { useCrudMovement } from "hooks/movement"
import { DEFAULT_PROJECT, useProjectList } from "hooks/project"
import { IResponseEntity, ValueOf } from "model/common"
import {
  EnumCurrency,
  EnumEntity,
  EnumEquipmentRental,
  IEntity,
  IEquipment,
  IFamily
} from "model/equipment"
import { IEquipmentProjectMovement } from "model/equipment_project"
import { EnumProjectState } from "model/project"
import { dateToString } from "utils"

import { columnEquipmentList } from "./const"

const DEFAULT_VISIBLE_COLUMNS = {
  brand: false,
  code: false,
  series: false,
  model: false,
  n_motor: false,
  power: false,
  color: false,
  year_production: false,
  mileage: false,
  purchase_price: false,
  expire_gps: false,
  expire_soat: false,
  expire_trec: false,
  expire_inspection: false,
  fee: false
}
export const EquipmentListTable = () => {
  const [openForm, setOpenForm] = useState(false)
  const [equipDeleted, setEquipDeleted] = useState<Partial<IEquipment> | undefined>(undefined)
  const [equipEdited, setEquipEdited] = useState<Partial<IEquipment> | undefined>(undefined)
  const [equipMovement, setEquipMovement] = useState<Partial<IEquipment> | undefined>(undefined)
  const [visibleColumns, setVisibleColumns] = useState<GridColumnVisibilityModel | undefined>(
    JSON.parse(localStorage.getItem("VISIBLE_EQUIPMENT_LIST") ?? "{}") ?? undefined
  )
  const propsCrudEquipment = useCrudEquipment()
  useEffect(() => {
    const ls = localStorage.getItem("VISIBLE_EQUIPMENT_LIST")
    if (!ls) {
      localStorage.setItem("VISIBLE_EQUIPMENT_LIST", JSON.stringify(DEFAULT_VISIBLE_COLUMNS))
      setVisibleColumns(DEFAULT_VISIBLE_COLUMNS)
    }
  }, [])
  useEffect(() => {
    if (equipEdited) {
      propsCrudEquipment.setEquipment(equipEdited)
    }
    setOpenForm(!!equipEdited)
  }, [equipEdited])
  useEffect(() => {
    if (!propsCrudEquipment.loadingCUD) {
      propsCrudEquipment.setEquipment({ ...DEFAULT_EQUIPMENT, code: "", plate: "" })
      setEquipDeleted(undefined)
      setEquipEdited(undefined)
      setOpenForm(false)
      propsCrudEquipment.refreshEquipmentList()
    }
  }, [propsCrudEquipment.loadingCUD])

  useEffect(() => {
    if (!openForm) {
      setEquipEdited(undefined)
      propsCrudEquipment.setEquipment({ ...DEFAULT_EQUIPMENT, code: "", plate: "" })
    }
  }, [openForm])
  useEffect(() => {
    if (propsCrudEquipment.errCreateUpdate) {
      alert(propsCrudEquipment.errCreateUpdate)
    }
  }, [propsCrudEquipment.errCreateUpdate])

  return (
    <Grid container md={12} lg={12} paddingTop={1} component={Paper}>
      <Box
        padding={2}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        width="100%"
      >
        <Button size="small" variant="contained" onClick={() => setOpenForm((prev) => !prev)}>
          Crear activo
        </Button>
        <IconButton
          disabled={propsCrudEquipment.loadingList.loading}
          size="small"
          onClick={propsCrudEquipment.refreshEquipmentList}
        >
          <Refresh fontSize="small" />
        </IconButton>
      </Box>
      <Grid container md={12} padding={2}>
        <DataGrid
          slots={{ toolbar: GridToolbar }}
          density="compact"
          rows={propsCrudEquipment.data.filter((e) => !!e.id) ?? []}
          columns={columnEquipmentList({
            setDeleteEquipment: setEquipDeleted,
            setEditEquipment: setEquipEdited,
            setMovement: setEquipMovement
          })}
          onColumnVisibilityModelChange={(item) => {
            setVisibleColumns(item)
            localStorage.setItem("VISIBLE_EQUIPMENT_LIST", JSON.stringify(item))
          }}
          columnVisibilityModel={visibleColumns}
          pagination
          autoHeight
          initialState={INITIAL_STATE_DATAGRID}
          disableRowSelectionOnClick
          pageSizeOptions={PAGE_SIZE_OPTIONS}
          localeText={localizedTextsMap}
          loading={propsCrudEquipment.loadingList.loading}
        />
      </Grid>
      <FormDialog
        open={openForm}
        visibleButtons={!propsCrudEquipment.loadingCUD}
        setOpen={setOpenForm}
        maxWidth="md"
        handleConfirm={propsCrudEquipment.updateOrCreateEquipment}
        title={equipEdited ? "Editar equipo " + equipEdited.code : `Nuevo equipo`}
      >
        <FormEquipment {...propsCrudEquipment} />
      </FormDialog>
      <FormDialog
        open={!!equipMovement}
        setOpen={() => {
          setEquipMovement(undefined)
          propsCrudEquipment.refreshEquipmentList()
        }}
        visibleButtons={false}
        maxWidth="lg"
        handleConfirm={propsCrudEquipment.updateOrCreateEquipment}
        title={`Ingresos/salidas de ${equipMovement?.plate}`}
      >
        <ProjectEquipmentMovements equipment={equipMovement ?? {}} />
      </FormDialog>
      <FormDialog
        open={!!equipDeleted}
        handleConfirm={() =>
          propsCrudEquipment.handleDeleteEquipment(Number(equipDeleted?.id) ?? 0)
        }
        title="Eliminar Registro"
        setOpen={() => {
          setEquipDeleted(undefined)
        }}
      >
        <label>
          Vamos a eliminar el registro: {equipDeleted?.plate}/{equipDeleted?.code}
        </label>
      </FormDialog>
    </Grid>
  )
}
export const FormEquipmentMovement = ({
  equipment,
  movementSelect,
  props
}: {
  equipment: Partial<IEquipment>
  movementSelect?: { project_id: string; movement_id: string }
  props: {
    loadingCUD: boolean | undefined
    responseCreate: State<IResponseEntity<IEquipmentProjectMovement>>
    movementForm: Partial<IEquipmentProjectMovement>
    loadingShow: boolean | undefined
    equipmentDB: IEquipment | undefined
    changeValueMovement: (
      key: keyof IEquipmentProjectMovement,
      value: ValueOf<IEquipmentProjectMovement>
    ) => void
    refreshEquipment: (id_equipment: number) => void
    updateOrCreateMovement: (equipment: Partial<IEquipment>) => void
    resetData: () => void
  }
}) => {
  const { responseCreate, loadingCUD, movementForm, equipmentDB, changeValueMovement } = props
  const { loadingList, projectList } = useProjectList({ showDeleted: false })
  useEffect(() => {
    console.log("equipmentDB", equipmentDB)

    changeValueMovement("id", movementSelect?.movement_id ?? 0)
    changeValueMovement("input_hourmeter", equipmentDB?.hourmeter ?? 0)
    changeValueMovement("output_hourmeter", equipmentDB?.hourmeter ?? 0)
    changeValueMovement("tariff_price", equipmentDB?.tariff_price ?? 0)
  }, [equipmentDB])
  useEffect(() => {
    if (movementSelect?.movement_id) {
      changeValueMovement(
        "project",
        projectList.find((p) => Number(movementSelect.project_id) === Number(p.id))
      )
      changeValueMovement("project_id", movementSelect.project_id)
      changeValueMovement("output_at", dateToString(new Date()))
    } else {
      changeValueMovement("project", undefined)
      changeValueMovement("project_id", undefined)
    }
  }, [movementSelect, projectList.length])

  useEffect(() => {
    if (equipment.id && !responseCreate.loading && !responseCreate.errorPayload?.message) {
      changeValueMovement("output_notes", "")
      changeValueMovement("input_notes", "")
      changeValueMovement("project_id", "")
      changeValueMovement("project", undefined)
    } else if (responseCreate.errorPayload?.message) {
      alert(` ${responseCreate.errorPayload}`)
      props.resetData()
    }
  }, [responseCreate])

  const changeValueDate = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    changeValueMovement(movementSelect ? "output_at" : "input_at", e.target.value)
  }
  const changeValueHourmeter = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    changeValueMovement(movementSelect ? "output_hourmeter" : "input_hourmeter", e.target.value)
  }
  const changeValueNotes = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    changeValueMovement(movementSelect ? "output_notes" : "input_notes", e.target.value)
  }
  return (
    <Grid container gap={1}>
      <Grid container mt={1}>
        <Grid item flex={1}>
          <TextField
            size="small"
            value={movementSelect ? movementForm.output_at ?? "" : movementForm.input_at ?? ""}
            onChange={changeValueDate}
            type="date"
            label="Fecha de movimiento"
            variant="outlined"
            fullWidth
          />
        </Grid>
        <Grid item flex={1}>
          <TextField
            size="small"
            value={
              movementSelect
                ? movementForm.output_hourmeter ?? 0
                : movementForm.input_hourmeter ?? 0
            }
            onChange={changeValueHourmeter}
            type="number"
            label="Horometro"
            variant="outlined"
            helperText={`Ult. Hor.: ${equipmentDB?.hourmeter ?? 0}`}
          />
        </Grid>
        <Grid item flex={1}>
          <TextField
            size="small"
            InputProps={{
              startAdornment: <AttachMoney fontSize="small" />
            }}
            value={movementForm.tariff_price ?? "0"}
            onChange={(e) => changeValueMovement("tariff_price", e.target.value)}
            type="number"
            label="Tarifa (PxH)"
            variant="outlined"
            helperText={`Tarifa sug.: $${equipmentDB?.tariff_price ?? 0}`}
          />
        </Grid>
      </Grid>
      <Grid container mt={1} alignItems={"flex-end"} gap={0.5}>
        <Grid item flex={1}>
          {!loadingList ? (
            <Autocomplete
              value={movementForm?.project ?? DEFAULT_PROJECT}
              onChange={(_, value) => {
                changeValueMovement("project", value ?? DEFAULT_PROJECT)
                changeValueMovement("project_id", value?.id)
              }}
              fullWidth
              disabled={!!movementSelect}
              size="small"
              isOptionEqualToValue={(val, other) => Number(val?.id) === Number(other?.id)}
              options={projectList.filter((p) => p.state === EnumProjectState.ACTIVE) ?? []}
              getOptionLabel={(opt) => `${opt.os} - ${opt.name}`}
              renderInput={(params) => <TextField {...params} label="Proyecto" />}
            />
          ) : (
            "Agrega proyectos en configuraciones"
          )}
        </Grid>
        <Grid item flex={1}>
          <TextField
            size="small"
            value={
              movementSelect ? movementForm.output_notes ?? "" : movementForm.input_notes ?? ""
            }
            onChange={changeValueNotes}
            style={{ flex: 1 }}
            label={`Nota de ${movementSelect ? "Salida" : "Ingreso"}`}
            variant="outlined"
            multiline
            fullWidth
            maxRows={2}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}
export const ProjectEquipmentMovements = ({ equipment }: { equipment: Partial<IEquipment> }) => {
  const [openForm, setOpen] = useState<boolean>(false)
  const [movementSelect, setMovementSelect] = useState<
    { project_id: string; movement_id: string } | undefined
  >(undefined)
  const props = useCrudMovement()
  const saveEquipment = () => props.equipmentDB && props.updateOrCreateMovement(props.equipmentDB)
  useEffect(() => {
    if (!openForm && equipment.id) {
      props.refreshEquipment(Number(equipment.id))
    }
  }, [openForm])
  useEffect(() => {
    if (!props.responseCreate.errorPayload) {
      setOpen(false)
      setMovementSelect(undefined)
    }
  }, [props.responseCreate])
  return (
    <Grid container>
      <Grid container mt={0.5}>
        <Button onClick={() => setOpen(true)} size="small" variant="contained">
          Nuevo Ingreso
          <Add fontSize="small" />
        </Button>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell colSpan={2} />
                <TableCell colSpan={3} style={{ borderLeft: "1px #ccc solid" }}>
                  <strong>INGRESO</strong>
                </TableCell>
                <TableCell colSpan={4} style={{ borderLeft: "1px #ccc solid" }}>
                  <strong>SALIDA</strong>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left">
                  <strong>Proyecto</strong>
                </TableCell>
                <TableCell align="left">
                  <strong>Tarifa</strong>
                </TableCell>

                <TableCell align="left" style={{ borderLeft: "1px #ccc solid" }}>
                  <strong>Fecha</strong>
                </TableCell>
                <TableCell align="left">
                  <strong>Horometro</strong>
                </TableCell>
                <TableCell align="left">
                  <strong>Notas</strong>
                </TableCell>

                <TableCell align="left" style={{ borderLeft: "1px #ccc solid" }}>
                  <strong>Fecha</strong>
                </TableCell>
                <TableCell align="left">
                  <strong>Horometro</strong>
                </TableCell>
                <TableCell align="left">
                  <strong>Notas</strong>
                </TableCell>
                <TableCell align="left" style={{ borderLeft: "1px #ccc solid" }}>
                  <strong>Op.</strong>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.equipmentDB?.movements?.map((m, index) => (
                <TableRow
                  selected={!m.output_at}
                  key={index}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="left">{m.project?.name}</TableCell>
                  <TableCell align="left">${Number(m.tariff_price ?? 0).toFixed(2)}</TableCell>
                  <TableCell align="left" style={{ borderLeft: "1px #ccc solid" }}>
                    {m.input_at}
                  </TableCell>
                  <TableCell align="left">{m.input_hourmeter}</TableCell>
                  <TableCell align="left">{m.input_notes ?? "---"}</TableCell>
                  <TableCell align="left" style={{ borderLeft: "1px #ccc solid" }}>
                    {m.output_at}
                  </TableCell>
                  <TableCell align="left">{m.output_hourmeter}</TableCell>
                  <TableCell align="left">{m.output_notes}</TableCell>
                  <TableCell align="left" style={{ borderLeft: "1px #ccc solid" }}>
                    {!m.output_at && (
                      <Tooltip title="Salida" placement="right">
                        <IconButton
                          onClick={() => {
                            setOpen(true)
                            setMovementSelect({
                              movement_id: m.id + "",
                              project_id: m.project_id + ""
                            })
                          }}
                          size="small"
                          color="info"
                        >
                          <Redo fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <FormDialog
          open={openForm}
          title={!movementSelect ? "Ingreso" : "Salida"}
          setOpen={() => {
            setOpen(false)
            setMovementSelect(undefined)
          }}
          handleConfirm={saveEquipment}
        >
          <FormEquipmentMovement
            equipment={equipment}
            movementSelect={movementSelect}
            props={props}
          />
        </FormDialog>
      </Grid>
    </Grid>
  )
}
export const FormEquipment = ({
  changeValueEquipment,
  equipment
}: {
  changeValueEquipment: Function
  equipment: Partial<IEquipment>
}) => {
  const { equipmentFamilyList, errorList, loadingList } = useEquipmentFamilyList()

  const changeEquipmentFamily = (fam?: Partial<IFamily> | null) => {
    changeValueEquipment("family", fam ?? undefined)
    changeValueEquipment("family_id", fam?.id ?? 0)
  }
  const changeEquipmentSupplier = (sup?: Partial<IEntity> | null) => {
    changeValueEquipment("supplier", sup ?? undefined)
    changeValueEquipment("supplier_id", sup?.id ?? 0)
  }
  const {
    errorList: errorSupplierList,
    loadingList: loadingSupplierList,
    entityList,
    refreshEntityList
  } = useEntityList({ type: EnumEntity.SUPPLIER })
  useEffect(() => {
    refreshEntityList()
  }, [])
  return (
    <Grid container>
      <Box width="100%" height={8} />
      <Grid container>
        <Grid item flex={1}>
          <Autocomplete
            loading={loadingList}
            value={equipment.family}
            onChange={(_, value) => changeEquipmentFamily(value)}
            fullWidth
            size="small"
            isOptionEqualToValue={(val, other) => Number(val?.id) === Number(other?.id)}
            options={equipmentFamilyList ?? []}
            getOptionLabel={(opt) => opt.family_name ?? ""}
            renderInput={(params) => <TextField {...params} label="Familia *" />}
          />
        </Grid>

        <Grid item flex={1}>
          <TextField
            size="small"
            value={equipment.code ?? ""}
            onChange={(e) => changeValueEquipment("code", e.target.value)}
            fullWidth
            label="Código *"
            variant="outlined"
          />
        </Grid>
        <Grid item flex={1}>
          <TextField
            size="small"
            fullWidth
            label="Placa *"
            variant="outlined"
            value={equipment.plate ?? ""}
            onChange={(e) => changeValueEquipment("plate", e.target.value)}
          />
        </Grid>
        <Grid item flex={1}>
          <TextField
            size="small"
            fullWidth
            label="Serie"
            variant="outlined"
            value={equipment.series ?? ""}
            onChange={(e) => changeValueEquipment("series", e.target.value)}
          />
        </Grid>
      </Grid>
      <Box width="100%" height={8} />

      <Grid container>
        <Grid item flex={1}>
          <TextField
            size="small"
            fullWidth
            label="N° Motor"
            variant="outlined"
            value={equipment.n_motor ?? ""}
            onChange={(e) => changeValueEquipment("n_motor", e.target.value)}
          />
        </Grid>
        <Grid item flex={1}>
          <TextField
            size="small"
            fullWidth
            label="Marca"
            variant="outlined"
            value={equipment.brand ?? ""}
            onChange={(e) => changeValueEquipment("brand", e.target.value)}
          />
        </Grid>
        <Grid item flex={1}>
          <TextField
            size="small"
            fullWidth
            label="Modelo"
            variant="outlined"
            value={equipment.model ?? ""}
            onChange={(e) => changeValueEquipment("model", e.target.value)}
          />
        </Grid>
        <Grid item flex={1}>
          <TextField
            size="small"
            fullWidth
            label="Potencia"
            variant="outlined"
            value={equipment.power ?? ""}
            onChange={(e) => changeValueEquipment("power", e.target.value)}
          />
        </Grid>
      </Grid>
      <Box width="100%" height={8} />

      <Grid container>
        <Grid item flex={1}>
          <TextField
            size="small"
            fullWidth
            label="Color"
            variant="outlined"
            value={equipment.color ?? ""}
            onChange={(e) => changeValueEquipment("color", e.target.value)}
          />
        </Grid>
        <Grid item flex={1}>
          <TextField
            size="small"
            fullWidth
            label="Año de fabricación"
            variant="outlined"
            value={equipment.year_production ?? ""}
            onChange={(e) => changeValueEquipment("year_production", e.target.value)}
          />
        </Grid>
        <Grid item flex={1}>
          <TextField
            size="small"
            fullWidth
            label="Horometro"
            variant="outlined"
            type="number"
            disabled
            value={equipment.hourmeter ?? ""}
            onChange={(e) => changeValueEquipment("hourmeter", e.target.value)}
          />
        </Grid>
        <Grid item flex={1}>
          <TextField
            size="small"
            fullWidth
            label="Kilometraje"
            variant="outlined"
            type="number"
            value={equipment.mileage ?? ""}
            onChange={(e) => changeValueEquipment("mileage", e.target.value)}
          />
        </Grid>
      </Grid>
      <Box width="100%" height={8} />

      <Grid container>
        <Grid item flex={1}>
          <TextField
            size="small"
            fullWidth
            label="Empresa Soat"
            variant="outlined"
            value={equipment.soat ?? ""}
            onChange={(e) => changeValueEquipment("soat", e.target.value)}
          />
        </Grid>
        <Grid item flex={1}>
          <TextField
            size="small"
            fullWidth
            label="F. ven. Soat"
            type="date"
            variant="outlined"
            value={equipment.expire_soat ?? ""}
            onChange={(e) => changeValueEquipment("expire_soat", e.target.value)}
          />
        </Grid>

        <Grid item flex={1}>
          <TextField
            size="small"
            fullWidth
            label="F. ven. Trec"
            type="date"
            variant="outlined"
            value={equipment.expire_trec ?? "--"}
            onChange={(e) => changeValueEquipment("expire_trec", e.target.value)}
          />
        </Grid>
        <Grid item flex={1}>
          <TextField
            size="small"
            fullWidth
            label="F. ven. Insp. Tec."
            type="date"
            variant="outlined"
            value={equipment.expire_inspection ?? "--"}
            onChange={(e) => changeValueEquipment("expire_inspection", e.target.value)}
          />
        </Grid>
      </Grid>
      <Box width="100%" height={8} />
      <Grid container>
        <Grid item flex={1}>
          <TextField
            size="small"
            fullWidth
            label="F. ven. GPS"
            type="date"
            variant="outlined"
            value={equipment.expire_gps ?? "--"}
            onChange={(e) => changeValueEquipment("expire_gps", e.target.value)}
          />
        </Grid>
        <Grid item flex={1}>
          <TextField
            size="small"
            fullWidth
            label="F. Tarjeta Merc."
            type="date"
            variant="outlined"
            value={equipment.expire_card_merchandise ?? "--"}
            onChange={(e) => changeValueEquipment("expire_card_merchandise", e.target.value)}
          />
        </Grid>
        <Grid item flex={1}>
          <TextField
            size="small"
            InputProps={{
              startAdornment: <AttachMoney fontSize="small" />
            }}
            fullWidth
            label="Costo/Tarifa"
            type="number"
            variant="outlined"
            value={equipment.tariff_price ?? ""}
            onChange={(e) => changeValueEquipment("tariff_price", e.target.value)}
          />
        </Grid>
      </Grid>
      <Grid container gap={1} paddingTop={1} alignItems={"center"}>
        <Grid container flexDirection="column" style={{ maxWidth: 70 }}>
          <label>Alquilado</label>
          <FormControlLabel
            control={
              <Switch
                checked={!equipment.own}
                onChange={(val) => changeValueEquipment("own", !equipment.own)}
              />
            }
            label=""
            value={equipment.series ?? ""}
          />
        </Grid>

        {equipment.own ? (
          <Grid item flex={1}>
            <TextField
              size="small"
              label="Precio de compra *"
              variant="outlined"
              type="number"
              value={equipment.purchase_price ?? ""}
              onChange={(e) => changeValueEquipment("purchase_price", e.target.value)}
            />
          </Grid>
        ) : (
          <>
            <Select
              value={equipment.rental_currency}
              label="Moneda *"
              variant="outlined"
              size="small"
              onChange={(e) => changeValueEquipment("rental_currency", e.target.value)}
            >
              <MenuItem value={EnumCurrency.USD}>$</MenuItem>
              <MenuItem value={EnumCurrency.PEN}>S/</MenuItem>
            </Select>
            <TextField
              size="small"
              label="Precio de alquiler *"
              type="number"
              InputProps={{
                startAdornment: equipment.rental_currency
              }}
              variant="outlined"
              value={equipment.fee_price ?? ""}
              onChange={(e) => changeValueEquipment("fee_price", e.target.value)}
            />
            <FormControl size="small">
              <FormLabel>Tipo alquiler *</FormLabel>
              <RadioGroup
                onChange={(e, v) => changeValueEquipment("rental", v)}
                value={equipment.rental}
                row
                name="row-radio-buttons-group"
              >
                <FormControlLabel
                  value={EnumEquipmentRental.DRY}
                  control={<Radio />}
                  label={EnumEquipmentRental.DRY}
                  checked={equipment.rental === EnumEquipmentRental.DRY}
                />
                <FormControlLabel
                  value={EnumEquipmentRental.SEMI_DRY}
                  control={<Radio />}
                  label={EnumEquipmentRental.SEMI_DRY}
                  checked={equipment.rental === EnumEquipmentRental.SEMI_DRY}
                />
                <FormControlLabel
                  value={EnumEquipmentRental.OPERATED}
                  control={<Radio />}
                  label={EnumEquipmentRental.OPERATED}
                  checked={equipment.rental === EnumEquipmentRental.OPERATED}
                />
              </RadioGroup>
            </FormControl>
            <Grid container flex={1}>
              <Autocomplete
                style={{ flex: 1 }}
                loading={loadingList}
                value={equipment.supplier}
                onChange={(_, value) => changeEquipmentSupplier(value)}
                size="small"
                isOptionEqualToValue={(val, other) => Number(val?.id) === Number(other?.id)}
                options={entityList ?? []}
                getOptionLabel={(opt) => `${opt.ruc}-${opt.business_name}`}
                renderInput={(params) => <TextField {...params} label="Proveedor *" />}
              />
              <IconButton onClick={refreshEntityList}>
                <Refresh fontSize="small" />
              </IconButton>
            </Grid>
          </>
        )}
      </Grid>
    </Grid>
  )
}

export const ContainerEquipmentList = () => {
  return (
    <Grid container gap={1} flexDirection={"column"} alignItems={"end"}>
      <Grid container justifyContent={"space-around"}>
        <CardTitle
          title="Equipos"
          subtitle="Lista de activos"
          icon={<Splitscreen fontSize="large" color="secondary" />}
          flex={1}
        />
      </Grid>
      <Grid container>
        <EquipmentListTable />
      </Grid>
    </Grid>
  )
}
