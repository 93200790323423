import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@mui/material"

import { IValorizationRecord } from "./const"

interface ValorizationTableProps {
  rows: IValorizationRecord[]
}
const calculateDiffHourmeter = (startHourmeter: string, endHourmeter: string) => {
  return (Number(endHourmeter) ?? 0) - (Number(startHourmeter) ?? 0)
}
export const ValorizationTableBody = ({ rows }: { rows: IValorizationRecord[] }) => {
  const total_hours = rows.reduce(
    (cc, current) => cc + calculateDiffHourmeter(current.start_hourmeter, current.end_hourmeter),
    0
  )
  const total_tariff = rows.reduce(
    (cc, current) =>
      cc +
      calculateDiffHourmeter(current.start_hourmeter, current.end_hourmeter) *
        Number(current.tariff_price),
    0
  )
  const total_tonnage = rows.reduce((cc, current) => cc + Number(current.tonnage), 0)
  return (
    <TableBody>
      {rows.map(({ date_part, end_hourmeter, start_hourmeter, tariff_price, tonnage }, key) => (
        <TableRow key={key} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
          <TableCell size="small" component="th" scope="row">
            {date_part}
          </TableCell>
          <TableCell size="small" align="right">
            {start_hourmeter}
          </TableCell>
          <TableCell size="small" align="right">
            {end_hourmeter}
          </TableCell>
          <TableCell size="small" align="right">
            {calculateDiffHourmeter(start_hourmeter, end_hourmeter).toFixed(2)}
          </TableCell>
          <TableCell size="small" align="right">
            {tonnage}
          </TableCell>
          <TableCell size="small" align="right">
            {tariff_price}
          </TableCell>
          <TableCell size="small" align="right">
            {(
              calculateDiffHourmeter(start_hourmeter, end_hourmeter) * Number(tariff_price)
            ).toFixed(2)}
          </TableCell>
        </TableRow>
      ))}
      <TableRow selected>
        <TableCell align="right" colSpan={3}>
          TOTAL:
        </TableCell>
        <TableCell align="right">{total_hours.toFixed(2)}</TableCell>
        <TableCell align="right">{total_tonnage.toFixed(2)}</TableCell>
        <TableCell align="right" colSpan={2}>
          {total_tariff.toFixed()}
        </TableCell>
      </TableRow>
    </TableBody>
  )
}
export const ValorizationTable = ({ rows }: ValorizationTableProps) => {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow selected>
            <TableCell size="small">Fecha</TableCell>
            <TableCell size="small" align="right">
              HI
            </TableCell>
            <TableCell size="small" align="right">
              HF
            </TableCell>
            <TableCell size="small" align="right">
              Horas
            </TableCell>
            <TableCell size="small" align="right">
              Tonelaje
            </TableCell>
            <TableCell size="small" align="right">
              Tarifa
            </TableCell>
            <TableCell size="small" align="right">
              Monto
            </TableCell>
          </TableRow>
        </TableHead>
        <ValorizationTableBody rows={rows} />
      </Table>
    </TableContainer>
  )
}
