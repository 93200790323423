import { useEffect, useState } from "react"

import { API_PROJECT } from "../constants/routes"
import { IResponse, IResponseEntity, ValueOf } from "../model/common"
import { EnumProjectState, IProject } from "../model/project"
import { useFetch } from "./common/petitions"

export const DEFAULT_PROJECT: IProject = {
  id: "0",
  name: "Selecciona proyecto",
  os: "--",
  customer_id: 0,
  code: "",
  description: "",
  projected_cost: 0,
  state: EnumProjectState.ACTIVE
}
export const DEFAULT_PROJECT_CREATE: IProject = {
  id: "0",
  name: "",
  os: "",
  customer_id: 0,
  code: "",
  description: "",
  projected_cost: 0,
  state: EnumProjectState.ACTIVE
}

export const useProjectList = ({ showDeleted }: { showDeleted?: boolean }) => {
  const { loadData, response } = useFetch<IResponse<IProject>>({
    method: "get"
  })
  const [refreshProject, setRefreshProject] = useState(false)

  useEffect(() => {
    loadData(API_PROJECT)
  }, [refreshProject])

  const list = showDeleted
    ? response.payload?.data
    : response.payload?.data.filter((item) => !item.deleted_at)

  return {
    projectList: [DEFAULT_PROJECT, ...(list ?? [])] ?? [DEFAULT_PROJECT],
    loadingList: response.loading,
    errorList: response.errorPayload,
    setRefreshProject
  }
}

export const useCrudProject = ({ showDeleted = true }: { showDeleted?: boolean }) => {
  const [project, setProject] = useState<Partial<IProject>>(DEFAULT_PROJECT_CREATE)
  const { loadData: loadGetProject, response: responseGet } = useFetch<IResponse<IProject>>({
    method: "get"
  })
  const { loadData: loadCreateProject, response: responseCreate } = useFetch<
    IResponseEntity<IProject>
  >({
    method: "post"
  })
  const { loadData: loadUpdateProject, response: responseUpdate } = useFetch<
    IResponseEntity<IProject>
  >({
    method: "put"
  })
  const { loadData: loadDeleteProject, response: responseDelete } = useFetch<
    IResponseEntity<IProject>
  >({
    method: "delete"
  })

  const updateOrCreateProject = () => {
    console.log("Project to save", project)
    const date_start_at = new Date(project.start_at ?? "")
    const date_end_at = new Date(project.end_at ?? "")
    const date_delivery_at = new Date(project.delivery_at ?? "")
    if (
      !project ||
      !project.customer_id ||
      !project.name ||
      !project.code ||
      !project.schedule_id ||
      isNaN(date_start_at.getTime()) ||
      isNaN(date_end_at.getTime()) ||
      !project.state
    ) {
      let msm = ""
      msm += project.customer_id ? "" : " cliente,"
      msm += project.name ? "" : " nombre,"
      msm += project.code ? "" : " código,"
      msm += project.schedule_id ? "" : " horario/turno,"
      msm += !isNaN(date_start_at.getTime()) ? "" : " fecha de inicio,"
      msm += !isNaN(date_end_at.getTime()) ? "" : " fecha de fin,"
      msm += project.state ? "" : " estado,"
      alert("Error en " + msm + " son obligatorios")
      return
    }
    if (project.state === EnumProjectState.FINALIZE && isNaN(date_delivery_at.getTime())) {
      alert("Al finalizar necesita una fecha de entrega correcta ")
      return
    }
    if (project.state !== EnumProjectState.FINALIZE && !isNaN(date_delivery_at.getTime())) {
      alert("El estado debe ser finalizado si ya tiene fecha de entrega")
      return
    }
    if (Number(project.id) > 0) {
      if (project.state === EnumProjectState.DELETED) {
        handleDeleteProject(Number(project.id))
      } else {
        restore()
      }
      loadUpdateProject(`${API_PROJECT}/${project.id}`, JSON.stringify(project))
    } else {
      loadCreateProject(API_PROJECT, JSON.stringify(project))
    }
  }
  const restore = () => {
    loadUpdateProject(`${API_PROJECT}/${project.id}/restore`, JSON.stringify(project))
  }
  const handleDeleteProject = (id: number) => {
    loadDeleteProject(`${API_PROJECT}/${id}`)
  }
  const changeValueProject = (key: keyof IProject, value: ValueOf<IProject>) => {
    setProject((prev) => ({
      ...prev,
      [key]: value
    }))
  }
  const list = showDeleted
    ? responseGet.payload?.data
    : responseGet.payload?.data.filter((item) => !!item.deleted_at)
  return {
    data: list ?? [],
    loadingCUD: responseCreate.loading || responseUpdate.loading || responseDelete.loading,
    project,
    loadingList: responseGet.loading,
    refreshProjectList: () => loadGetProject(API_PROJECT),
    changeValueProject,
    handleDeleteProject,
    updateOrCreateProject,
    setProject
  }
}
