import { LocalGasStation } from "@mui/icons-material"
import { Card, CardContent, Chip, Grid } from "@mui/material"
import { formatNumber } from "utils"

import Chart from "./Chart"
import ChartLine from "./ChartLine"
import ChartStacked from "./ChartStacked"
import HomeSkeleton from "./HomeSkeleton"
import { useHome } from "./hooks/useHome"

export const HomeCharts = () => {
  const {
    loading,
    accumulatedData,
    currentMonthData,
    percentageUseAccumulatedData,
    percentageUseCurrentData,
    rateHourmeterData,
    avgFuelingInputData,
    stockFueling,
    rateMileageData
  } = useHome()
  if (loading) return <HomeSkeleton />
  return (
    <div style={{ width: "100%", display: "flex", gap: 8, flexDirection: "column" }}>
      <div style={{ display: "flex", width: "100%", gap: 8 }}>
        <Card style={{ flex: 1.5 }}>
          <CardContent style={{ margin: 0, padding: 0 }}>
            <Grid container flexDirection="row">
              <Chart
                id="accumulatedData"
                chartData={accumulatedData}
                endScroll={0.8}
                height={380}
              />
              <Chart
                id="currentMonthData"
                chartData={currentMonthData}
                endScroll={0.8}
                height={380}
              />
            </Grid>
          </CardContent>
        </Card>
        <Card style={{ flex: 1 }}>
          <CardContent style={{ margin: 0, padding: 0 }}>
            <Grid container flexDirection="column">
              <div style={{ flex: 1 }}>
                <ChartLine
                  id="avgFuelingInputData"
                  endScroll={1}
                  chartData={avgFuelingInputData}
                  labelText="[bold]{category}[/]:{valueY} días"
                  disableScroll={true}
                  height={380}
                />
              </div>
              <div
                style={{
                  flex: 0.25,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  padding: 8
                }}
              >
                <Chip
                  color="primary"
                  icon={<LocalGasStation color="primary" />}
                  label={stockFueling.dateStock}
                  size="small"
                />
                <br />
                <Chip
                  size="small"
                  variant="outlined"
                  color="primary"
                  label={`ENT: ${formatNumber(Number(stockFueling.sumInputs))}`}
                />
                <Chip
                  variant="outlined"
                  color="primary"
                  size="small"
                  label={`SAL: -${formatNumber(Number(stockFueling.sumOutputs))}`}
                />
                <br />
                <Chip
                  size="small"
                  variant="filled"
                  color="secondary"
                  label={`${formatNumber(Number(stockFueling.stock))} gls`}
                />
              </div>
            </Grid>
          </CardContent>
        </Card>
      </div>
      <div style={{ display: "flex", width: "100%", gap: 8 }}>
        <Card style={{ flex: 1.5 }}>
          <CardContent style={{ margin: 0, padding: 0 }}>
            <Grid container flexDirection="row">
              <ChartStacked
                id="percentageUseAccumulatedData"
                chartData={percentageUseAccumulatedData}
                endScroll={0.7}
              />
              <ChartStacked
                endScroll={0.7}
                id="percentageUseCurrent"
                chartData={percentageUseCurrentData}
              />
            </Grid>
          </CardContent>
        </Card>

        <Card style={{ flex: 1 }}>
          <CardContent style={{ margin: 0, padding: 0 }}>
            <Chart
              id="rateHourmeterData"
              endScroll={1}
              chartData={rateHourmeterData}
              disableScroll={true}
            />
          </CardContent>
        </Card>
      </div>
      <div style={{ display: "flex", width: "100%" }}>
        <Card style={{ flex: 1 }}>
          <CardContent style={{ margin: 0, padding: 0 }}>
            <Chart
              id="rateMileageData"
              chartData={rateMileageData}
              flex={2}
              endScroll={1}
              height={700}
            />
          </CardContent>
        </Card>
      </div>
    </div>
  )
}
