import LockOutlinedIcon from "@mui/icons-material/LockOutlined"
import { Alert } from "@mui/material"
import Avatar from "@mui/material/Avatar"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import CssBaseline from "@mui/material/CssBaseline"
import Grid from "@mui/material/Grid"
import Paper from "@mui/material/Paper"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"
import { ThemeProvider, createTheme } from "@mui/material/styles"
import { Copyright } from "components/layout/Layout"
import { COLOR_PRIMARY } from "constants/colors"
import { useAuth } from "hooks/useAuth"

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme()

export default function ContainerSign() {
  const { loadingCheckLogin, email, password, error, submitAuth, onChangeEmail, onChangePassword } =
    useAuth()
  return (
    <ThemeProvider theme={defaultTheme}>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          alignItems="center"
          justifyContent="center"
          sx={{
            backgroundColor: COLOR_PRIMARY,
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "flex",
            flexDirection: "column"
          }}
        >
          {/* <img width={250} src={require("../../assets/icon.png")} alt="Logo" /> */}
          <Box marginTop={4} />
          <Typography color="Background" fontWeight="bold" component="h1" variant="h4">
            Sistema Administrativo
          </Typography>
        </Grid>
        <Grid
          item
          display="flex"
          xs={12}
          sm={8}
          md={5}
          component={Paper}
          alignItems="center"
          justifyContent="center"
          elevation={6}
          square
        >
          <Box display="flex" marginY={8} marginX={4} alignItems="center" flexDirection="column">
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Bienvenido
            </Typography>
            <Typography component="span" variant="body1">
              Iniciar sesión con tus credenciales
            </Typography>
            <Box sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                label="Email"
                name="email"
                autoComplete="email"
                autoFocus
                value={email}
                onChange={(e) => onChangeEmail(e.target.value)}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Contraseña"
                type="password"
                value={password}
                autoComplete="current-password"
                onChange={(e) => onChangePassword(e.target.value)}
              />
              {/* <FormControlLabel
                                control={<Checkbox value="remember" color="primary" />}
                                label="Remember me"
                            /> */}
              <Button
                type="submit"
                disabled={loadingCheckLogin}
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                onClick={submitAuth}
              >
                Ingresar
              </Button>
              {error && <Alert severity="error">{error}</Alert>}
              {/* <Grid container>
                                <Grid item xs>
                                    <Link href="#" variant="body2">
                                        Forgot password?
                                    </Link>
                                </Grid>
                                <Grid item>
                                    <Link href="#" variant="body2">
                                        {"Don't have an account? Sign Up"}
                                    </Link>
                                </Grid>
                            </Grid> */}
              <Copyright />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  )
}
