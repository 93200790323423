/**
 * Converts a hexadecimal color code to an RGB color value.
 *
 * @param {string} hex - The hexadecimal color code to convert.
 * @returns {string | null} The corresponding RGB color value, or `null` if the input is invalid.
 */
export default function helps(hex: string): string | null {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return result
    ? `rgb(${parseInt(result[1], 16)},${parseInt(result[2], 16)},${parseInt(result[3], 16)})`
    : null
}

/**
 * Returns the number of days in a given month.
 *
 * @param {number} year - The year.
 * @param {number} month - The month, where 1 is January and 12 is December.
 * @returns {number} The number of days in the given month.
 * @throws {Error} If the month is not between 1 and 12.
 */
export function getMonthDays(month: number): number {
  const year = new Date().getFullYear()
  switch (month) {
    case 0: // Enero
    case 2: // Marzo
    case 4: // Mayo
    case 6: // Julio
    case 7: // Agosto
    case 9: // Octubre
    case 11: // Diciembre
      return 31
    case 3: // Abril
    case 5: // Junio
    case 8: // Septiembre
    case 10: // Noviembre
      return 30
    case 1: // Febrero
      return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0 ? 29 : 28
    default:
      throw new Error("El mes debe estar entre 0 y 11. =" + month)
  }
}

export function subtractDays(date: Date, days: number): Date {
  return new Date(date.getTime() - days * 24 * 60 * 60 * 1000)
}
