import { useLayoutEffect } from "react"

import * as am5 from "@amcharts/amcharts5"
import * as am5xy from "@amcharts/amcharts5/xy"
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated"

import { COLOR_PRIMARY } from "constants/index"

import { ChartData } from "./types"

type ChartProps = {
  id: string
  chartData: ChartData
  endScroll?: number
}
function ChartStacked({ id, chartData, endScroll }: ChartProps) {
  useLayoutEffect(() => {
    const root = am5.Root.new(id)

    root.setThemes([am5themes_Animated.new(root)])

    const chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        wheelX: "panX",
        wheelY: "zoomX",
        paddingLeft: 0,
        layout: root.verticalLayout
      })
    )
    chart.set(
      "scrollbarX",
      am5.Scrollbar.new(root, {
        orientation: "horizontal",
        end: endScroll
      })
    )
    chart.topAxesContainer.children.push(
      am5.Label.new(root, {
        text: chartData.title?.toUpperCase(),
        fontWeight: "bold",
        fontSize: 10,
        x: 0
      })
    )

    // Create Y-axis
    const yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        min: 0,
        max: 100,
        numberFormat: "#'%'",
        strictMinMax: true,
        calculateTotals: true,
        renderer: am5xy.AxisRendererY.new(root, {
          strokeOpacity: 0.1
        })
      })
    )
    yAxis.get("renderer").labels.template.setAll({
      fontSize: 8,
      fontWeight: "bold"
    })
    const xRenderer = am5xy.AxisRendererX.new(root, {
      minGridDistance: 30,
      minorGridEnabled: true
    })

    xRenderer.labels.template.setAll({
      rotation: -90,
      centerY: am5.p50,
      centerX: am5.p100,
      fontSize: 11
    })
    xRenderer.grid.template.setAll({
      location: 1
    })
    // Create X-Axis
    const xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        renderer: xRenderer,
        categoryField: "category"
      })
    )
    xAxis.data.setAll(chartData.data)

    chartData.series.forEach((s) => {
      const series = chart.series.push(
        am5xy.ColumnSeries.new(root, {
          name: s.name,
          xAxis: xAxis,
          yAxis: yAxis,
          stacked: true,
          valueYField: s.valueYField,
          categoryXField: s.categoryXField,
          valueYShow: "valueYTotalPercent",
          sequencedInterpolation: true,

          tooltip: am5.Tooltip.new(root, {})
        })
      )
      series.data.setAll(chartData.data)

      series.columns.template.setAll({
        tooltipText: "{name}:{valueYTotalPercent.formatNumber('#.#')}%"
      })
      series.appear()

      series.bullets.push(function () {
        return am5.Bullet.new(root, {
          sprite: am5.Label.new(root, {
            text: "{valueYTotalPercent.formatNumber('#.#')}%",
            fontSize: 12,
            fontWeight: "bold",
            fill: am5.color(s.fontColor || "#fff"),
            centerY: am5.p50,
            centerX: am5.p50,
            populateText: true
          })
        })
      })
      series.set("fill", am5.color(s.color || COLOR_PRIMARY))

      series.columns.template.setAll({
        strokeOpacity: 0
      })
      series.appear()
    })

    // Add legend
    const legend = chart.children.push(am5.Legend.new(root, {}))
    legend.data.setAll(chart.series.values)

    // Add cursor
    chart.set("cursor", am5xy.XYCursor.new(root, {}))

    return () => {
      root.dispose()
    }
  }, [chartData])

  return <div id={id} style={{ flex: 1, height: 500 }}></div>
}
export default ChartStacked
