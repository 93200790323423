import { Dispatch, SetStateAction } from "react"

import { Autocomplete, Button, Grid, TextField, Typography } from "@mui/material"
import { IDailyPart, ITimeline } from "model"
import { EnumProjectState } from "model/project"

interface FormTimelineProps {
  currentTimeline: ITimeline
  lastTimeline?: ITimeline
  dailyPart: Partial<IDailyPart>
  setCurrentTimeline: Dispatch<SetStateAction<ITimeline>>
  setTimelineList: Dispatch<SetStateAction<ITimeline[]>>
}
export const FormTimeline = ({
  currentTimeline,
  lastTimeline,
  dailyPart,
  setTimelineList,
  setCurrentTimeline
}: FormTimelineProps) => {
  const validItem =
    !!currentTimeline.movement_id &&
    !!currentTimeline.end_hourmeter &&
    !!currentTimeline.start_hourmeter &&
    Number(currentTimeline.end_hourmeter) > Number(currentTimeline.start_hourmeter)

  const outDateEnd =
    new Date(currentTimeline.movement?.project?.end_at ?? "").getTime() < new Date().getTime()
  const addItemTimeline = () => {
    if (!validItem || !currentTimeline?.end_hourmeter || !currentTimeline?.start_hourmeter) return
    //valid dates
    if (currentTimeline.end_hourmeter <= currentTimeline.start_hourmeter) {
      return
    }
    addValidItem()
  }
  const addValidItem = () => {
    setTimelineList((prev) => {
      return [...prev, currentTimeline]
    })
    setCurrentTimeline((c) => ({
      ...c,
      start_hourmeter: c.end_hourmeter,
      end_hourmeter: undefined,
      work: "",
      tonnage: 0
    }))
  }
  return (
    <Grid container mt={1} alignItems={"stretch"}>
      <Grid item flex={1} alignSelf={"end"}>
        <Autocomplete
          value={currentTimeline.movement}
          style={{ flex: 1 }}
          onChange={(_, value) =>
            setCurrentTimeline((prev) => ({
              ...prev,
              movement: value ?? undefined,
              movement_id: Number(value?.id ?? 0)
            }))
          }
          size="small"
          isOptionEqualToValue={(val, other) => Number(val?.id) === Number(other?.id)}
          options={
            dailyPart.equipment?.movements_active?.filter(
              (p) => p.project?.state === EnumProjectState.ACTIVE
            ) ?? []
          }
          getOptionLabel={(opt) => opt?.project?.name ?? opt?.project?.os ?? ""}
          renderInput={(params) => (
            <TextField {...params} label="Proyecto" helperText="Proyecto(s) asignados a equipo" />
          )}
        />
      </Grid>
      <Grid item flex={0.8}>
        <TextField
          size="small"
          fullWidth
          type="number"
          onChange={(e) =>
            setCurrentTimeline((prev) => ({
              ...prev,
              start_hourmeter: e.target.value ? Number(e.target.value) : undefined
            }))
          }
          helperText={`> o = que ${
            lastTimeline ? lastTimeline.end_hourmeter : dailyPart.equipment?.hourmeter ?? 0
          }`}
          value={currentTimeline.start_hourmeter ?? ""}
          label="H. Inicio"
          variant="outlined"
          error={
            lastTimeline
              ? Number(currentTimeline.start_hourmeter) < Number(lastTimeline.end_hourmeter)
              : !currentTimeline.start_hourmeter ||
                Number(currentTimeline.start_hourmeter) < Number(dailyPart.equipment?.hourmeter)
          }
        />
      </Grid>
      <Grid item flex={0.8}>
        <TextField
          size="small"
          fullWidth
          type="number"
          onChange={(e) =>
            setCurrentTimeline((prev) => ({
              ...prev,
              end_hourmeter: e.target.value ? Number(e.target.value) : undefined
            }))
          }
          value={currentTimeline.end_hourmeter ?? ""}
          helperText={`mayor que ${currentTimeline.start_hourmeter ?? ""}`}
          label="H. Fin"
          variant="outlined"
          error={
            !currentTimeline.end_hourmeter ||
            Number(currentTimeline.end_hourmeter) <= Number(currentTimeline.start_hourmeter)
          }
        />
      </Grid>
      <Grid item flex={0.5}>
        <TextField
          value={currentTimeline.tonnage ?? ""}
          error={Number(currentTimeline.tonnage ?? 0) <= 0}
          size="small"
          label="Tonelaje"
          fullWidth
          type="number"
          variant="outlined"
          onChange={(e) =>
            setCurrentTimeline((prev) => ({
              ...prev,
              tonnage: e.target.value ? Number(e.target.value) : undefined
            }))
          }
        />
      </Grid>
      <Grid item flex={0.5}>
        <TextField
          value={(
            Number(currentTimeline.end_hourmeter ?? 0) -
            Number(currentTimeline.start_hourmeter ?? 0)
          ).toFixed(2)}
          error={
            Number(currentTimeline.end_hourmeter ?? 0) <=
            Number(currentTimeline.start_hourmeter ?? 0)
          }
          size="small"
          label="H. Trabajo"
          fullWidth
          disabled
          variant="outlined"
        />
      </Grid>
      <Grid item flex={1}>
        <TextField
          value={currentTimeline.work ?? ""}
          onChange={(val) => setCurrentTimeline((prev) => ({ ...prev, work: val.target.value }))}
          size="small"
          label="Descripción del trabajo"
          multiline
          fullWidth
          variant="outlined"
        />
      </Grid>
      <Grid item alignItems="stretch">
        <Button size="small" disabled={!validItem} onClick={addItemTimeline} variant="contained">
          AGREGAR
        </Button>
      </Grid>
      <Grid container mt={1}>
        <Typography color="red">
          {outDateEnd &&
            `PROYECTO FUERA DE LA FECHA DE ESTIMADA: ${currentTimeline.movement?.project?.end_at}`}
        </Typography>
      </Grid>
    </Grid>
  )
}
