import { useEffect, useState } from "react"

import {
  Button,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  InputLabel,
  LinearProgress,
  ListItemText,
  ListSubheader,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Switch,
  TextField
} from "@mui/material"
import { GridSearchIcon } from "@mui/x-data-grid"
import { FormDialog } from "components"
import { useValorizationReport } from "hooks/daily-part"
import { IEquipment } from "model/equipment"

import { ValorizationTable } from "./ValorizationTable"
import { IValorizationRow } from "./const"

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  },
  autoFocus: false
}

export const ValorizationFilter = ({ equipmentList }: { equipmentList: IEquipment[] }) => {
  const [equipmentVisualization, setEquipmentVisualization] = useState<IValorizationRow>()
  const {
    openForm,
    loading,
    isFilterSupplier,
    from,
    to,
    supplierIDSelect,
    supplierList,
    notValidForm,
    equipmentSelect,
    displayedEquipmentOptions,
    valorizationList,
    setFrom,
    setSearchText,
    setOpenForm,
    setIsFilterSupplier,
    setEquipmentSelect,
    setSupplierIDSelect,
    setTo,
    downloadFileValorization,
    onClickVisualization
  } = useValorizationReport({ equipmentList })

  useEffect(() => {
    if (supplierIDSelect > 0) {
      const plates = equipmentList
        .filter((e) => e.supplier?.id === supplierIDSelect)
        .map((e) => e.plate)
      setEquipmentSelect(plates)
    } else {
      setEquipmentSelect([])
    }
  }, [supplierIDSelect, equipmentList])
  useEffect(() => {
    if (!isFilterSupplier) {
      setEquipmentSelect([])
      setSupplierIDSelect(0)
    }
  }, [isFilterSupplier])
  useEffect(() => {
    if (valorizationList.length > 0) {
      setEquipmentVisualization(valorizationList[0])
    } else {
      setEquipmentVisualization(undefined)
    }
  }, [valorizationList.length])
  const onChangeEquipmetVisualization = (equipment: IEquipment) => {
    const find = valorizationList.find((v) => v.equipment.id === equipment.id)
    setEquipmentVisualization(find)
  }

  return (
    <>
      <Button
        onClick={() => setOpenForm((prev) => !prev)}
        size="small"
        color="success"
        variant="contained"
      >
        VALORIZACIÓN
      </Button>
      <FormDialog
        open={openForm}
        loading={loading}
        maxWidth="lg"
        handleConfirm={downloadFileValorization}
        title={`Descargar valorización por equipo(s)`}
        setOpen={() => setOpenForm(false)}
        disabledButtons={loading || notValidForm}
      >
        <Grid container flexDirection="column">
          <Grid flex={1}>{loading && <LinearProgress variant="indeterminate" />}</Grid>
          <Grid item>
            <FormControlLabel
              onChange={() => setIsFilterSupplier((prev) => !prev)}
              control={<Switch checked={isFilterSupplier} />}
              label="Por proveedor"
            />
          </Grid>
          <Grid container gap={1} alignItems={"end"}>
            <TextField
              label="Desde"
              size="small"
              type="date"
              value={from}
              onChange={(e) => setFrom(e.currentTarget.value)}
            />
            <TextField
              label="Hasta"
              size="small"
              type="date"
              value={to}
              onChange={(e) => setTo(e.currentTarget.value)}
            />

            {isFilterSupplier && (
              <Grid item flex={1}>
                <FormControl fullWidth>
                  <InputLabel id="supplier_label">Proveedor</InputLabel>
                  <Select
                    labelId="supplier_label"
                    value={supplierIDSelect}
                    onChange={(e) => setSupplierIDSelect(Number(e.target.value))}
                    input={<OutlinedInput label="Proveedor" />}
                    MenuProps={MenuProps}
                    fullWidth
                    size="small"
                  >
                    {supplierList.map((entity) => (
                      <MenuItem key={entity?.id} value={entity?.id}>
                        <ListItemText primary={entity?.business_name} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )}
            <Grid item flex={1}>
              <FormControl fullWidth>
                <InputLabel id="equipments">Equipo (s)</InputLabel>
                <Select
                  labelId="equipments"
                  multiple
                  value={equipmentSelect}
                  onChange={(e) =>
                    Array.isArray(e.target.value) && setEquipmentSelect(e.target.value)
                  }
                  onClose={() => setSearchText("")}
                  input={<OutlinedInput label="Equipo (s)" />}
                  renderValue={(selected) => selected.join(", ")}
                  MenuProps={MenuProps}
                  fullWidth
                  size="small"
                >
                  <ListSubheader>
                    <TextField
                      size="small"
                      // Autofocus on textfield
                      autoFocus
                      placeholder="Type to search..."
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <GridSearchIcon />
                          </InputAdornment>
                        )
                      }}
                      onChange={(e) => setSearchText(e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key !== "Escape") {
                          // Prevents autoselecting item while typing (default Select behaviour)
                          e.stopPropagation()
                        }
                      }}
                    />
                  </ListSubheader>
                  {displayedEquipmentOptions.map((equipment) => (
                    <MenuItem key={equipment.id} value={equipment.plate}>
                      <Checkbox checked={equipmentSelect.indexOf(equipment.plate) > -1} />
                      <ListItemText
                        primary={`${equipment.plate} - ${
                          equipment.family?.family_name ?? "SIN FAM"
                        } - ${equipment.supplier?.business_name ?? "SIN PROV"}`}
                      />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                size="small"
                disabled={notValidForm}
                onClick={onClickVisualization}
              >
                PREVISUALIZAR
              </Button>
            </Grid>
          </Grid>
          <Grid container component={Paper}>
            {valorizationList.map(({ equipment }) => (
              <Button key={equipment.id} onClick={() => onChangeEquipmetVisualization(equipment)}>
                <Chip
                  color={equipment.id === equipmentVisualization?.equipment.id ? "info" : "default"}
                  label={equipment.plate}
                  size="small"
                />
              </Button>
            ))}
            {equipmentVisualization && <ValorizationTable rows={equipmentVisualization.data} />}
          </Grid>
        </Grid>
      </FormDialog>
    </>
  )
}
