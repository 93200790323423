import { useCallback, useReducer, useRef } from "react"

import { LS_TOKEN } from "constants/const"
import { saveAs } from "file-saver"

export interface State<T> {
  payload?: T
  loading?: boolean
  errorPayload?: Error
}
interface IFetch<T> {
  response: State<T>
  loadData: (url: string, data?: string) => void
  resetData: () => void
}

//type Cache<T> = { [url: string]: T }

// discriminated union type
type Action<T> =
  | { type: "reset" }
  | { type: "loading" }
  | { type: "fetched"; payload: T }
  | { type: "error"; payload: Error }

export function useFetch<T = unknown>(options?: RequestInit): IFetch<T> {
  //const cache = useRef<Cache<T>>({})

  // Used to prevent state update if the component is unmounted
  const cancelRequest = useRef<boolean>(false)

  const initialState: State<T> = {
    errorPayload: undefined,
    payload: undefined
  }

  // Keep state logic separated
  const fetchReducer = (state: State<T>, action: Action<T>): State<T> => {
    switch (action.type) {
      case "loading":
        return { ...initialState, loading: true }
      case "reset":
        return { ...initialState, loading: false }
      case "fetched":
        return { ...initialState, loading: false, payload: action.payload }
      case "error":
        return { ...initialState, loading: false, errorPayload: action.payload }
      default:
        return state
    }
  }

  const [state, dispatch] = useReducer(fetchReducer, initialState)

  const loadData = useCallback(
    (url: string, data?: string) => {
      if (!url) return

      cancelRequest.current = false

      const fetchData = async (url: string, dataToServer?: string) => {
        dispatch({ type: "loading" })

        // If a cache exists for this url, return it
        /* if (cache.current[url]) {
          dispatch({ type: "fetched", payload: cache.current[url] })
          return
        }
*/
        try {
          if (options && dataToServer) {
            options.body = dataToServer
          }
          const response = await fetch(url, {
            ...options,

            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem(LS_TOKEN)}`
            }
          })
          if (!response.ok) {
            console.log(response)
            const errorBody = await response.json()
            if (errorBody && errorBody.error) {
              throw new Error(errorBody.error)
            } else {
              throw new Error(response.statusText)
            }
          }

          const data = (await response.json()) as T
          //cache.current[url] = data
          if (cancelRequest.current) return

          dispatch({ type: "fetched", payload: data })
        } catch (error) {
          if (cancelRequest.current) return

          dispatch({ type: "error", payload: error as Error })
        }
      }

      void fetchData(url, data)

      // Use the cleanup function for avoiding a possibly...
      // ...state update after the component was unmounted
      return () => {
        cancelRequest.current = true
      }
    },
    [options]
  )
  const resetData = () => {
    console.log("ress")
    dispatch({ type: "reset" })
  }

  return { response: state, loadData, resetData }
}

export const downloadFile = async (urlFile: string, nameFile?: string) => {
  const options = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem(LS_TOKEN)}`
    }
  }
  fetch(urlFile, options)
    .then((res) => res.blob())
    .then((blob) => {
      // Crea un objeto Blob con el tipo MIME adecuado
      const fileBlob = new Blob([blob], { type: blob.type })

      // Utiliza FileSaver.js para guardar el archivo con el nombre proporcionado
      saveAs(fileBlob, nameFile ?? "report")
    })
}

interface IResponseUpload<T> {
  error?: string
  value?: T
}
export async function FetchUploadAPI<T>(baseURL: string, body: FormData) {
  const token = await localStorage.getItem(LS_TOKEN)
  try {
    const res = await fetch(baseURL, {
      method: "POST",
      headers: { Authorization: `Bearer ${token}` },
      body
    })

    if (!res.ok) {
      const text = await res.text()
      return {
        error: text,
        value: {}
      } as IResponseUpload<T>
    } else {
      const json = await res.json()
      console.log("json", json)
      return {
        error: "",
        value: json
      } as IResponseUpload<T>
    }
  } catch (error) {
    console.log("errorUpload", error)
    return {
      error
    } as IResponseUpload<T>
  }
}
