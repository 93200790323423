import { ReactElement } from "react"

import { Box, Paper, Typography } from "@mui/material"

interface ICardTitle {
  icon: ReactElement
  title: string
  subtitle: string
  flex?: number
}
export const CardTitle = ({ icon, title, subtitle, flex }: ICardTitle) => {
  return (
    <Paper
      sx={{
        p: 2,
        display: "flex",
        flexDirection: "row",
        maxWidth: "100%",
        alignItems: "center",

        width: "100%"
      }}
    >
      {icon}
      <Box marginLeft={3}>
        <Typography fontWeight="bold" variant="subtitle1">
          {title}
        </Typography>
        <Typography fontWeight="bold" variant="body2" color="grey">
          {subtitle}
        </Typography>
      </Box>
    </Paper>
  )
}
