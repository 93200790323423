import * as React from "react"

import { Face } from "@mui/icons-material"
import MenuIcon from "@mui/icons-material/Menu"
import { Grid } from "@mui/material"
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar"
import Box from "@mui/material/Box"
import Container from "@mui/material/Container"
import CssBaseline from "@mui/material/CssBaseline"
import IconButton from "@mui/material/IconButton"
import Link from "@mui/material/Link"
import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"
import { styled, useTheme } from "@mui/material/styles"
import { LS_USER } from "constants/const"
import { IUser } from "model/user"

import { Menu } from "./Menu"

export const drawerWidth: number = 240
export function Copyright(props: any) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {"Copyright © development by "}
      <Link color="inherit" href="https://urteaga.pe">
        Urteaga
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  )
}
interface AppBarProps extends MuiAppBarProps {
  open?: boolean
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open"
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  })
}))
interface ILayout {
  children: React.ReactNode
  title: string
}

export const Layout = ({ children, title }: ILayout) => {
  const openLs = localStorage.getItem("LS_DRAWER_OPEN")
  const [open, setOpen] = React.useState(openLs === "true")
  const user: IUser = JSON.parse(localStorage.getItem(LS_USER) ?? "{}")
  const toggleDrawer = () => {
    setOpen(!open)
    localStorage.setItem("LS_DRAWER_OPEN", !open + "")
  }
  const {
    palette: { secondary }
  } = useTheme()

  React.useEffect(() => {
    document.title = title
  }, [title])
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="absolute" color="primary" open={open}>
        <Toolbar
          sx={{
            pr: "24px" // keep right padding when drawer closed
          }}
        >
          <IconButton
            edge="start"
            color="secondary"
            aria-label="open drawer"
            onClick={toggleDrawer}
            sx={{
              marginRight: "36px",
              ...(open && { display: "none" })
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography component="h1" variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }} />

          <IconButton color="inherit">
            <Face color="secondary" />
            <Box flexDirection={"column"} alignItems="flex-start" display="flex" paddingLeft={1}>
              <Typography component="p" variant="body2" color={secondary.main} fontWeight="bold">
                {user.name} {user.surname}
              </Typography>
              <Typography component="p" variant="body2" color={secondary.main}>
                {user.email}
              </Typography>
            </Box>
          </IconButton>
        </Toolbar>
      </AppBar>
      <Menu open={open} toggleDrawer={toggleDrawer} />
      <Box display="flex" flexDirection="column" flexGrow={1}>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light" ? theme.palette.grey[100] : theme.palette.grey[900],
            flexGrow: 1,
            minHeight: "95vh"
          }}
        >
          <Toolbar />
          <Grid container justifyContent="center">
            <Grid item container marginTop={2} className={open ? "main-open" : "main-close"}>
              {children}
            </Grid>
          </Grid>
        </Box>
        <Box
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light" ? theme.palette.grey[100] : theme.palette.grey[900],
            flexGrow: 1,
            height: "3vh",
            overflow: "auto"
          }}
        >
          <Copyright />
        </Box>
      </Box>
    </Box>
  )
}
