import moment from "moment"

// utils.js
export function formatDate(date: string) {
  return new Date(date).toLocaleDateString(undefined, {
    hour: "numeric",
    minute: "numeric"
  })
}

export function capitalizeString(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export function daysDiffNow(total_days: number, date: string) {
  if (!date) return 0
  const b = moment(date)
  const a = moment()
  const diff = total_days - a.diff(b, "days")
  return diff < 0 ? 0 : diff
}

export const createFormData = (file: File) => {
  const formData = new FormData()
  formData.append("file", file)
  return formData
}
export function timeValidToAdd(hour1: string, hour2: string) {
  const [hour1h, hour1m] = hour1.split(":")
  const [hour2h, hour2m] = hour2.split(":")

  const intHour1h = parseInt(hour1h, 10)
  const intHour1m = parseInt(hour1m, 10)
  const intHour2h = parseInt(hour2h, 10)
  const intHour2m = parseInt(hour2m, 10)
  if (isNaN(intHour1h) || isNaN(intHour2h)) return false

  if (intHour1h < intHour2h) {
    return true
  } else if (intHour1h === intHour2h && intHour1m < intHour2m) {
    return true
  }

  return false
}
export function calculateDuration(hourStart: string, hourEnd: string) {
  const [hourStartH, hourStartM] = hourStart.split(":")
  const [hourEndH, hourEndM] = hourEnd.split(":")

  const dateStart = new Date(0, 0, 0, Number(hourStartH), Number(hourStartM))
  const dateEnd = new Date(0, 0, 0, Number(hourEndH), Number(hourEndM))

  if (dateEnd.getTime() < dateStart.getTime()) {
    dateEnd.setDate(dateEnd.getDate() + 1)
  }
  const difference = dateEnd.getTime() - dateStart.getTime()
  const durationHours = difference / (1000 * 60 * 60) // Milisegundos a horas

  return durationHours
}
export function dateToString(date: Date) {
  return `${date.getFullYear()}-${`0${date.getMonth() + 1}`.slice(-2)}-${`0${date.getDate()}`.slice(
    -2
  )}`
}
export function datetimeToString(date: Date) {
  return `${date.getFullYear()}-${`0${date.getMonth() + 1}`.slice(-2)}-${`0${date.getDate()}`.slice(
    -2
  )} ${`0${date.getHours()}`.slice(-2)}:${`0${date.getMinutes()}`.slice(
    -2
  )}:${`0${date.getSeconds()}`.slice(-2)}`
}

export function formatNumber(number: number) {
  return new Intl.NumberFormat("es-PE").format(number)
}
