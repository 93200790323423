import { useEffect, useState } from "react"

import { Refresh } from "@mui/icons-material"
import {
  Button,
  Chip,
  Divider,
  Grid,
  IconButton,
  LinearProgress,
  TextField,
  Typography
} from "@mui/material"
import { DataGrid } from "@mui/x-data-grid"
import { FormDialog } from "components"
import { INITIAL_STATE_DATAGRID, PAGE_SIZE_OPTIONS, localizedTextsMap } from "constants/const"
import { useFuelingMovement } from "hooks/fueling"
import { IFuelingInput } from "model"
import { IFueling } from "model/fueling"
import { formatNumber } from "utils"

import { columnFuelingInput, columnFuelingOutput } from "./const"

function compareDate(a: string, b: string) {
  const dateA = new Date(a).getTime()
  const dateB = new Date(b).getTime()

  return dateB - dateA
}
export const MovementsFueling = ({ fueling: fuelingBase }: { fueling?: IFueling }) => {
  const [deleteInput, setDeleteInput] = useState<Partial<IFuelingInput> | undefined>(undefined)

  const {
    fueling,
    inputList,
    outputList,
    loadingList,
    loadingCreate,
    fuelingInput,
    loadingFuelingDB,
    refreshListInput,
    refreshFuelingDB,
    refreshListOutput,
    changeValueFuelingInput,
    createFuelingInput,
    handleDeleteInput,
    loadingDelete
  } = useFuelingMovement({ fueling: fuelingBase })
  useEffect(() => {
    if (!loadingCreate) {
      refreshListInput()
      refreshListOutput()
      refreshFuelingDB()
      changeValueFuelingInput("quantity", "")
    }
  }, [loadingCreate])
  useEffect(() => {
    refreshListInput()
    refreshListOutput()
    refreshFuelingDB()
    setDeleteInput(undefined)
  }, [loadingDelete])
  if (!fueling) return null
  const shortedDispatch = [...outputList].sort((a, b) => compareDate(a.fecha, b.fecha))
  return (
    <Grid container gap={2}>
      <Divider style={{ width: "100%" }} orientation="horizontal" />
      {loadingFuelingDB && <LinearProgress variant="indeterminate" style={{ width: "100%" }} />}
      {loadingCreate && <LinearProgress variant="indeterminate" />}
      <Grid container>
        <Typography fontWeight="bold">Nuevo ingreso</Typography>
      </Grid>
      <Grid item>
        <Grid container alignItems="center" gap={1}>
          <TextField
            onChange={(e) => changeValueFuelingInput("quantity", e.target.value)}
            size="small"
            type="number"
            variant="outlined"
            label="Cantidad de galones"
            value={fuelingInput.quantity ?? ""}
          />
          <TextField
            onChange={(e) => changeValueFuelingInput("created_at", e.target.value)}
            size="small"
            type="date"
            variant="outlined"
            label="Fecha de ingreso"
            value={fuelingInput.created_at ?? ""}
          />
          <Button
            size="small"
            variant="contained"
            onClick={createFuelingInput}
            disabled={
              !(Number(fuelingInput.quantity) > 0) || !fuelingInput.created_at || loadingCreate
            }
          >
            AGREGAR
          </Button>
        </Grid>
      </Grid>
      <Grid item flex={1}>
        <Grid container gap={2} justifyContent="end" alignItems="center">
          <Grid item>
            <Grid container flexDirection="column">
              <Chip label="INGRESOS" size="small" />
              <Chip
                label={formatNumber(fueling.input || 0)}
                size="small"
                variant="outlined"
                color="info"
              />
            </Grid>
          </Grid>
          <Grid item>
            <Typography fontWeight="bold">-</Typography>
          </Grid>
          <Grid item>
            <Grid container flexDirection="column">
              <Chip label="SALIDAS" size="small" />
              <Chip
                label={formatNumber(fueling.output || 0)}
                size="small"
                variant="outlined"
                color="info"
              />
            </Grid>
          </Grid>
          <Grid item>
            <Typography fontWeight="bold">=</Typography>
          </Grid>
          <Grid item>
            <Grid container flexDirection="column">
              <Chip label="STOCK" size="small" />
              <Chip
                label={formatNumber((fueling.input ?? 0) - (fueling.output ?? 0))}
                size="small"
                variant="filled"
                color="primary"
              />
            </Grid>
          </Grid>
          <IconButton
            color="inherit"
            size="small"
            disabled={loadingFuelingDB}
            onClick={refreshFuelingDB}
          >
            <Refresh fontSize="small" />
          </IconButton>
        </Grid>
      </Grid>
      <Divider style={{ width: "100%" }} orientation="horizontal" />
      <Grid container>
        <Grid item flex={1}>
          <Typography fontWeight="bold">Ingresos</Typography>
          <DataGrid
            density="compact"
            rows={inputList}
            columns={columnFuelingInput({ setDeleteInput })}
            pagination
            autoHeight
            initialState={INITIAL_STATE_DATAGRID}
            disableRowSelectionOnClick
            pageSizeOptions={PAGE_SIZE_OPTIONS}
            localeText={localizedTextsMap}
            loading={loadingList}
          />
        </Grid>
        <Grid item flex={2}>
          <Typography fontWeight="bold">Salidas</Typography>
          <DataGrid
            density="compact"
            rows={shortedDispatch}
            columns={columnFuelingOutput({})}
            pagination
            autoHeight
            initialState={INITIAL_STATE_DATAGRID}
            disableRowSelectionOnClick
            pageSizeOptions={PAGE_SIZE_OPTIONS}
            localeText={localizedTextsMap}
            loading={loadingList}
          />
        </Grid>
        <FormDialog
          open={!!deleteInput}
          handleConfirm={() => handleDeleteInput(Number(deleteInput?.id) ?? 0)}
          title="Eliminar Registro"
          disabledButtons={loadingDelete}
          setOpen={() => {
            setDeleteInput(undefined)
          }}
        >
          <label>Vamos a eliminar el registro: {deleteInput?.created_at} </label>
        </FormDialog>
      </Grid>
    </Grid>
  )
}
