import { useEffect, useState } from "react"

import { Box, Button, FormControlLabel, Grid, Switch, TextField } from "@mui/material"
import { DataGrid } from "@mui/x-data-grid"
import FormDialog from "components/common/Dialog"
import { INITIAL_STATE_DATAGRID, PAGE_SIZE_OPTIONS, localizedTextsMap } from "constants/const"
import { DEFAULT_ENTITY, useCrudEntity } from "hooks/entity"
import { ValueOf } from "model/common"
import { EnumEntity, IEntity } from "model/equipment"
import { dateToString } from "utils"

import { columnsEntity } from "./const"

export const FormEntity = ({
  changeValueEntity,
  supplier
}: {
  changeValueEntity: (key: keyof IEntity, value: ValueOf<IEntity>) => void
  supplier: Partial<IEntity>
}) => {
  return (
    <Grid container>
      <Box width="100%" height={8} />
      <Grid container>
        <Grid item flex={1}>
          <TextField
            size="small"
            value={supplier.ruc ?? ""}
            onChange={(e) => changeValueEntity("ruc", e.target.value)}
            fullWidth
            autoFocus
            disabled={!!supplier.deleted_at}
            label="RUC"
            variant="outlined"
          />
        </Grid>
        <Grid item flex={1}>
          <TextField
            size="small"
            value={supplier.business_name ?? ""}
            onChange={(e) => changeValueEntity("business_name", e.target.value)}
            fullWidth
            autoFocus
            disabled={!!supplier.deleted_at}
            label="Razón Social"
            variant="outlined"
          />
        </Grid>
        <Box width={"100%"} />
        <FormControlLabel
          onChange={(e, checked) =>
            changeValueEntity("deleted_at", checked ? undefined : dateToString(new Date()))
          }
          control={<Switch checked={!supplier.deleted_at} />}
          label="ACTIVO"
        />
      </Grid>
    </Grid>
  )
}
export const ListContainerEntity = ({ type }: { type: EnumEntity }) => {
  const [openForm, setOpenForm] = useState(false)
  const [supplierEdit, setEntityEdit] = useState<IEntity | undefined>(undefined)
  const [supplierDelete, setEntityDelete] = useState<IEntity>()

  const {
    loadingList,
    loadingCUD,
    data,
    entity,
    changeValueEntity,
    handleDeleteEntity,
    refreshEntityList,
    setEntity,
    updateOrCreateEntity
  } = useCrudEntity({ type })

  useEffect(() => {
    if (supplierEdit) {
      setEntity(supplierEdit)
    }
    setOpenForm(!!supplierEdit)
  }, [supplierEdit])

  useEffect(() => {
    if (!loadingCUD) {
      setOpenForm(false)
      setEntity(DEFAULT_ENTITY)
      setEntityDelete(undefined)
      refreshEntityList()
    }
  }, [loadingCUD])
  useEffect(() => {
    if (!openForm) {
      setEntityEdit(undefined)
      setEntity(DEFAULT_ENTITY)
    }
  }, [openForm])
  return (
    <Grid container>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Button size="small" variant="contained" onClick={() => setOpenForm((prev) => !prev)}>
          Crear {type}
        </Button>
      </Box>
      <Grid item md={12}>
        <DataGrid
          density="compact"
          rows={data}
          columns={columnsEntity({
            setDeleteEntity: setEntityDelete,
            setEditEntity: setEntityEdit
          })}
          pagination
          autoHeight
          initialState={INITIAL_STATE_DATAGRID}
          disableRowSelectionOnClick
          pageSizeOptions={PAGE_SIZE_OPTIONS}
          localeText={localizedTextsMap}
          loading={loadingList}
        />
      </Grid>
      <FormDialog
        open={openForm}
        visibleButtons={!loadingCUD}
        setOpen={setOpenForm}
        maxWidth="xs"
        handleConfirm={updateOrCreateEntity}
        title={supplierEdit ? `Editar ${type}: ` + supplierEdit.business_name : `Nuevo ${type}`}
      >
        <FormEntity changeValueEntity={changeValueEntity} supplier={entity} />
      </FormDialog>

      <FormDialog
        open={!!supplierDelete}
        handleConfirm={() => handleDeleteEntity(Number(supplierDelete?.id) ?? 0)}
        title="Eliminar Registro"
        setOpen={() => {
          setEntityDelete(undefined)
        }}
      >
        <label>Vamos a eliminar el registro: {supplierDelete?.business_name}</label>
      </FormDialog>
    </Grid>
  )
}
