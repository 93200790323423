import { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react"

import { List, Refresh } from "@mui/icons-material"
import {
  Autocomplete,
  Button,
  Grid,
  IconButton,
  LinearProgress,
  Paper,
  TextField,
  Typography
} from "@mui/material"
import { DataGrid, GridToolbar } from "@mui/x-data-grid"
import { CardTitle } from "components/common/CardTitle"
import FormDialog from "components/common/Dialog"
import { DatePicker } from "components/common/datepicker/DatePicker"
import { INITIAL_STATE_DATAGRID, PAGE_SIZE_OPTIONS, localizedTextsMap } from "constants/index"
import {
  DEFAULT_DAILY_PART,
  downloadFileProject,
  useCrudDailyPart,
  useDailyByDate
} from "hooks/daily-part"
import { useEquipmentFamilyList } from "hooks/equipment"
import { useProjectList } from "hooks/project"
import { ITimeline } from "model/daily-part"
import { IEquipment } from "model/equipment"
import { dateToString } from "utils"

import { FormDailyPart } from "./FormDailyPart"
import { ResumeDailyPart } from "./ResumeDailyPart"
import { ValorizationFilter } from "./ValorizationFilter"
import { IDailyPartCustom, columnDailyList } from "./const"
import { getResumePerFamily } from "./utils"

const CrudDailyPartContainer = ({
  openForm,
  deleteDaily,
  selectDateINIT,
  equipmentDefault,
  editDaily,
  setEditDaily,
  setOpenForm,
  setDeleteDaily
}: {
  openForm: boolean
  deleteDaily: Partial<IDailyPartCustom> | undefined
  editDaily: Partial<IDailyPartCustom> | undefined
  selectDateINIT: Date
  equipmentDefault: Partial<IEquipment>
  setDeleteDaily: Dispatch<SetStateAction<Partial<IDailyPartCustom | undefined>>>
  setEditDaily: Dispatch<SetStateAction<Partial<IDailyPartCustom | undefined>>>
  setOpenForm: Dispatch<SetStateAction<boolean>>
}) => {
  const [timelineList, setTimelineList] = useState<ITimeline[]>([])
  const [isEditWorks, setIsEditWorks] = useState(false)
  const {
    dailyPart,
    loadingCUD,
    isNotValidDailyForm,
    responseCreate,
    responseUpdate,
    changeValueDailyPart,
    setDailyPart,
    updateOrCreateDailyPart,
    handleDeleteDailyPart,
    resetData: resetFormCreate
  } = useCrudDailyPart()
  useEffect(() => {
    if (!openForm) {
      setDailyPart(DEFAULT_DAILY_PART)
      setTimelineList([])
    }
    if (!openForm && !loadingCUD) {
      setDeleteDaily(undefined)
      setEditDaily(undefined)
      setIsEditWorks(false)
      changeValueDailyPart("date_part", dateToString(selectDateINIT))
    }
  }, [openForm, selectDateINIT, loadingCUD])
  useEffect(() => {
    if (responseCreate.errorPayload) {
      alert(responseCreate.errorPayload.message)
      resetFormCreate()
    }
  }, [responseCreate])
  useEffect(() => {
    if (responseUpdate.errorPayload) {
      alert(responseUpdate.errorPayload.message)
    }
  }, [responseUpdate])
  useEffect(() => {
    if (equipmentDefault.id) {
      changeValueDailyPart("equipment", equipmentDefault)
      changeValueDailyPart("equipment_id", equipmentDefault.id)
    }
  }, [equipmentDefault])

  useEffect(() => {
    if (editDaily?.daily) {
      setDailyPart(editDaily.daily)
      setOpenForm(true)
    }
  }, [editDaily])
  useEffect(() => {
    if (isEditWorks) {
      changeValueDailyPart("total_hours", 0)
      changeValueDailyPart("start_hourmeter", 0)
      changeValueDailyPart("end_hourmeter", 0)
    } else if (editDaily?.daily) {
      const { total_hours, start_hourmeter, end_hourmeter, equipment } = editDaily?.daily
      const equipmentWithoutStock = {
        ...equipment,
        hourmeter: 0
      }
      changeValueDailyPart("total_hours", total_hours)
      changeValueDailyPart("equipment", equipmentWithoutStock)
      changeValueDailyPart("start_hourmeter", Number(start_hourmeter) ?? 0)
      changeValueDailyPart("end_hourmeter", Number(end_hourmeter) ?? 0)
    }
  }, [isEditWorks, editDaily])
  const isModeEdit = !!editDaily?.daily?.id
  const titleForm = isModeEdit
    ? `Editar parte diario de ${dailyPart.equipment?.plate} de ${dailyPart.date_part}`
    : "Nuevo parte diario"

  const onCloseOpenCreate = () => setOpenForm((prev) => !prev)
  const disableButtonDailyCreate = isNotValidDailyForm || timelineList.length === 0
  const disableButtonDailyEdit = isNotValidDailyForm || (isEditWorks && timelineList.length === 0)

  const updateOrCreateDaily = () => updateOrCreateDailyPart(timelineList, isEditWorks)
  return (
    <>
      <FormDialog
        title={titleForm}
        open={openForm}
        setOpen={onCloseOpenCreate}
        handleConfirm={updateOrCreateDaily}
        maxWidth={"lg"}
        loading={loadingCUD}
        disabledButtons={disableButtonDailyCreate && disableButtonDailyEdit}
      >
        <FormDailyPart
          isModeEdit={isModeEdit}
          dailyPart={dailyPart}
          timelineList={timelineList}
          responseCreate={responseCreate}
          responseUpdate={responseUpdate}
          firstTimeline={timelineList[0]}
          lastTimeline={timelineList.at(-1)}
          isEditWorks={isEditWorks}
          setTimelineList={setTimelineList}
          setDailyPart={setDailyPart}
          changeValueDailyPart={changeValueDailyPart}
          setIsEditWorks={setIsEditWorks}
        />
      </FormDialog>
      <FormDialog
        open={!!deleteDaily}
        loading={loadingCUD}
        handleConfirm={() => handleDeleteDailyPart(Number(deleteDaily?.daily?.id) ?? 0)}
        title={`Vamos a eliminar el registro: ${deleteDaily?.equipment?.plate} del ${deleteDaily?.daily?.date_part}`}
        setOpen={() => setDeleteDaily(undefined)}
      >
        <Typography fontWeight="bold" variant="h6">
          Recuerda: El horometro del equipo quedará como el horometro inicial (
          {deleteDaily?.daily?.start_hourmeter ?? 0}), si no es el adecuado puede ir a la sección de
          equipos y editarlo.
        </Typography>
      </FormDialog>
    </>
  )
}
export const ContainerDailyPartList = () => {
  const [deleteDaily, setDeleteDaily] = useState<Partial<IDailyPartCustom>>()
  const [editDaily, setEditDaily] = useState<Partial<IDailyPartCustom>>()
  const [selectDateINIT, setDateInit] = useState(new Date())
  const [equipDefault, setEquipDefault] = useState<Partial<IEquipment>>({})
  const [openForm, setOpenForm] = useState(false)
  const {
    dailyList,
    errorDailyDate,
    loadingDailyList,
    familyFilter,
    filterDailyList,
    projectFilter,
    setFamilyFilter,
    setProjectFilter,
    loadDailyByDate
  } = useDailyByDate()
  const { equipmentFamilyList, loadingList: loadingListFamily } = useEquipmentFamilyList()
  const { projectList, loadingList: loadingListProject } = useProjectList({ showDeleted: false })
  useEffect(() => {
    if (!openForm && !deleteDaily) {
      loadDailyByDate(dateToString(selectDateINIT))
    }
  }, [openForm, selectDateINIT, deleteDaily])

  const selectDay = (val: Date) => setDateInit(val)

  const onSelectDefaultEquipment = (e: Partial<IEquipment> | undefined) => {
    setOpenForm(true)
    if (e?.id) {
      setEquipDefault({ ...e })
    }
  }
  const getHoursPerFamily = useCallback(getResumePerFamily, [filterDailyList])

  const { itemsFamily, hoursAllFamily } = getHoursPerFamily(filterDailyList)

  const handleDownloadFileProject = () => {
    const date = new Date()
    downloadFileProject(
      Number(projectFilter?.id),
      `PD-${projectFilter?.os}-${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
    )
  }

  return (
    <Grid container gap={1} flexDirection={"column"} alignItems={"end"}>
      <Grid container justifyContent={"space-around"}>
        <Grid container item md={2} xs={2}>
          <CardTitle
            title="PD"
            subtitle="Parte diario"
            icon={<List fontSize="large" color="secondary" />}
          />
        </Grid>
        <Grid item md={10} xs={10} component={Paper}>
          <DatePicker getSelectedDay={selectDay} selectDate={selectDateINIT} />
        </Grid>
      </Grid>
      {errorDailyDate ?? ""}
      <Grid container component={Paper} m={0.5} p={1}>
        <Grid container justifyContent={"space-between"} alignItems={"center"} gap={1}>
          <Grid container gap={1}>
            <Grid container item flex={1}>
              <Autocomplete
                value={familyFilter}
                style={{ minWidth: 200 }}
                onChange={(_, value) => setFamilyFilter(value ?? undefined)}
                size="small"
                loading={loadingListFamily}
                isOptionEqualToValue={(val, other) => Number(val?.id) === Number(other?.id)}
                options={equipmentFamilyList}
                getOptionLabel={(opt) => opt.family_name ?? ""}
                renderInput={(params) => <TextField {...params} label="Familia" />}
              />
              <Autocomplete
                value={projectFilter}
                style={{ minWidth: 300 }}
                onChange={(_, value) => setProjectFilter(value ?? undefined)}
                size="small"
                loading={loadingListProject}
                isOptionEqualToValue={(val, other) => Number(val?.id) === Number(other?.id)}
                options={projectList}
                getOptionLabel={(opt) => `${opt.os} ${opt.name}`}
                renderInput={(params) => <TextField {...params} label="Proyecto" />}
              />
              <Button
                onClick={() => setOpenForm((prev) => !prev)}
                size="small"
                color="primary"
                variant="contained"
              >
                CREAR PD
              </Button>
              <Button
                onClick={handleDownloadFileProject}
                size="small"
                color="secondary"
                variant="contained"
                disabled={projectFilter?.id === "0"}
              >
                DESCARGAR
              </Button>
              <ValorizationFilter equipmentList={dailyList.map((d) => d.equipment)} />
            </Grid>
            <IconButton
              disabled={loadingDailyList}
              size="small"
              onClick={() => loadDailyByDate(dateToString(selectDateINIT))}
            >
              <Refresh fontSize="small" />
            </IconButton>
          </Grid>
        </Grid>

        <Grid container mt={2}>
          {loadingDailyList && <LinearProgress variant="indeterminate" style={{ width: "100%" }} />}
          <div style={{ width: "100%" }}>
            <DataGrid
              slots={{
                toolbar: GridToolbar
              }}
              density="compact"
              rows={filterDailyList}
              columns={columnDailyList({
                onSelectDefaultEquipment,
                setDeleteDaily,
                setEditDaily
              })}
              loading={loadingDailyList}
              pagination
              autoHeight
              initialState={INITIAL_STATE_DATAGRID}
              disableRowSelectionOnClick
              localeText={localizedTextsMap}
              pageSizeOptions={PAGE_SIZE_OPTIONS}
            />
          </div>
        </Grid>
      </Grid>
      <ResumeDailyPart total={hoursAllFamily} itemsFamily={itemsFamily} />
      <CrudDailyPartContainer
        deleteDaily={deleteDaily}
        equipmentDefault={equipDefault}
        openForm={openForm}
        editDaily={editDaily}
        selectDateINIT={selectDateINIT}
        setDeleteDaily={setDeleteDaily}
        setOpenForm={setOpenForm}
        setEditDaily={setEditDaily}
      />
    </Grid>
  )
}
