import { IEquipmentProjectMovement } from "./equipment_project"
import { IProject } from "./project"

export enum EnumEquipmentState {
  WORKING = "WORKING",
  STAND_BY = "STAND_BY",
  MAINTENANCE = "MAINTENANCE"
}
export enum EnumEquipmentRental {
  DRY = "SECA",
  SEMI_DRY = "SEMISECA",
  OPERATED = "OPERADA"
}
export enum EnumEntity {
  SUPPLIER = "PROVEEDOR",
  CUSTOMER = "CLIENTE"
}
export enum EnumCurrency {
  PEN = "PEN",
  USD = "USD"
}
export interface IFamily {
  id: string
  family_name: string
  created_at?: string
  edited_at?: string
  deleted_at?: string
}
export interface IEntity {
  id: number
  ruc: string
  business_name: string
  type: EnumEntity
  created_at?: string
  edited_at?: string
  deleted_at?: string
}
export interface IEquipment {
  id: string
  fueling_id?: number
  family_id?: string
  supplier_id?: string
  code: string
  name: string
  plate: string
  brand: string
  model: string
  series: string
  n_motor: string
  power: string
  color: string
  year_production: string
  hourmeter: number
  mileage: number
  has_gps: boolean
  expire_gps: string
  soat: string
  expire_soat: string
  has_trec: boolean
  expire_trec: string
  has_inspection_technical: boolean
  expire_inspection: string
  expire_card_merchandise: string
  own: boolean
  fee_price: number
  purchase_price: number
  tariff_price: number
  family?: Partial<IFamily>
  supplier?: Partial<IEntity>
  project?: IProject
  movements_active?: IEquipmentProjectMovement[]
  movements?: IEquipmentProjectMovement[]
  state: EnumEquipmentState
  rental: EnumEquipmentRental
  rental_currency: EnumCurrency
}
