import { useLayoutEffect } from "react"

import * as am5 from "@amcharts/amcharts5"
import * as am5xy from "@amcharts/amcharts5/xy"
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated"

import { ChartData } from "./types"

type ChartProps = {
  id: string
  chartData: ChartData
  endScroll?: number
  disableScroll?: boolean
  flex?: number
  height?: number
}
function Chart({
  id,
  chartData,
  endScroll = 0.5,
  disableScroll = false,
  flex = 1,
  height = 500
}: ChartProps) {
  useLayoutEffect(() => {
    const root = am5.Root.new(id)

    root.setThemes([am5themes_Animated.new(root)])
    root.interfaceColors.set("grid", am5.color(0xff0000))

    const chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panY: false,
        layout: root.verticalLayout,
        wheelX: "panX",
        wheelY: !disableScroll ? "zoomX" : "none",
        pinchZoomX: true,
        paddingTop: 10
      })
    )
    chart.topAxesContainer.children.push(
      am5.Label.new(root, {
        text: chartData.title?.toUpperCase(),
        fontWeight: "bold",
        fontSize: 10,
        x: 0
      })
    )
    if (!disableScroll) {
      chart.set(
        "scrollbarX",
        am5.Scrollbar.new(root, {
          orientation: "horizontal",
          end: endScroll
        })
      )
    }

    // Create Y-axis
    const yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: am5xy.AxisRendererY.new(root, {})
      })
    )
    yAxis.get("renderer").labels.template.setAll({
      fontSize: 8,
      fontWeight: "bold"
    })
    const xRenderer = am5xy.AxisRendererX.new(root, {
      minGridDistance: 30,
      minorGridEnabled: true
    })

    xRenderer.labels.template.setAll({
      rotation: -90,
      centerY: am5.p50,
      centerX: am5.p100,
      fontSize: 11
    })
    xRenderer.grid.template.setAll({
      location: 1
    })
    // Create X-Axis
    const xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        renderer: xRenderer,
        categoryField: "category"
      })
    )
    xAxis.data.setAll(chartData.data)
    chartData.series.forEach((s) => {
      const series = chart.series.push(
        am5xy.ColumnSeries.new(root, {
          name: s.name,
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: s.valueYField,
          categoryXField: s.categoryXField,
          sequencedInterpolation: true,

          tooltip: am5.Tooltip.new(root, {
            labelText: s.labelText || "[bold]{categoryX}[/]\n{valueY}",
            showTooltipOn: "always"
          })
        })
      )
      series.data.setAll(chartData.data)
      series.appear()
      if (!s.color) {
        series.columns.template.adapters.add("fill", function (fill, target) {
          return chart.get("colors")?.getIndex(series.columns.indexOf(target))
        })
      } else {
        series.set("fill", am5.color(s.color))
      }

      series.columns.template.setAll({ cornerRadiusTL: 5, cornerRadiusTR: 5, strokeOpacity: 0 })
    })

    // Add legend
    const legend = chart.children.push(am5.Legend.new(root, {}))
    legend.data.setAll(chart.series.values)

    // Add cursor
    chart.set("cursor", am5xy.XYCursor.new(root, {}))

    return () => {
      root.dispose()
    }
  }, [chartData])

  return <div id={id} style={{ flex, height }}></div>
}
export default Chart
